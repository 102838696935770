import React from "react";
import "./style.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { ROUTE_URLS } from "../../constants/URLS";

const PageTitleHeader = ({ title }) => {
  const { CREATE_FORM } = ROUTE_URLS;
  const navigate = useNavigate();
  const [profile_cookie] = useCookies([process.env.REACT_APP_PS_COOKIE_NAME]);
  const [createFormcookie, , removeCreateFormCookie] = useCookies([
    process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
  ]);

  let profileStatus = Number(
    profile_cookie[process.env.REACT_APP_PS_COOKIE_NAME]
  );

  const goToCreateForm = () => {
    if (createFormcookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]) {
      removeCreateFormCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME);
      navigate(CREATE_FORM);
    } else {
      navigate(CREATE_FORM);
    }
  };

  return (
    <div className="page_title_header">
      <h1>{title}</h1>
      <div className="side_buttons_div">
        <p>Create New Form</p>
        <button
          className={!profileStatus ? "inactive_button" : null}
          disabled={!profileStatus}
          title={!profileStatus ? "Please complete your profile" : null}
          onClick={goToCreateForm}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default PageTitleHeader;
