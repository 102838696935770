import React, { useEffect, useRef, forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/fontawesome-free-regular";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import YoutubeEmbedVideo from "../../components/YoutubeEmbedVideo";
import CustomTextArea from "../../components/CustomTextArea";

import "./formcode.css";
import { IMAGES } from "../../constants/images";
import { FORMCODE_YOUTUBE_VIDEO_ID } from "../../constants/staticData";
import { Link, useParams } from "react-router-dom";
import { ROUTE_URLS } from "../../constants/URLS";
import { FORM_EMBED_URL } from "../../constants/EXTRA_CONSTANTS";
import DotLoader from "../../components/DotLoader";

export default function FormCode() {
  const { DASHBOARD } = ROUTE_URLS;
  const { form_code_id, formName } = useParams();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    setValue(
      `<iframe src="${FORM_EMBED_URL}/${form_code_id}" width="100%" height="100%"  frameBorder="0"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowFullScreen  title="${formName}"/>`
    );
    setLoading(false);
  }, []);

  function myFunction() {
    textAreaRef.current.select();
    textAreaRef.current.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(textAreaRef.current.value);
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Code Copied!";
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  function outFunc() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy";
  }
  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="form_code_sec">
        <div className="page_container">
          <h1>Here is your form code</h1>
          <div className="form_code">
            <CustomBoxHeader title={"Form Code"} title_img={IMAGES.pencil} />
            <div className="content_body">
              <CustomTextArea
                name="message"
                id="form_code"
                label={"Here is your form code"}
                height={"218px"}
                readOnly
                value={value}
                ref={textAreaRef}
              />
              <div className="tooltip">
                <div onClick={myFunction} onMouseOut={outFunc}>
                  <span className="tooltiptext" id="myTooltip">
                    Copy
                  </span>
                  <FontAwesomeIcon size="2x" color="#000" icon={faClone} />
                </div>
              </div>

              <h3>Watch the video to learn more</h3>
              <YoutubeEmbedVideo embedId={FORMCODE_YOUTUBE_VIDEO_ID} />
              {/* <img src={IMAGES.formcodeimg} alt="" /> */}
              <h3>Your form will be emailed to you.</h3>
              <div className="link_div">
                <Link to={DASHBOARD}>Click Here</Link>
                <p> to go to Dashboard</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
