export const generateErrorMessage = (field) => {
  const fieldName = field?.replace(/_/g, " ");

  if (field.includes("billing")) {
    return `${
      fieldName?.charAt(8)?.toUpperCase() + fieldName?.slice(9)
    } is required.`;
  } else if (field?.includes("shipping")) {
    return `${
      field?.charAt(9)?.toUpperCase() + field?.slice(10)?.replace(/_/g, " ")
    } is required.`;
  } else {
    return `${
      field?.charAt(0)?.toUpperCase() + field?.slice(1)?.replace(/_/g, " ")
    } is required.`;
  }
};

export const checkFieldsForValidation = (
  is_shipping_address,
  payment_processor
) => {
  let allFields = [];
  if (is_shipping_address && payment_processor == "paypal") {
    allFields = [...BillingRequiredFields, ...ShippingRequiredFields];
  } else if (!is_shipping_address && payment_processor != "paypal") {
    allFields = [...BillingRequiredFields];
  } else if (is_shipping_address && payment_processor != "paypal") {
    allFields = [...BillingRequiredFields, ...ShippingRequiredFields];
  } else if (!is_shipping_address && payment_processor == "paypal") {
    allFields = [...BillingRequiredFields];
  } else {
    allFields = [...BillingRequiredFields, ...ShippingRequiredFields];
  }
  return allFields;
};
export const resetErrors = (fields, setError) => {
  const resetValues = fields?.reduce((acc, field) => {
    acc[field] = "";
    return acc;
  }, {});
  setError(resetValues);
};

export const BillingRequiredFields = [
  "billing_first_name",
  "billing_last_name",
  "billing_email",
  "billing_phone",
  "billing_company_name",
  "billing_street_address",
  // "billing_add_line_2",
  // "billing_city",
  // "billing_state",
  "billing_country",
  "billing_zip_postal_code",
];

export const ShippingRequiredFields = [
  "shipping_first_name",
  "shipping_last_name",
  "shipping_company_name",
  "shipping_street_address",
  // "shipping_address_line_2",
  // "shipping_city",
  // "shipping_state",
  "shipping_country",
  "shipping_zip_postal_code",
];

export const CardDetailsFields = [
  "card_Number",
  "card_Holder_Name",
  "expiry_Date",
  "CVV",
];

export const calculateProductPrice = (product_price, product_quantity) => {
  return Number(product_price * product_quantity);
};

export const calculateDiscountPrice = (
  product_price,
  discount_value,
  product_quantity
) => {
  if (discount_value) {
    return Number(((product_price * discount_value) / 100) * product_quantity);
  }
};

export const calculateSalesTax = (
  product_price,
  discount_value,
  sale_tax,
  product_quantity
) => {
  if (sale_tax && discount_value) {
    return Number(
      ((Number(product_price * product_quantity) -
        Number(((product_price * discount_value) / 100) * product_quantity)) *
        sale_tax) /
        100
    );
  } else if (sale_tax) {
    return Number((Number(product_price * product_quantity) * sale_tax) / 100);
  }
};

export const calculateTotalPrice = (
  product_price,
  discount_value,
  sale_tax,
  product_quantity
) => {
  let calculated_product_price = calculateProductPrice(
    product_price,
    product_quantity
  );
  let calculated_sales_tax = calculateSalesTax(
    product_price,
    discount_value,
    sale_tax,
    product_quantity
  );
  let calculated_discount = calculateDiscountPrice(
    product_price,
    discount_value,
    product_quantity
  );

  if (discount_value && sale_tax) {
    return (
      calculated_product_price - calculated_discount + calculated_sales_tax
    );
  } else if (discount_value && !sale_tax) {
    return calculated_product_price - calculated_discount;
  } else if (!discount_value && sale_tax) {
    return calculated_product_price + calculated_sales_tax;
  } else {
    return calculated_product_price;
  }
};

export const handleNumberField = (event) => {
  const { key, keyCode, target } = event;
  const { value, name } = target;
  if (
    keyCode == 40 ||
    key == "ArrowDown" ||
    keyCode == 38 ||
    key == "ArrowUp" ||
    key == "e" ||
    keyCode == 69 ||
    key == "-" ||
    keyCode == 109 ||
    key == "+" ||
    keyCode == 107 ||
    key == "." ||
    keyCode == 110
  ) {
    event.preventDefault();
  }
  if (
    name == "CVV" &&
    value.toString().length > 3 &&
    key != "BackSpace" &&
    keyCode != 8 &&
    key != "Delete" &&
    keyCode != 46 &&
    key != "ArrowLeft" &&
    keyCode != 37 &&
    keyCode != 39 &&
    key != "ArrowRight" &&
    key != "Tab" &&
    keyCode != 9
  ) {
    event.preventDefault();
  }
  if (
    name == "billing_phone" &&
    value.toString().length > 14 &&
    key != "BackSpace" &&
    keyCode != 8 &&
    key != "Delete" &&
    keyCode != 46 &&
    key != "ArrowLeft" &&
    keyCode != 37 &&
    keyCode != 39 &&
    key != "ArrowRight" &&
    key != "Tab" &&
    keyCode != 9
  ) {
    event.preventDefault();
  }
};
