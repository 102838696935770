//API ENDPOINTS
//AUTH ENDPOINTS
export const LOGIN_ENDPOINT = "/login";
export const REGISTER_ENDPOINT = "/register";
export const GOOGLE_SIGNIN_SIGNUP_ENDPOINT = "/google-sign";
export const LOGOUT_ENDPOINT = "/logout";
export const UPDATE_PASSWORD_ENDPOINT = "/update-password";
export const PASSWORD_RESET_MAIL_ENDPOINT = "/password-reset-mail";

//PROFILE ENDPOINTS
export const USER_PROFILE_ENDPOINT = "/user-profile";
export const UPDATE_PROFILE_ENDPOINT = "/update-profile";
export const UPDATE_CARD_DETAILS_ENDPOINT = "/update-card-details";
export const GET_DEFAULT_PP = "/client-default-pp";
export const GET_DEFAULT_CRM = "/client-default-crm";
export const UPDATE_DEFAULT_PP = "/update-default-pp";
export const UPDATE_DEFAULT_CRM = "/update-default-crm";
export const TOGGLE_PROMOTIONS_ENDPOINT = "/update-promotions";

// COUNTRY, STATE, CITIES AND CURRENCIES ENDPOINTS
export const COUNTRIES_ENDPOINT = "/countries";
export const STATES_ENDPOINT = "/countries-states";
export const CITIES_ENDPOINT = "/states-cities";
export const CURRENCY_LIST_ENDPOINT = "/currencies-list";

//CRM ACCOUNT CHECK ENDPOINTS
export const MAIL_CHIMP_ACCOUNT_CHECK_ENDPOINT = "/has-account/mailchimp";
export const HUBSPOT_ACCOUNT_CHECK_ENDPOINT = "/has-account/hubspot";

//PAYMENT PROCESSOR ACCOUNT CHECK ENDPOINTS
export const STRIPE_ACCOUNT_CHECK_ENDPOINT = "/has-account/stripe";
export const PAYPAL_ACCOUNT_CHECK_ENDPOINT = "/has-account/paypal";

//FORM ENDPOINTS
export const GET_ALL_FORMS_ENDPOINT = "/user-forms";
export const CREATE_FORM_DETAILS_ENDPOINT = "/create-form-details";
export const GET_FORM_DETAILS_ENDPOINT = "/get-form-details";
export const CREATE_FORM_ENDPOINT = "/create-form";
export const UPDATE_FORM_ENDPOINT = "/update-form";
export const FRAME_FORM = "/frame-form";
export const SUBMIT_IFRAME_ENDPOINT = "/product-checkout";
export const DISCOUNT_CHECK_ENDPOINT = "/check-discount";
export const PAYPAL_SUBSCRIPTION_CHECK_ENDPOINT = "/subscription-check";
export const PAYPAL_PAYMENT_CHECK_ENDPOINT = "/payment-execute";


// SUPPORT ENDPOINTS
export const CONTACT_US_ENDPOINT = "/contact-us";
