import React from "react";
import "../css/CustomRadioWithLabel.css";
import { Field } from "formik";

const FormikCustomRadioWithLabel = ({
  label,
  value,
  name,
  id,
  widthBottomPad,
  radioLeft,
  selected,
}) => {
  return (
    <div
      className={widthBottomPad ? "radio_div with_padding_bottom" : "radio_div"}
    >
      {radioLeft ? <label htmlFor={id}>{label}</label> : null}
      <div className="rad_div">
        <Field
          type="radio"
          checked={selected}
          value={value}
          name={name}
          id={id}
        />
      </div>
      {radioLeft ? null : <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default FormikCustomRadioWithLabel;
