import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";

import "./Signup.css";
import { ROUTE_URLS } from "../../../../constants/URLS";
import { useScrollTo } from "../../../../hooks/useScrollTo";
import { toast } from "react-toastify";

export default function FinalSignup() {
  useScrollTo(0, 0);
  const { CREATE_FORM } = ROUTE_URLS;
  const navigate = useNavigate();
  const [cookie, , removeCookie] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  useEffect(() => {
    // Disable navigation using browser back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", preventNavigation);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("popstate", preventNavigation);
    };
  }, []);

  const preventNavigation = (event) => {
    event.preventDefault();
    toast.info("You can't go back to previous page.");
    window.history.pushState(null, document.title, window.location.href);
  };
  const goToCreateForm = () => {
    if (cookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]) {
      removeCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME);
    }
    navigate(CREATE_FORM);
  };
  return (
    <>
      <Header />
      <div id="final_signup">
        <div className="page_container">
          <h1> Are you ready to start creating your forms ?</h1>
          <div className="first_form">
            <button onClick={goToCreateForm}>CREATE MY FIRST FORM</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
