import React from "react";
import "../css/CustomPaginationBox.css";

const CustomPaginationBox = ({ page_number, total_pages, form }) => {
  const getLIs = (page_number, total_pages) => {
    let content = [];
    for (let i = 0; i < total_pages; i++) {
      content.push(
        <li className={i == page_number - 1 ? "active" : null} key={i}></li>
      );
    }
    return content;
  };
  return (
    <div className="pagination">
      {form ? (
        <>
          <p>
            Creating your form  Step {page_number} of {total_pages}
          </p>
        </>
      ) : (
        <p>
          Creating your account Step {page_number} of {total_pages}
        </p>
      )}
      <ul>{getLIs(page_number, total_pages)}</ul>
    </div>
  );
};

export default CustomPaginationBox;
