import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/header.css";
import { IMAGES } from "../constants/images";
import AXIOS_REQUEST from "../helpers/Axios";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { ROUTE_URLS } from "../constants/URLS";
import { LOGOUT_ENDPOINT } from "../constants/API_ENDPOINTS";

const Header = () => {
  const {
    LOGIN,
    FORGOT_PASS,
    CHANGE_PASSWORD,
    GET_SUPPORT,
    MY_ACCOUNT,
    DASHBOARD,
    MANAGE_PAYMENT,
    BILLING_INFO,
    PAYMENT_DETAIL,
    FINAL_SIGNUP,
  } = ROUTE_URLS;

  const [openModal, setopenModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [urlEnd, setUrlEnd] = useState(location.pathname);
  const [cookies, setCookie, removeCookie] = useCookies([
    process.env.REACT_APP_TOKEN_COOKIE_NAME,
  ]);
  const [, , removeCookieCreateFormFlow] = useCookies([
    process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
  ]);

  const [profileCookie, , removeProfileStatusCookie] = useCookies([
    process.env.REACT_APP_PS_COOKIE_NAME,
  ]);

  useEffect(() => {
    setUrlEnd(location.pathname);
  }, []);
  const handleLogout = async () => {
    try {
      let response = await AXIOS_REQUEST.get(LOGOUT_ENDPOINT, {
        headers: {
          Authorization:
            "Bearer " +
            cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response.status == 200) {
        removeCookie([process.env.REACT_APP_TOKEN_COOKIE_NAME]);
        removeCookieCreateFormFlow([
          process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
        ]);

        removeProfileStatusCookie([process.env.REACT_APP_PS_COOKIE_NAME]);
        toast.success("Successfully Logged Out.", {
          closeButton: true,
        });
        navigate(LOGIN);
      } else {
        toast.error("Something went wrong.", {
          closeButton: true,
        });
      }
    } catch (error) {
      console.log(error.message, error);
      toast.error("Something went wrong.", {
        closeButton: true,
      });
    }
  };

  return (
    <>
      <header>
        <div className="header_main">
          <div className="container">
            <div className="header">
              <div className="log_sec">
                <Link to={DASHBOARD}>
                  <img src={IMAGES.Onepageheaderimg} alt={"Carlie Anglemire"} />
                </Link>
              </div>
              <nav className="desk_nav">
                <ul className="first_ul">
                  <li>
                    <Link
                      to="#"
                      // to={"https://www.onepagepay.com/about/"}
                      // target="_blank"
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to={"#"}>Pricing</Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      // to={"https://www.onepagepay.com/demo/"}
                      // target="_blank"
                    >
                      Schedule a demo
                    </Link>
                  </li>
                </ul>
                {urlEnd.toLocaleLowerCase() !==
                  FORGOT_PASS.toLocaleLowerCase() &&
                urlEnd.toLocaleLowerCase() !==
                  CHANGE_PASSWORD.toLocaleLowerCase() &&
                (urlEnd.toLocaleLowerCase() !==
                  GET_SUPPORT.toLocaleLowerCase() ||
                  cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME]
                    ?.access_token) ? (
                  <div className="button_ul">
                    <ul>
                      <li>
                        <Link onClick={handleLogout}>Logout</Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </nav>

              <nav className="mobile_nav">
                <div className="hamburger">
                  <div className="icon">
                    <img
                      src={IMAGES.hamburger}
                      style={{ display: openModal ? "none" : "block" }}
                      alt={"hamburgerImg"}
                      onClick={() => setopenModal(true)}
                    />
                    {/* <img src={IMAGES.closeimg} style={{ display: openModal ? "block" : "none" }} alt={"closeImg"} onClick={() => setopenModal(false)} /> */}
                    <div
                      className="hamburger_content"
                      style={{ display: openModal ? "block" : "none" }}
                    >
                      <ul className="first_ul">
                        <div className="crossImg">
                          <img
                            src={IMAGES.closeimg}
                            style={{ display: openModal ? "block" : "none" }}
                            alt={"closeImg"}
                            onClick={() => setopenModal(false)}
                          />
                        </div>
                        <li>
                          <Link
                            to="#"
                            // to={"https://www.onepagepay.com/about/"}
                            // target="_blank"
                          >
                            About us
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>Pricing</Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            // to={"https://www.onepagepay.com/demo/"}
                            // target="_blank"
                          >
                            Schedule a demo
                          </Link>
                        </li>
                      </ul>
                      {(urlEnd.toLocaleLowerCase() !==
                        FORGOT_PASS.toLocaleLowerCase() &&
                        urlEnd.toLocaleLowerCase() !==
                          CHANGE_PASSWORD.toLocaleLowerCase() &&
                        urlEnd.toLocaleLowerCase() !==
                          GET_SUPPORT.toLocaleLowerCase()) ||
                      cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME]
                        ?.access_token ? (
                        <div className="button_ul">
                          <Link>
                            <button onClick={handleLogout}>Logout</button>
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {urlEnd.toLocaleLowerCase() === FORGOT_PASS.toLocaleLowerCase() ||
        urlEnd.toLocaleLowerCase() == CHANGE_PASSWORD.toLocaleLowerCase() ? (
          <div className="lost_password_header">
            <h1>Lost Password</h1>
          </div>
        ) : urlEnd === GET_SUPPORT &&
          !cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME]?.access_token ? (
          <div className="dashbord">
            <div className="container">
              <ul className="dashboard_ul">
                <li
                  className={
                    urlEnd.toLocaleLowerCase() ===
                    GET_SUPPORT.toLocaleLowerCase()
                      ? "active_header_li"
                      : null
                  }
                >
                  <Link to={GET_SUPPORT}>Get Support</Link>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="dashbord">
            <div className="container">
              <ul className="dashboard_ul">
                <li
                  className={
                    urlEnd.toLocaleLowerCase() === DASHBOARD.toLocaleLowerCase()
                      ? "active_header_li"
                      : null
                  }
                >
                  <Link to={DASHBOARD}>Dashboard</Link>
                </li>
                {urlEnd === MANAGE_PAYMENT ? (
                  <>
                    <li
                      className={
                        urlEnd.toLocaleLowerCase() ===
                        MANAGE_PAYMENT.toLocaleLowerCase()
                          ? "active_header_li"
                          : null
                      }
                    >
                      <Link to={DASHBOARD}>Manage Payment</Link>
                    </li>
                    <li
                      className={
                        urlEnd.toLocaleLowerCase() ===
                        MY_ACCOUNT.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            BILLING_INFO.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            PAYMENT_DETAIL.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            FINAL_SIGNUP.toLocaleLowerCase()
                          ? "active_header_li"
                          : null
                      }
                    >
                      <Link to={MY_ACCOUNT}>My Account</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      className={
                        urlEnd.toLocaleLowerCase() ===
                        MY_ACCOUNT.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            BILLING_INFO.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            PAYMENT_DETAIL.toLocaleLowerCase()
                          ? "active_header_li"
                          : urlEnd.toLocaleLowerCase() ===
                            FINAL_SIGNUP.toLocaleLowerCase()
                          ? "active_header_li"
                          : null
                      }
                    >
                      <Link to={MY_ACCOUNT}>My Account</Link>
                    </li>
                    <li
                      className={
                        urlEnd.toLocaleLowerCase() ===
                        GET_SUPPORT.toLocaleLowerCase()
                          ? "active_header_li"
                          : null
                      }
                    >
                      <Link to={GET_SUPPORT}>Get Support</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
