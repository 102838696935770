import React from 'react';
import '../css/CustomToast.css';

const CustomToast = ({ message, type, onClose }) => {
  return (
    <div className={`custom-toast ${type}`}>
      <p>{message}</p>
    </div>
  );
};

export default CustomToast;