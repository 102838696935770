import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomInput from "../../components/CustomInput";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import CustomRadioWithLabel from "../../components/CustomRadioWithLabel";
import CustomPaginationBox from "../../components/CustomPaginationBox";
import DotLoader from "../../components/DotLoader";

import "./FormDesign.css";
import { IMAGES } from "../../constants/images";
import { FormDesignSchema } from "../../helpers/validationSchema/FormFlowValidation";
import FormikCustomRadioWithLabel from "../../components/FormikCustomRadioWithLabel";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import { CREATE_FORM_DETAILS_ENDPOINT } from "../../constants/API_ENDPOINTS";
import { toast } from "react-toastify";

const FormDesign = () => {
  const { CRM, CONFIRM_SELECTION } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();
  const inputFile = useRef(null);

  const [cookies, setCookie] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [formDesignData, setFormDesignData] = useState();
  const [logoURL, setLogoURL] = useState();
  const [logoPath, setLogoPath] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [logoFile, setLogoFile] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form_id) {
      getFormDetails();
    } else {
      setFormDesignData(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
      if (cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].logo_file_path) {
        loadFile(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].logo_file_path);
        setLogoPath(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].logo_file_path);
      } else if (cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].logo_file_url) {
        setLogoURL(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].logo_file_url);
      }
    }
  }, []);

  //get form details step 5
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        5,
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        if (response.data[0]) {
          setLoading(false);
          setFormDesignData(response.data[0]);
          if (response?.data[0]?.logo_file_path) {
            loadFile(response?.data[0]?.logo_file_path);
          } else if (response?.data[0]?.logo_file_url) {
            setLogoURL(response?.data[0]?.logo_file_url);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const OpenChangeFile = (event) => {
    event.preventDefault();
    if (form_id) {
      let formData = formDesignData;
      formData.logo_file_path = "";
      setFormDesignData(formData);
    } else {
      setFormDesignData();
    }
    inputFile.current.click();
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    let image_path = "";
    let image_file;
    if (file) {
      setLoading(true);
      image_path = URL.createObjectURL(event.target.files[0]);
      image_file = event.target.files[0];

      setFormDesignData((prevData) => ({
        ...prevData,
        logo_file_path: image_path,
        logo_file_url: "",
      }));
      // setFormDesignData(formData);
      setLogoPath(image_path);
      loadFile(image_path);
      setLogoFile(file);
      setLogoURL("");
      setLoading(false);
      setFieldValue("logo_path", file);
      setFieldValue("upload_logo", file);
    } else {
      setLogoPath(image_path);
    }
  };

  //handle logo url
  const handleLogoUrl = async (logoUrl, setFieldValue) => {
    try {
      if (logoUrl) {
        const response = await fetch(logoUrl);
        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          setFormDesignData((prevData) => ({
            ...prevData,
            logo_file_path: "",
            logo_file_url: logoUrl,
          }));
          setLogoURL(imageUrl);
          setLogoPath("");
          setFieldValue("logo_url", logoUrl);
          setFieldValue("logo_path", "");
          // setFieldValue("logo_upload", null); // Reset the uploaded file
        } else {
          toast.error("Invalid Logo Image URL.");
          console.error("Failed to fetch image from URL.");
        }
      } else {
        setFormDesignData((prevData) => ({
          ...prevData,
          logo_file_url: "",
        }));
        setLogoURL(logoUrl);
      }
    } catch (error) {
      toast.error("Invalid Logo Image URL.");
      console.error("Error fetching image:", error);
    }
  };

  function loadFile(logoUrl) {
    if (logoUrl) {
      fetch(logoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          let filename = logoUrl.split("/")[logoUrl.split("/").length - 1];
          var file = new File([blob], filename, { type: blob.type });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          var fileInput = document.getElementById("upload_logo");
          if (dataTransfer.files) {
            fileInput.files = dataTransfer.files;
            setFileUpload(dataTransfer.files);
            setLogoFile(file);
            setLogoPath(logoUrl);
          }
        })
        .catch((error) => {
          console.log("Error loading file:", error.message);
        });
    }
  }

  const handleFormDesign = async (values) => {
    try {
      let formData = new FormData();

      let payload = {
        form_step: 5,
        form_id: form_id ? form_id : cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
        user_id: cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
        form_style: values.form_style,
        logo_file_path: values.upload_logo,
        logo_file_url: values.logo_url,
      };

      for (var key in payload) {
        formData.append(key, payload[key]);
      }

      setLoading(true);
      let response = await AXIOS_REQUEST.post(
        CREATE_FORM_DETAILS_ENDPOINT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
          },
        }
      );
      if (response.data.status) {
        setLoading(false);
        let newObj = {
          ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
          ...response.data.details,
        };

        setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
        if (form_id) {
          navigate(`${CONFIRM_SELECTION}/${form_id}`);
        } else {
          navigate(CONFIRM_SELECTION);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="form_design_main">
        <div className="page_container">
          <h1>Form Design</h1>

          <Formik
            initialValues={{
              upload_logo: formDesignData?.logo_file_path
                ? formDesignData?.logo_file_path
                  ? logoFile
                  : ""
                : "",
              form_style: formDesignData?.form_style
                ? formDesignData?.form_style
                : "single_column",
              logo_url:
                formDesignData?.logo_file_url &&
                formDesignData?.logo_file_url != "null"
                  ? formDesignData?.logo_file_url
                  : "",
            }}
            enableReinitialize
            onSubmit={(values) => handleFormDesign(values)}
            validationSchema={FormDesignSchema}
          >
            {({ errors, touched, handleChange, values, setFieldValue }) => (
              <Form>
                <div className="box">
                  <div className="box_body">
                    <div className="form_design">
                      <div className="logo_upload">
                        <CustomBoxHeader
                          title={"Logo Upload"}
                          title_img={IMAGES.image_logo}
                        />

                        <div className="logo_upload_body">
                          <div className="disc_container">
                            <div className="upload_btn_div">
                              <div className="upload_btn_div_left">
                                <label htmlFor="upload_logo">
                                  {/* {formDesignData?.logo_file_path
                                    ? formDesignData?.logo_file_path?.split(
                                        "/"
                                      )[
                                        formDesignData?.logo_file_path?.split(
                                          "/"
                                        ).length - 1
                                      ]
                                    : values.upload_logo
                                    ? values.upload_logo.name
                                    : "No file Chosen (Recommended Size - 250 x 150 px)"} */}
                                  {formDesignData?.logo_file_path
                                    ? ""
                                    : "No file Chosen (Recommended Size - 250 x 150 px)"}
                                </label>
                                <div className="upload_btn">
                                  <button onClick={OpenChangeFile}>
                                    {values.upload_logo
                                      ? "Change File"
                                      : "Choose File"}
                                  </button>
                                </div>

                                <input
                                  id="upload_logo"
                                  name="upload_logo"
                                  type="file"
                                  accept="image/*"
                                  ref={inputFile}
                                  onChange={(event) =>
                                    handleImageChange(event, setFieldValue)
                                  }
                                  style={{ display: "none" }}
                                />
                              </div>
                              {logoURL || logoPath ? (
                                <div className="upload_btn_div_right">
                                  <img
                                    src={
                                      logoPath && logoPath ? logoPath : logoURL
                                    }
                                    alt="upload_btn_div_right"
                                  />
                                </div>
                              ) : (
                                formDesignData?.logo_file_path && (
                                  <div className="upload_btn_div_right">
                                    Loading...
                                  </div>
                                )
                              )}
                            </div>

                            {errors.upload_logo && touched.upload_logo && (
                              <div className="invalid-error invalid-feedback">
                                {errors.upload_logo}
                              </div>
                            )}
                            <CustomInput
                              type={"text"}
                              label={"OR Enter Image URL"}
                              name="logo_url"
                              specialEffect={"logo_url"}
                              handleLogoUrl={() => {
                                handleLogoUrl(values.logo_url, setFieldValue);
                              }}
                              error={errors && errors.logo_url}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form_style">
                        <CustomBoxHeader
                          title={"Form Style"}
                          title_img={IMAGES.image_logo}
                        />
                        <div className="form_style_body">
                          <div className="form_style_body_left">
                            <div className="radio_main">
                              <FormikCustomRadioWithLabel
                                // label={"Single Column"}
                                label={"Order Summary on the Top"}
                                value={"single_column"}
                                id={"single_column"}
                                name={"form_style"}
                                selected={values.form_style == "single_column"}
                                widthBottomPad={true}
                              />
                              <FormikCustomRadioWithLabel
                                // label={"Two Column"}
                                label={"Order Summary on the Right"}
                                value={"two_column"}
                                id={"two_column"}
                                name={"form_style"}
                                selected={values.form_style == "two_column"}
                                widthBottomPad={true}
                              />
                              {/* <FormikCustomRadioWithLabel
                                label={"Custom Design (+£150)"}
                                value={"custom_design"}
                                id={"custom_design"}
                                name={"form_style"}
                                selected={values.form_style == "custom_design"}
                                widthBottomPad={true}
                              /> */}
                              {/* <span id="team_contact_label">
                                Our team will contact you for an accurate
                                quotation
                              </span> */}
                            </div>
                          </div>
                          <div className="form_style_body_right">
                            <div className="image_div">
                              <img
                                src={
                                  values.form_style == "two_column"
                                    ? IMAGES.formstylebg
                                    : IMAGES.formstylebgsingle
                                }
                                alt=""
                              />
                              {/* <img src={IMAGES.formstylebg} alt="" /> */}
                              {/* <img src={IMAGES.formstylebgsingle} alt="" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pagination_outer">
                      <CustomPaginationBox
                        total_pages={5}
                        page_number={5}
                        form={true}
                      />
                      <div className="next_buttn">
                        <Link
                          to={form_id ? `${CRM}/${form_id}` : CRM}
                          className="back_btn"
                        >
                          <button>Back</button>
                        </Link>
                        {/* <Link to={CONFIRM_SELECTION}> */}
                        <button type="submit">Next</button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormDesign;
