import React from "react";
import "./style.css";
const NoData = ({ style, message }) => {
  return (
    <div style={style ? style : {}} className="no_data_container">
      {message}
    </div>
  );
};

export default NoData;
