import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactPaginate from "react-paginate";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";

import "./dashboard.css";
import "./paginate.css";
import { IMAGES } from "../../constants/images";
import { toast } from "react-toastify";
import CustomModal from "../../components/CustomModal";
import ConfirmationModalContent, {
  customStyles,
} from "../../components/ConfirmationModalContent";

import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import {
  getAllFormsAPI,
  getDefaultCRM_API,
  getDefaultPP_API,
} from "../../APIs";
import {
  ALL_FORMS_ITEMS_PER_PAGE,
  OPP_PORTAL_CURRENCY,
} from "../../constants/EXTRA_CONSTANTS";
import DotLoader from "../../components/DotLoader";
import {
  ActiveComponent,
  InActiveComponent,
} from "../../components/IconComponents";
import PageTitleHeader from "../../components/PageTitleHeader";
import { ANNUAL_FORM_PRICE, MONTHLY_FORM_PRICE } from "../../constants";

const Dashboard = () => {
  const {
    MANAGE_PAYMENT,
    CHANGE_CRM,
    CHANGE_PAYMENT_PROCESSOR,
    CREATE_FORM,
    FORM_CODE,
  } = ROUTE_URLS;
  const navigate = useNavigate();
  useScrollTo(0, 0);

  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [selectedPP, setSelectedPP] = useState();
  const [selectedCRM, setSelectedCRM] = useState();
  const [formsData, setFormsData] = useState([]);
  const [planToCancel, setPlanToCancel] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllForms = async () => {
    try {
      setLoading(true);
      let response = await getAllFormsAPI(
        "Bearer " +
          Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        setFormsData(response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const getDefaultCRM = async () => {
    try {
      setLoading(true);

      let data = await getDefaultCRM_API(
        "Bearer " +
          Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (data) {
        setLoading(false);
        setSelectedCRM(data.default_crm);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getDefaultPP = async () => {
    try {
      setLoading(true);
      let data = await getDefaultPP_API(
        "Bearer " +
          Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (data) {
        setLoading(false);
        setSelectedPP(data.default_PP);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // getting forms, default CRM and PP on page load.
  useEffect(() => {
    getAllForms();
    getDefaultCRM();
    getDefaultPP();
  }, []);

  const cancelModal = (id) => {
    setPlanToCancel(id);
    setOpenModal(true);
  };

  const cancelPayment = (planToCancel, flag) => {
    if (flag == "No") {
      setPlanToCancel();
      setOpenModal(false);
    } else if (flag == "Yes") {
      let filteredPayments = formsData.filter(
        (item) => item.id !== planToCancel
      );
      setFormsData(filteredPayments);
      setOpenModal(false);
      setPlanToCancel();
      toast.info("Form Plan cancelled Successfully.");
    }
  };

  const editForm = (id) => {
    navigate(CREATE_FORM + "/" + id);
  };

  const getCode = (item) => {
    navigate(FORM_CODE + "/" + item.id + "/" + item.frame + "/" + item.name);
  };

  //pagination methods
  // Invoke when user click to request another page.
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  let itemsPerPage = ALL_FORMS_ITEMS_PER_PAGE;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems =
    formsData &&
    Array.isArray(formsData) &&
    formsData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(formsData?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % formsData?.length;
    setItemOffset(newOffset);
  };

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((item, index) => (
            <tr>
              <td>{item?.name}</td>
              <td>{item.is_shipping_address ? "✔" : "X"}</td>
              <td>
                {item.form_type == "single_product"
                  ? "Single Product"
                  : item.form_type == "multiple_product"
                  ? "Multi Products"
                  : ""}
              </td>
              <td>
                {item.form_type == "single_product"
                  ? JSON.parse(item?.form_type_products)?.[0].product_name
                  : JSON.parse(item?.form_type_products)
                      ?.map((product) => product.product_name)
                      .join(", ")}
              </td>
              <td>
                {item.form_type == "single_product"
                  ? JSON.parse(item?.form_type_products)?.[0].product_price
                  : null}
              </td>
              <td>
                {item.payment_frequency == "monthly"
                  ? "Monthly"
                  : item.payment_frequency == "annually"
                  ? "Annually"
                  : null}
              </td>
              <td>
                {item.payment_frequency == "monthly"
                  ? OPP_PORTAL_CURRENCY + MONTHLY_FORM_PRICE
                  : item.payment_frequency == "annually"
                  ? OPP_PORTAL_CURRENCY + ANNUAL_FORM_PRICE
                  : null}
              </td>
              <td style={{ textAlign: "center" }}>
                {item.is_active ? (
                  <ActiveComponent title={"Form is Active."} />
                ) : (
                  <InActiveComponent title={"Finish form setup to activate."} />
                )}
              </td>

              <td className="Edit_button">
                {item.is_active ? (
                  <button type="button" onClick={() => getCode(item)}>
                    Get Code
                  </button>
                ) : (
                  <></>
                )}

                <button type="button" onClick={() => editForm(item.id)}>
                  Edit Form
                </button>
              </td>
            </tr>
          ))}
      </>
    );
  }

  return (
    <>
      <DotLoader loading={loading} />
      <CustomModal
        contentStyles={customStyles}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        content={
          <ConfirmationModalContent
            paymentToCancel={planToCancel}
            cancelPayment={cancelPayment}
          />
        }
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <Header />
      <div id="dashboard">
        <div className="page_container">
          <PageTitleHeader
            title={"My Payment Forms"}
          />
          <div className="dashboard_inner">
            <CustomBoxHeader
              title={"Current Forms"}
              title_img={IMAGES.adminimg}
            />
            <div className="forms_body">
              <table>
                {currentItems.length != 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th>Form Name</th>
                        <th>Shipping Address</th>
                        <th>
                          Form <br /> Type
                        </th>
                        <th>Product Names</th>
                        <th>Product Price</th>
                        <th>Plan</th>
                        <th>Price</th>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="forms_list" id="forms_list">
                      <Items currentItems={currentItems} />
                    </tbody>
                  </>
                ) : (
                  <div
                    className="nothing_selected"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Forms Found.
                  </div>
                )}
              </table>
            </div>
            <ReactPaginate
              nextLabel="Next"
              previousLabel="Previous"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              nextClassName="page-item"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={"react_pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              renderOnZeroPageCount={null}
            />
          </div>
          <div className="payment_sec">
            <div className="payment_sec1">
              <CustomBoxHeader
                title={"Payment Methods"}
                title_img={IMAGES.adminimg}
              />

              <div className="payment_body">
                {selectedPP ? (
                  <div className="inner_image">
                    {selectedPP == "stripe" ? (
                      <div className="img_py_sec">
                        <img src={IMAGES.Stripe_logo_revised_} alt="payment" />
                      </div>
                    ) : null}
                    {selectedPP == "paypal" ? (
                      <div className="img_py_sec">
                        <img src={IMAGES.paypal_logo} alt="content2img" />
                      </div>
                    ) : null}
                    {selectedPP == "gocardless" ? (
                      <div className="img_py_sec">
                        <img src={IMAGES.gocardless} alt="content3" />
                      </div>
                    ) : null}

                    {selectedPP == "takepayment" ? (
                      <>
                        <div className="img_py_sec">
                          <img src={IMAGES.takepayment} alt="content41" />
                        </div>
                        <div className="img_py_sec">
                          {" "}
                          <img src={IMAGES.takepaymenttext} alt="content42" />
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="nothing_selected">
                    No Payment Processor is connected.
                  </div>
                )}
                {selectedPP ? (
                  <Link to={CHANGE_PAYMENT_PROCESSOR}>
                    <button>Change payment processor</button>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="payment_sec2">
              <CustomBoxHeader
                title={"CRM Methods"}
                title_img={IMAGES.securityimg}
              />

              <div className="payment_body">
                {selectedCRM ? (
                  <div className="inner_image">
                    {selectedCRM == "mailchimp" ? (
                      <div className="inner_image hubspot">
                        <div className="img_py_sec">
                          <img src={IMAGES.Mailchimp_logo} alt="payment" />
                        </div>
                      </div>
                    ) : null}
                    {selectedCRM == "hubspot" ? (
                      <div className="inner_image hubspot">
                        <div className="img_py_sec">
                          {" "}
                          <img src={IMAGES.HubSpot_Primary} alt="Edit CRM" />
                        </div>
                      </div>
                    ) : null}
                    {selectedCRM == "zoho" ? (
                      <div className="inner_image hubspot">
                        <div className="img_py_sec">
                          <img src={IMAGES.zoho_logo} alt="content3" />
                        </div>
                      </div>
                    ) : null}
                    {selectedCRM == "no_crm" ? (
                      <div className="content content_no_crm">
                        <p>I don't have CRM, just give me Payment Form.</p>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="nothing_selected">No CRM is connected.</div>
                )}
                {selectedCRM ? (
                  <Link to={CHANGE_CRM}>
                    <button>Change CRM</button>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
