import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useGoogleLogin } from "@react-oauth/google";

import {
  EyeCloseComponent,
  EyeOpenComponent,
} from "../../../../components/IconComponents";
import CustomInput from "../../../../components/CustomInput";
import StrengthMeter from "../../../../components/StrengthMeter";
import DotLoader from "../../../../components/DotLoader";
import TestimonialCarousel from "../../../../components/TestimonialCarousel";

import "./Signup.css";

import { RegisterSchema } from "../../../../helpers/validationSchema";
import AXIOS_REQUEST from "../../../../helpers/Axios";
import { IMAGES } from "../../../../constants/images";
import { GOOGLE_OAUTH_CLIENT_ID } from "../../../../constants";
import { ROUTE_URLS } from "../../../../constants/URLS";
import {
  GOOGLE_SIGNIN_SIGNUP_ENDPOINT,
  REGISTER_ENDPOINT,
} from "../../../../constants/API_ENDPOINTS";
import SignWithGoogle from "../../../../components/CustomGoogleAuthButton";

const Register = () => {
  const responseGoogle = async (Gresponse) => {
    // Handle the Google sign-in response here
    try {
      Gresponse.operation = "register";
      let response = await AXIOS_REQUEST.post(
        GOOGLE_SIGNIN_SIGNUP_ENDPOINT,
        Gresponse
      );
      if (response) {
        if (response.status === 200 && response.data.status) {
          setLoading(false);

          const data = {
            google_login_id: response?.data?.user?.google_login_id,
            access_token: response.data.access_token,
            expiration: 24 * 60 * 60 * 1000, // expires in 30 minutes
          };
          const expiryTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
          const expirationDate = new Date(Date.now() + expiryTime);

          // if (values.rememberMe) {
          setCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME, JSON.stringify(data), {
            maxAge: expiryTime,
          });
          if (response.data.profile_status) {
            navigate(MY_ACCOUNT);
          } else {
            navigate(BILLING_INFO);
          }
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  //sign up google hook
  const signupWithGoogle = useGoogleLogin({
    clientId: GOOGLE_OAUTH_CLIENT_ID,
    onSuccess: responseGoogle,
    onFailure: responseGoogle,
    accessType: "offline",
    scope: "email profile",
  });

  const { LOGIN, FORGOT_PASS, BILLING_INFO, DASHBOARD, MY_ACCOUNT } =
    ROUTE_URLS;
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [password, setPassword] = useState();
  const [passShow, setPassShow] = useState(true);
  const [confirmPassShow, setConfirmPassShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const [isStrong, initRobustPassword] = useState(null);
  const handleInput = (e) => {
    e.preventDefault();
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const IconClickConfirmPassword = () => {
    setConfirmPassShow(!confirmPassShow);
  };

  const IconClickPassword = () => {
    setPassShow(!passShow);
  };

  const handleRegister = async (values) => {
    try {
      let raw = {
        email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
      };
      setLoading(true);
      let response = await AXIOS_REQUEST.post(REGISTER_ENDPOINT, raw);
      if (response.status === 200) {
        setLoading(false);

        const data = {
          access_token: response.data.access_token,
          expiration: 24 * 60 * 60 * 1000, // expires in 30 minutes
        };
        const expiryTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        const expirationDate = new Date(Date.now() + expiryTime);

        if (values.rememberMe) {
          setCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME, JSON.stringify(data), {
            maxAge: expiryTime,
          });
          if (response.data.profile_status) {
            navigate(MY_ACCOUNT);
          } else {
            navigate(BILLING_INFO);
          }
        } else {
          setCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME, JSON.stringify(data));
          if (response.data.profile_status) {
            navigate(MY_ACCOUNT);
          } else {
            navigate(BILLING_INFO);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.email) {
        let x = 1500;
        error.response.data.email.forEach((element) => {
          toast.error(element, { autoClose: x + 500 });
          x = x + 500;
        });
      }
      if (error.response.data.password) {
        let x = 1500;
        error.response.data.password.forEach((element) => {
          toast.error(element, { autoClose: x + 500 });
          x = x + 500;
        });
      }
    }
  };
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  useEffect(() => {
    let unparsed_user = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
    if (unparsed_user) {
      const storedData = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
      if (storedData && storedData.expiration > Date.now()) {
        const value = storedData.access_token;
        if (value) {
          return <Navigate to={DASHBOARD} />;
        }
      }
    }
  }, []);

  return (
    <>
      <DotLoader loading={loading} />
      <div id="first_signup">
        <div className="container_sec">
          <div className="login">
            <div className="inner_container_login">
              <div className="login_upper_header">
                <a href="./">
                  <img src={IMAGES.account_img} alt="" />
                </a>
                <div className="with_google_btn">
                  <p>No apps configured. Please contact your administrator.</p>
                  <SignWithGoogle
                    title="Sign up with google."
                    GoogleAuthAPI={signupWithGoogle}
                  />
                </div>
              </div>
              <div
                style={{
                  maxWidth: "600px",
                  width: "100%",
                }}
              >
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => {
                    handleRegister(values);
                  }}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <CustomInput
                        type="text"
                        name="email"
                        id="email"
                        label={"Email Address"}
                        required={true}
                        error={errors && errors.username}
                      />
                      <CustomInput
                        type={passShow ? "password" : "text"}
                        name="password"
                        id="password"
                        label={"Password"}
                        required={true}
                        IconComponent={
                          passShow ? EyeCloseComponent : EyeOpenComponent
                        }
                        IconClick={IconClickPassword}
                        error={errors && errors.password}
                      />
                      <CustomInput
                        type={confirmPassShow ? "password" : "text"}
                        name="confirmPassword"
                        id="confirmPassword"
                        label={"Confirm Password"}
                        required={true}
                        IconComponent={
                          confirmPassShow ? EyeCloseComponent : EyeOpenComponent
                        }
                        IconClick={IconClickConfirmPassword}
                        error={errors && errors.confirmPassword}
                      />
                      <StrengthMeter
                        password={values.password}
                        actions={initPwdInput}
                      />
                      <div className="hint_container">
                        <div className="hint_login">
                          <p>
                            Hint: The password should be atleast twelve
                            characters long. To make it stronger, use upper and
                            lower case letters, numbers and symbols like
                            !"?%^&).
                          </p>
                        </div>
                      </div>

                      <div className="checbox_container">
                        <div className="checkbox_sec">
                          <button type="submit">Register</button>

                          <ul>
                            <li>
                              <Link to={LOGIN}>Login</Link>
                            </li>
                            <li>
                              <Link to={FORGOT_PASS}>Lost your password?</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <div className="carousel_sendmsg_div">
            <div className="testimonial">
              <TestimonialCarousel />
            </div>
            <div className="msg_btn">
              <button>Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
