import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import Autocomplete from "react-autocomplete";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import CustomBoxHeader from "../../../../components/CustomBoxHeader";
import CustomInput from "../../../../components/CustomInput";
import CustomPaginationBox from "../../../../components/CustomPaginationBox";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import DotLoader from "../../../../components/DotLoader";

import "./Signup.css";
import { IMAGES } from "../../../../constants/images";
import { BillingInfoSchema } from "../../../../helpers/validationSchema";
import {
  getCities,
  getCountriesObjects,
  getStatesOfACountry,
} from "../../../../helpers/helperFunctions";
import AXIOS_REQUEST from "../../../../helpers/Axios";
import { ROUTE_URLS } from "../../../../constants/URLS";
import {
  UPDATE_PROFILE_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from "../../../../constants/API_ENDPOINTS";

export default function BillingInfo() {
  const { PAYMENT_DETAIL } = ROUTE_URLS;
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [countriesObjects, setCountryObjects] = useState([]);
  const [countriesObjectsUpdates, setCountriesObjectsUpdates] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [Tokencookie, setTokenCookie] = useCookies([
    process.env.REACT_APP_TOKEN_COOKIE_NAME,
  ]);
  const [loading, setLoading] = useState(false);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const getAndSetCountriesList = async () => {
    let countriesObjectsFromAPI = await getCountriesObjects();
    let countriesList = countriesObjectsFromAPI.map((item) => item?.name);
    setCountryObjects(countriesObjectsFromAPI);
    setCountryList(countriesList);
    let countriesObjectsUpdatesArray = [{ value: "", label: "Select Country" }];
    countriesList.forEach((item) => {
      countriesObjectsUpdatesArray.push({ value: item, label: item });
    });
    setCountriesObjectsUpdates(countriesObjectsUpdatesArray);
    setCountries(countriesObjectsUpdatesArray);
    return countriesObjectsFromAPI;
  };

  useEffect(() => {
    async function y() {
      setLoading(true);
      let user_details = await getUserDetails();
      let countriesObjectsFromAPI = await getAndSetCountriesList();
      if (user_details.firstname) {
        setUserDetails(user_details);
        // let iso2OfSelectedCountry = countriesObjectsFromAPI.filter(
        //   (item) => item?.name == user_details?.address?.country
        // )[0]?.iso2;

        // let statesFromAPI = await getStatesOfACountry(iso2OfSelectedCountry);
        // let getSelectedState = statesFromAPI.filter(
        //   (item) => item.name == user_details?.address?.state
        // )[0];
        // let citiesFromAPI = await getCities(
        //   getSelectedState?.country_code,
        //   getSelectedState?.state_code
        // );
        // setStates(statesFromAPI);
        // setCities(citiesFromAPI);
        setLoading(false);
      } else {
        setUserDetails(user_details);
        setLoading(false);
      }
    }

    y();
  }, []);

  //navigate to Payment Detail page
  const handleBillingInfo = async (values) => {
    try {
      let response = await AXIOS_REQUEST.post(UPDATE_PROFILE_ENDPOINT, values);
      if (response && response.status == 200) {
        if (!response.data.status) {
          setLoading(false);
          toast.error(response.data.message);
        } else {
          setLoading(false);
          toast.success("Billing Details Saved Successfully");
          navigate(PAYMENT_DETAIL);
        }
      }
    } catch (error) {
      toast.error("UPDATE PROFILE:", error.message);
      setLoading(false);
    }
  };

  //get details of a user
  const getUserDetails = async () => {
    let response = await AXIOS_REQUEST.get(USER_PROFILE_ENDPOINT);
    if (response) {
      return response.data;
    }
  };

  const handleCountry = async (event, setFieldValue) => {
    setLoading(true);
    if (event.target.name === "country") {
      const selectedCountry = event.target.value;
      try {
        let iso2OfSelectedCountry = countriesObjects.filter(
          (item) => item.name == selectedCountry
        )[0].iso2;
        let response = await getStatesOfACountry(iso2OfSelectedCountry);
        setStates(response);
        setFieldValue("country", selectedCountry);
        setFieldValue("state", "");
        setFieldValue("city", "");
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleState = async (event, setFieldValue) => {
    setLoading(true);
    if (event.target.name === "state") {
      const selectedState = event.target.value;
      try {
        let selectedStateX = states?.filter(
          (item) => item.name == selectedState
        )[0];
        let response = await getCities(
          selectedStateX.country_code,
          selectedStateX.state_code
        );
        setCities(response);
        setFieldValue("state", selectedState);
        setFieldValue("city", "");
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="billing_info_sec">
        <div className="page_container">
          <div className="info_bill">
            <h2>
              Welcome to One Page Pay <br /> Please create your trial account to
              continue
            </h2>
            <Formik
              initialValues={{
                firstname: userDetails ? userDetails?.firstname : "",
                lastname: userDetails ? userDetails?.lastname : "",
                email: userDetails ? userDetails?.email : "",
                phone: userDetails ? userDetails?.phone : "",
                company: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.company
                    : userDetails?.companyname
                  : "",
                streetaddress: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.streetaddress
                    : userDetails?.address?.streetaddress
                  : "",
                addressline2: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.addressline2
                    : userDetails?.address?.addressline2
                  : "",
                country: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.country
                    : userDetails?.address?.country
                  : "",
                state: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.state
                    : userDetails?.address?.state
                    ? userDetails?.address?.state
                    : ""
                  : "",
                city: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.city
                    : userDetails?.address?.city
                    ? userDetails?.address?.city
                    : ""
                  : "",
                postalcode: userDetails
                  ? userDetails?.fromCookie
                    ? userDetails?.postalcode
                    : userDetails?.address?.postalcode
                  : "",
                shareForMarketing: userDetails?.shareForMarketing
                  ? userDetails?.shareForMarketing == 1
                    ? true
                    : false
                  : true,
              }}
              enableReinitialize={true}
              validationSchema={BillingInfoSchema}
              onSubmit={(values) => handleBillingInfo(values)}
            >
              {({ errors, touched, handleChange, values, setFieldValue }) => (
                <Form>
                  <div className="billing_inner">
                    <div className="billing_one">
                      <CustomBoxHeader
                        title={"Billing Contact"}
                        title_img={IMAGES.editimg}
                      />
                      <div className="box_body">
                        <CustomInput
                          type="text"
                          name="firstname"
                          label={"First Name"}
                          error={errors && errors.firstname}
                        />
                        <CustomInput
                          type="text"
                          name="lastname"
                          label={"Last Name"}
                          error={errors && errors.lastname}
                        />
                        <CustomInput
                          type="email"
                          name="email"
                          label={"Email"}
                          readOnly
                          error={errors && errors.email}
                        />
                        <CustomInput
                          type="text"
                          name="phone"
                          label={"Phone"}
                          error={errors && errors.phone}
                          maxLength={15}
                        />
                        <CustomInput
                          type="text"
                          name="company"
                          label={"Company Name"}
                          error={errors && errors.company}
                        />
                      </div>
                    </div>
                    <div className="billing_one">
                      <CustomBoxHeader
                        title={"Billing Address"}
                        title_img={IMAGES.worldimg}
                      />
                      <div className="box_body">
                        <CustomInput
                          type="text"
                          name="streetaddress"
                          label={"Street Address"}
                          error={errors && errors.streetaddress}
                        />
                        <CustomInput
                          type="text"
                          name="addressline2"
                          label={"Address Line 2 (Optional)"}
                          error={errors && errors.addressline2}
                        />

                        <div
                          className="selct_input"
                          style={{ minHeight: "70px" }}
                        >
                          <label htmlFor="country">Country</label>

                          <div style={{ width: "100%", position: "relative" }}>
                            <Autocomplete
                              getItemValue={(item) => item.label}
                              items={countries}
                              wrapperProps={{
                                style: {
                                  width: "100%",
                                  background: "var(--input-bg)",
                                  position: "absolute",
                                  zIndex: 99999,
                                },
                                className: "input_container",
                              }}
                              menuStyle={{
                                background: "white",
                                minWidth: "auto",
                                maxHeight: "200px",
                                overflowY: "scroll",
                                top: "unset",
                                left: "unset",
                                position: "absolute",
                                width: "100%",
                              }}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  style={{
                                    background: isHighlighted
                                      ? "lightgray"
                                      : "var(--input-bg)",
                                    zIndex: 99999,
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.label}
                                </div>
                              )}
                              value={values.country}
                              onChange={(event, val) =>
                                setFieldValue("country", val)
                              }
                              onSelect={(val) => setFieldValue("country", val)}
                              shouldItemRender={(item, value) =>
                                item.label
                                  .toLowerCase()
                                  .includes(value.toLowerCase())
                              }
                            />
                          </div>
                        </div>
                        {touched.country && errors.country ? (
                          <div className="invalid-error invalid-feedback">
                            {errors.country}
                          </div>
                        ) : null}

                        <CustomInput
                          type="text"
                          name="state"
                          label={"State / County (Optional)"}
                          // required={true}
                          error={errors && errors.state}
                        />
                        <CustomInput
                          type="text"
                          name="city"
                          label={"City (Optional)"}
                          // required={true}
                          error={errors && errors.city}
                        />

                        <CustomInput
                          type="text"
                          name="postalcode"
                          label={"Zip / Postal Code"}
                          required={true}
                          error={errors && errors.postalcode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="register_pagination">
                    <div className="checkbox_main">
                      <CustomCheckbox
                        label={"I would like to be contacted for marketing"}
                        id={"shareForMarketing"}
                        checked={values.shareForMarketing == true}
                        name={"shareForMarketing"}
                        widthBottomPad
                      />
                    </div>

                    <div className="pagination_outer">
                      <CustomPaginationBox page_number={1} total_pages={3} />
                    </div>

                    <div className="next_buttn">
                      {/* <Link to={PAYMENT_DETAIL}> */}
                      <button type="submit">Next</button>
                      {/* </Link> */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
