import React, { useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import CustomToast from "../../../components/CustomToast";

import "./forgotpass.css";
import { Form, Formik } from "formik";
import { ForgotPassSchema } from "../../../helpers/validationSchema";
import AXIOS_REQUEST from "../../../helpers/Axios";
import { ROUTE_URLS } from "../../../constants/URLS";
import { PASSWORD_RESET_MAIL_ENDPOINT } from "../../../constants/API_ENDPOINTS";
import DotLoader from "../../../components/DotLoader";

export default function ForgotPass() {
  const { LOGIN, REGISTER } = ROUTE_URLS;
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);

  const showToast = (message, type) => {
    setToast({ message, type });
  };

  const handleCreateNewPassword = async (values) => {
    try {
      setLoading(true);
      let response = await AXIOS_REQUEST.post(
        PASSWORD_RESET_MAIL_ENDPOINT,
        values
      );

      if (response.status == 200) {
        setLoading(false);
        if (response.data.status) {
          showToast("Check your email for the confirmation link.", "message");
        } else {
          showToast(response.data.message, "error");
        }
      } else {
        showToast(response.error, "error");
      }
    } catch (error) {
      setLoading(false);
      showToast("Something went wrong.", "error");
      setTimeout(() => {
        setToast(null);
      }, 10000);
    }
  };
  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="lost_password_sec">
        <div className="page_container">
          <div className="forgot">
            {toast && (
              <CustomToast
                message={toast.message}
                type={toast.type}
                onClose={() => setToast(null)}
              />
            )}
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotPassSchema}
              onSubmit={(values) => {
                handleCreateNewPassword(values);
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form>
                  <CustomInput
                    type="text"
                    name="email"
                    label={"Username or Email Address"}
                    required={true}
                    error={errors && errors.email}
                  />
                  <button type="submit">Get New password</button>
                </Form>
              )}
            </Formik>
            <div className="logi_register">
              <ul>
                <Link to={LOGIN}>
                  <li>Log in</li>
                </Link>
                <Link to={REGISTER}>
                  <li>Register</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
