import React from "react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/fontawesome-free-solid";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import testiMonial1 from "../../images/testimonial/testiMonial1.jpg";
import testiMonial2 from "../../images/testimonial/testiMonial2.jpg";
import testiMonial3 from "../../images/testimonial/testiMonial3.jpg";
import testiMonial4 from "../../images/testimonial/testiMonial4.jpg";

const TestimonialCarousel = () => {
  const TestimonialData = [
    {
      id: 1,
      Name: "Olly Thompson",
      Company_Name: "The Business Brain",
      Image_item: testiMonial1,
      Description:
        "The support and setup of One Page Pay was so simple to use and worked seamlessly to get our membership payments working straight away.",
      Date_Testi: "January 15, 2021",
    },
    {
      id: 2,
      Name: "Terry Mccoy",
      Company_Name: "The NLP Works",
      Image_item: testiMonial2,
      Description:
        "All of my questions were answered quickly and effectively. Navigating the One Page Pay wizard was very simple to understand. My payment forms were up and I was taking payments within 15 mins!",
      Date_Testi: "March 05, 2020",
    },
    {
      id: 3,
      Name: "Amaya Visayas",
      Company_Name: "Land Ocean",
      Image_item: testiMonial3,
      Description:
        "One Page Pay was the answer to our payment issues. We spent hours trying to take payments over the phone. With One Page Pay we were able to connect our payments provider and create our payment forms in under 10 mins. Amazing support form the guys, they talked us through the process and now we are using it to make all of our payments online capable.",
      Date_Testi: "September 03, 2020",
    },
    {
      id: 4,
      Name: "Syed Mohsin Ahmed",
      Company_Name: "Tax Focus",
      Image_item: testiMonial4,
      Description:
        "Before One Page Pay, we struggled to take payments. Now we can take online payments while we have clients on the phone. We simply direct them to the right page on our website, they punch in the payment details and our subscriptions are set up. It couldn't be easier.",
      Date_Testi: "August 06, 2019",
    },
  ];

  return (
    <div className="carousel">
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={3000}
      >
        {TestimonialData?.map((item, index) => (
          <div key={index} className="testimonial_item">
            <FontAwesomeIcon icon={faQuoteLeft} />
            <p className="content">{item.Description}</p>
            <h3>{item.Name}</h3>
            <h6>{item.Company_Name}</h6>
            <div className="img_card_test">
              <img src={item.Image_item}></img>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimonialCarousel;
