import React from "react";

import "./index.css";

import Google_img from "../../images/Google/Google_img.png";

function SignWithGoogle({ title, GoogleAuthAPI }) {
  return (
    <div className="signWithGoogle" onClick={GoogleAuthAPI}>
      <img src={Google_img} alt="" />
      <button className="customBtn" id="customBtn" >
        {title}
      </button>
    </div>
  );
}

export default SignWithGoogle;
