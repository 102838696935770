import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import DotLoader from "../../components/DotLoader";

import "./dashboard.css";
import { IMAGES } from "../../constants/images";
import { useScrollTo } from "../../hooks/useScrollTo";
import {
  GET_DEFAULT_CRM,
  UPDATE_DEFAULT_CRM,
} from "../../constants/API_ENDPOINTS";
import AXIOS_REQUEST from "../../helpers/Axios";
import { CapitalizeAWord } from "../../helpers/helperFunctions";
import PageTitleHeader from "../../components/PageTitleHeader";
import { ROUTE_URLS } from "../../constants/URLS";
import { Link } from "react-router-dom";

export default function Crm() {
  useScrollTo(0, 0);

  const { DASHBOARD } = ROUTE_URLS;
  const [cookies] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [selectedCRM, setSelectedCRM] = useState();
  const [loading, setLoading] = useState(false);
  let token;

  //get default CRM of a user API Call
  const GetDefaultCRM = async () => {
    try {
      setLoading(true);
      let response = await AXIOS_REQUEST.get(GET_DEFAULT_CRM, {
        headers: {
          Authorization:
            "Bearer " +
            cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response?.data?.status) {
        setLoading(false);
        setSelectedCRM(response.data.default_crm);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  // get and set default CRM from API on page load.
  useEffect(() => {
    GetDefaultCRM();
  }, []);

  const CheckWindowOpenedOrNot = (newTab) => {
    token = setInterval(() => {
      if (newTab.closed) {
        clearInterval(token);
        setLoading(false);
      }
    }, 1000);
  };

  // to change the CRM
  const handleUpdateCRM = async (crm) => {
    if (crm !== selectedCRM) {
      setLoading(true);
      const data = {
        to: crm,
      };
      let response = await AXIOS_REQUEST.post(UPDATE_DEFAULT_CRM, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response.data.status) {
        setLoading(false);
        toast.info(response.data.message, {
          closeButton: true,
        });
        setSelectedCRM(crm);
      } else {
        setLoading(false);
        if (crm == "hubspot" || crm == "mailchimp") {
          if (!response.data.url) {
            toast.error(
              "Server Error: URL not provided for : " + CapitalizeAWord(crm)
            );
          } else {
            setLoading(true);
            const newTab = window.open(response.data.url, "_blank");
            CheckWindowOpenedOrNot(newTab);
            window.addEventListener("message", function (event) {
              // Check if the message is coming from the new tab
              if (event.source === newTab) {
                let responseFromNewTab = event.data;
                if (responseFromNewTab.status) {
                  setLoading(false);
                  toast.info(
                    CapitalizeAWord(crm) + " has been connected successfully."
                  );
                  setSelectedCRM(crm);
                }
              }
            });
          }
        } else {
          setLoading(false);
          toast.info("Nothing Selected.");
        }
      }
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="crm_sec">
        <div className="page_container">
          <PageTitleHeader title={"Edit CRM"} />

          <div className="crm_main">
            <CustomBoxHeader title={"Edit CRM"} title_img={IMAGES.adminimg} />
            <div className="crm_body">
              <h2>{selectedCRM ? "Change " : "Select "} your CRM System</h2>
              <div className="content_box">
                <div
                  className="content content1"
                  style={{
                    border:
                      selectedCRM == "hubspot" ? `2px solid #e46e2e ` : null,
                  }}
                  onClick={() => handleUpdateCRM("hubspot")}
                >
                  <img src={IMAGES.HubSpot_Primary} alt="Edit CRM" />
                </div>
                <div
                  className="content content2"
                  style={{
                    border:
                      selectedCRM == "mailchimp" ? `2px solid #e46e2e ` : null,
                  }}
                  onClick={() => handleUpdateCRM("mailchimp")}
                >
                  <img src={IMAGES.Mailchimp_logo} alt="Edit CRM" />
                </div>
                <div
                  className="content content_no_crm"
                  style={{
                    border:
                      selectedCRM == "no_crm" ? `2px solid #e46e2e ` : null,
                  }}
                  onClick={() => handleUpdateCRM("no_crm")}
                >
                  <p>I don't have CRM, just give me Payment Form.</p>
                </div>
              </div>
              <Link to={DASHBOARD} className="back_btn_primary">
                <button>Back to Dashboard</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
