import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";
import { useGoogleLogin } from "@react-oauth/google";

import CustomInput from "../../components/CustomInput";
import DotLoader from "../../components/DotLoader";
import TestimonialCarousel from "../../components/TestimonialCarousel";
import CheckboxInput from "../../components/FormikCustomCheckBox";

import {
  EyeCloseComponent,
  EyeOpenComponent,
} from "../../components/IconComponents";

import "./account.css";
import { IMAGES } from "../../constants/images";
import { LoginSchema } from "../../helpers/validationSchema";
import AXIOS_REQUEST from "../../helpers/Axios";
import { ROUTE_URLS } from "../../constants/URLS";
import {
  GOOGLE_SIGNIN_SIGNUP_ENDPOINT,
  LOGIN_ENDPOINT,
} from "../../constants/API_ENDPOINTS";
import { GOOGLE_OAUTH_CLIENT_ID } from "../../constants";
import SignWithGoogle from "../../components/CustomGoogleAuthButton";
import jwtDecode from "jwt-decode";

const Loginpage = () => {
  const { REGISTER, FORGOT_PASS, DASHBOARD, BILLING_INFO } = ROUTE_URLS;

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    process.env.REACT_APP_TOKEN_COOKIE_NAME,
  ]);
  const [psCookie, setPSCookie] = useCookies([
    process.env.REACT_APP_PS_COOKIE_NAME,
  ]);

  const [loading, setLoading] = useState(false);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [passShow, setPassShow] = useState(true);

  useEffect(() => {
    let unparsed_user = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
    if (unparsed_user) {
      const storedData = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
      if (storedData) {
        const value = storedData.access_token;
        if (value) {
          setShouldRedirect(true);
        }
      }
    }
  }, [cookies]);

  const responseGoogle = async (Gresponse) => {
    // Handle the Google sign-in response here
    try {
      Gresponse.operation = "login";
      let response = await AXIOS_REQUEST.post(
        GOOGLE_SIGNIN_SIGNUP_ENDPOINT,
        Gresponse
      );
      if (response) {
        if (response.status === 200 && response.data.status) {
          setLoading(false);
          const data = {
            google_login_id: response?.data?.user?.google_login_id,
            access_token: response.data.access_token,
            expiration: 24 * 60 * 60 * 1000, // expires in 30 minutes
          };
          const expiryTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

          setCookie(
            process.env.REACT_APP_TOKEN_COOKIE_NAME,
            JSON.stringify(data),
            {
              maxAge: expiryTime,
            }
          );

          setPSCookie(
            process.env.REACT_APP_PS_COOKIE_NAME,
            JSON.stringify(jwtDecode(response.data.access_token).ps)
          );
          if (response.data.profile_status) {
            navigate(DASHBOARD);
          } else {
            navigate(BILLING_INFO);
          }
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  //sign In google hook
  const signIn = useGoogleLogin({
    clientId: GOOGLE_OAUTH_CLIENT_ID,
    onSuccess: responseGoogle,
    onFailure: responseGoogle,
    accessType: "offline",
    scope: "email profile",
  });

  const IconClick = () => {
    setPassShow(!passShow);
  };

  if (shouldRedirect) {
    return <Navigate to={DASHBOARD} />;
  }

  const handleLogin = async (values) => {
    try {
      let raw = {
        email: values.email,
        password: values.password,
      };

      setLoading(true);
      let response = await AXIOS_REQUEST.post(LOGIN_ENDPOINT, raw);
      if (response.status === 200) {
        if (!response.data.status) {
          setLoading(false);
          toast.error(response.data.message, { autoClose: 2000 });
        } else {
          toast.success("Successfully Logged In.", {
            closeButton: true,
            // hideProgressBar: true,
          });
          setLoading(false);
          const data = {
            access_token: response.data.access_token,
            expiration: 24 * 60 * 60 * 1000, // expires in 30 minutes
          };
          const expiryTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
          if (values.rememberMe) {
            setCookie(
              process.env.REACT_APP_TOKEN_COOKIE_NAME,
              JSON.stringify(data),
              {
                maxAge: expiryTime,
              }
            );
            setPSCookie(
              process.env.REACT_APP_PS_COOKIE_NAME,
              JSON.stringify(jwtDecode(response.data.access_token).ps),
              { maxAge: expiryTime }
            );

            if (response.data.profile_status) {
              navigate(DASHBOARD);
            } else {
              navigate(BILLING_INFO);
            }
          } else {
            setCookie(
              process.env.REACT_APP_TOKEN_COOKIE_NAME,
              JSON.stringify(data)
            );
            setPSCookie(
              process.env.REACT_APP_PS_COOKIE_NAME,
              JSON.stringify(jwtDecode(response.data.access_token).ps)
            );
            if (response.data.profile_status) {
              navigate(DASHBOARD);
            } else {
              navigate(BILLING_INFO);
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        let x = 2500;
        toast.error(error.response.data.message, { autoClose: 2500 });
        x = x + 500;
      }
      if (error.response.data.email) {
        let x = 2500;
        error.response.data.email.forEach((element) => {
          toast.error(element, { autoClose: x + 500 });
          x = x + 500;
        });
      }
      if (error.response.data.password) {
        let x = 2500;
        error.response.data.password.forEach((element) => {
          toast.error(element, { autoClose: x + 500 });
          x = x + 500;
        });
      }
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <div id="Account_sec">
        <div className="container_sec">
          <div className="login">
            <div className="inner_container_login">
              <div className="login_upper_header">
                <a href="./">
                  <img src={IMAGES.account_img} alt="" />
                </a>
                <div className="with_google_btn">
                  <p>No apps configured. Please contact your administrator.</p>
                  <SignWithGoogle
                    title="Sign in with google."
                    GoogleAuthAPI={signIn}
                  />
                </div>
              </div>
            </div>
            <div className="account_inputs">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  rememberMe: false,
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  handleLogin(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <CustomInput
                      type="text"
                      name="email"
                      id="email"
                      label={"Username or Email Address"}
                      required={true}
                      error={errors && errors.username}
                    />
                    <CustomInput
                      type={passShow ? "password" : "text"}
                      name="password"
                      id="password"
                      label={"Password"}
                      required={true}
                      IconComponent={
                        passShow ? EyeCloseComponent : EyeOpenComponent
                      }
                      IconClick={IconClick}
                      error={errors && errors.password}
                    />

                    <CheckboxInput
                      label="Remember Me"
                      name="rememberMe"
                      id="rememberMe"
                    />
                    <div className="login_container checkbox_sec">
                      <button type="submit">Log in</button>

                      <ul>
                        <li>
                          <Link to={REGISTER}>Register</Link>
                        </li>
                        <li>
                          <Link to={FORGOT_PASS}>Lost your password?</Link>
                        </li>
                      </ul>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="carousel_sendmsg_div">
            <div className="testimonial">
              <TestimonialCarousel />
            </div>
            <div className="msg_btn">
              <button>Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loginpage;
