import { ToastContainer, Slide } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";
import "./css/common.css";
import "react-toastify/dist/ReactToastify.css";

import Account from "./pages/Auth/Account";
import Register from "./pages/Auth/register/firstSignup/firstSignup";
import MyAccount from "./pages/Auth/myAccount/MyAccount";
import BillingHistory from "./pages/Auth/myAccount/BillingHistory";
import Support from "./pages/Support/Support";
import ForgotPass from "./pages/Auth/Forgotpassword/ForgotPass";
import ChangePassword from "./pages/Auth/Forgotpassword/ChangePassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Createaccount from "./pages/Auth/createAccount/CreateAccount";
import BillingInfo from "./pages/Auth/register/firstSignup/BillingInfo";
import ManagePayment from "./pages/Dashboard/ManagePayment";
import ChangeCRM from "./pages/Dashboard/ChangeCrm";
import ChangePaymentProcessor from "./pages/Dashboard/ChangePaymentProcessor";
import PaymentDetail from "./pages/Auth/register/firstSignup/PaymentDetail";
import FinalSignup from "./pages/Auth/register/firstSignup/FinalSignup";
import CreateForm from "./pages/Form/CreateForm";
import SetupProduct from "./pages/Form/SetupProduct";
import SetupPayment from "./pages/Form/SetupPayment";
import FormCode from "./pages/Form/FormCode";
import ConfirmSelection from "./pages/Form/ConfirmSelection";
import FormDesign from "./pages/Form/FormDesign";
import CrmSystem from "./pages/Form/CrmSystem";
import PaymentProcessor from "./pages/Form/PaymentProcessor";
import CongratsPaymentProcessor from "./pages/Form/CongratsPaymentProcessor";
import IFrameForm from "./pages/IFrameForm";
import Nopage from "./pages/Nopage";
import ProtectedRoute from "./ProtectedRoute";
import { ROUTE_URLS } from "./constants/URLS";
import { GOOGLE_OAUTH_CLIENT_ID } from "./constants";
import { TOAST_DEFAULT_TIMING } from "./constants/EXTRA_CONSTANTS";
import PaymentValidate from "./pages/IFrameForm/PaymentValidate";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const appearance = {
  theme: "night ",

  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    spacingUnit: "2px",
    borderRadius: "4px",
    // See all possible variables below
  },
};

const PaymentDetailWrapper = (props) => (
  <Elements
    stripe={stripePromise}
    options={{
      appearance: appearance,
    }}
  >
    <PaymentDetail {...props} />
  </Elements>
);

function App() {
  const {
    LOGIN,
    REGISTER,
    FORGOT_PASS,
    CHANGE_PASSWORD,
    GET_SUPPORT,
    CREATE_ACCOUNT,
    BILLING_INFO,
    PAYMENT_DETAIL,
    FINAL_SIGNUP,
    MY_ACCOUNT,
    BILLING_HISTORY,
    DASHBOARD,
    MANAGE_PAYMENT,
    CHANGE_CRM,
    CHANGE_PAYMENT_PROCESSOR,
    CREATE_FORM,
    SETUP_PRODUCT,
    SETUP_PAYMENT,
    PAYMENT_PROCESSOR,
    CONGRATS,
    CRM,
    FORM_DESIGN,
    CONFIRM_SELECTION,
    FORM_CODE,
    EMBED_FORM1C,
    ALL_OTHER,
    FORM_ID_PARAM,
    PP_CRM_PARAM,
    FORM_CODE_PARAM,
    FORM_NAME_PARAM,
    PAYMENT_VALIDATE,
  } = ROUTE_URLS;

  return (
    <BrowserRouter>
      <CookiesProvider>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
          <Routes>
            <Route path={LOGIN} element={<Account />} />
            <Route path={REGISTER} element={<Register />} />
            <Route path={FORGOT_PASS} element={<ForgotPass />} />
            <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={GET_SUPPORT} element={<Support />} />

            <Route
              path={EMBED_FORM1C + FORM_ID_PARAM}
              element={<IFrameForm />}
            />

            <Route path={EMBED_FORM1C} element={<IFrameForm />} />
            <Route path={PAYMENT_VALIDATE} element={<PaymentValidate />} />

            {/* //ProtectedRoutes => these will not open without login */}
            <Route path={LOGIN} element={<ProtectedRoute />}>
              <Route path={CREATE_ACCOUNT} element={<Createaccount />} />
              <Route path={BILLING_INFO} element={<BillingInfo />} />
              <Route path={PAYMENT_DETAIL} element={<PaymentDetailWrapper />} />
              <Route path={FINAL_SIGNUP} element={<FinalSignup />} />
              <Route path={MY_ACCOUNT} element={<MyAccount />} />
              <Route path={BILLING_HISTORY} element={<BillingHistory />} />
              <Route path={DASHBOARD} element={<Dashboard />} />
              <Route path={MANAGE_PAYMENT} element={<ManagePayment />} />
              <Route path={CHANGE_CRM} element={<ChangeCRM />} />
              <Route
                path={CHANGE_PAYMENT_PROCESSOR}
                element={<ChangePaymentProcessor />}
              />

              <Route path={CREATE_FORM} element={<CreateForm />} />
              <Route
                path={CREATE_FORM + FORM_ID_PARAM}
                element={<CreateForm />}
              />
              <Route path={SETUP_PRODUCT} element={<SetupProduct />} />
              <Route
                path={SETUP_PRODUCT + FORM_ID_PARAM}
                element={<SetupProduct />}
              />
              <Route path={SETUP_PAYMENT} element={<SetupPayment />} />
              <Route
                path={SETUP_PAYMENT + FORM_ID_PARAM}
                element={<SetupPayment />}
              />
              <Route path={PAYMENT_PROCESSOR} element={<PaymentProcessor />} />
              <Route
                path={PAYMENT_PROCESSOR + FORM_ID_PARAM}
                element={<PaymentProcessor />}
              />
              <Route
                path={CONGRATS + PP_CRM_PARAM}
                element={<CongratsPaymentProcessor />}
              />
              <Route path={CRM} element={<CrmSystem />} />
              <Route path={CRM + FORM_ID_PARAM} element={<CrmSystem />} />
              <Route path={FORM_DESIGN} element={<FormDesign />} />
              <Route
                path={FORM_DESIGN + FORM_ID_PARAM}
                element={<FormDesign />}
              />
              <Route path={CONFIRM_SELECTION} element={<ConfirmSelection />} />
              <Route
                path={CONFIRM_SELECTION + FORM_ID_PARAM}
                element={<ConfirmSelection />}
              />
              <Route path={FORM_CODE} element={<FormCode />} />
              <Route
                path={
                  FORM_CODE + FORM_ID_PARAM + FORM_CODE_PARAM + FORM_NAME_PARAM
                }
                element={<FormCode />}
              />
              <Route path={FORM_CODE + FORM_ID_PARAM} element={<FormCode />} />
            </Route>

            <Route path={ALL_OTHER} element={<Nopage />} />
          </Routes>
        </GoogleOAuthProvider>
      </CookiesProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={TOAST_DEFAULT_TIMING}
        hideProgressBar={false}
        newestOnTop={false}
        transition={Slide}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </BrowserRouter>
  );
}
export default App;
