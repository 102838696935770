import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import DotLoader from "../../components/DotLoader";

import "./dashboard.css";
import { IMAGES } from "../../constants/images";
import { useScrollTo } from "../../hooks/useScrollTo";
import AXIOS_REQUEST from "../../helpers/Axios";
import { UPDATE_DEFAULT_PP } from "../../constants/API_ENDPOINTS";
import { getDefaultPP_API } from "../../APIs";
import { CapitalizeAWord } from "../../helpers/helperFunctions";
import PageTitleHeader from "../../components/PageTitleHeader";
import { ROUTE_URLS } from "../../constants/URLS";
import { Link } from "react-router-dom";

export default function PaymentEdit() {
  useScrollTo(0, 0);

  const { DASHBOARD } = ROUTE_URLS;
  const [cookies] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [selectedPP, setSelectedPP] = useState();
  const [loading, setLoading] = useState(false);
  let token;

  //get default payment Processor of a user API Call
  const getDefaultPP = async () => {
    try {
      setLoading(true);
      let data = await getDefaultPP_API(
        "Bearer " +
          cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (data) {
        setLoading(false);
        setSelectedPP(data.default_PP);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // get and set default Payment Processor from API on page load.
  useEffect(() => {
    getDefaultPP();
  }, []);

  const CheckWindowOpenedOrNot = (newTab) => {
    token = setInterval(() => {
      if (newTab.closed) {
        clearInterval(token);
        setLoading(false);
      }
    }, 1000);
  };

  // to change the Payment Processor
  const handleUpdatePP = async (PP) => {
    if (PP !== selectedPP) {
      setLoading(true);
      const data = {
        to: PP,
      };
      let response = await AXIOS_REQUEST.post(UPDATE_DEFAULT_PP, data, {
        headers: {
          Authorization:
            "Bearer " +
            cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
          "Content-Type": "application/json",
        },
      });
      if (response.data.status) {
        setLoading(false);
        setSelectedPP(PP);
        toast.info(response.data.message, {
          closeButton: true,
        });
      } else {
        setLoading(false);
        if (PP == "stripe" || PP == "paypal") {
          if (!response.data.url) {
            toast.error(
              "Server Error: URL not provided for " + CapitalizeAWord(PP)
            );
          } else {
            setLoading(true);
            const newTab = window.open(response.data.url, "_blank");
            CheckWindowOpenedOrNot(newTab);
            // Add an event listener to listen for messages from the new tab
            window.addEventListener("message", function (event) {
              // Check if the message is coming from the new tab
              if (event.source === newTab) {
                event.source.postMessage(
                  "Message from original tab",
                  event.origin
                );
                setLoading(false);

                toast.success(
                  CapitalizeAWord(PP) + " has been connected successfully."
                );
                setSelectedPP(PP);
              }
            });
          }
        }
      }
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="Paymentedit_sec crm_sec">
        <div className="page_container">
          {/* <h1>Edit Payment Processor</h1> */}
          <PageTitleHeader title={"My Payment Forms"} />

          <div className="crm_main">
            <CustomBoxHeader
              title={"Edit Payment Processor"}
              title_img={IMAGES.securityimg}
            />
            <div className="crm_body">
              <h2>
                {selectedPP ? "Change " : "Select "}your Payment Processor
              </h2>
              <div className="content_box">
                <div
                  className="content content1"
                  style={{
                    border:
                      selectedPP == "stripe" ? `2px solid #e46e2e ` : null,
                  }}
                  onClick={() => handleUpdatePP("stripe")}
                >
                  <div className="img_py_sec">
                    {" "}
                    <img src={IMAGES.Stripe_logo_revised_} alt="Edit CRM" />
                  </div>
                </div>
                {/* <div
                  className="content content2"
                  style={{
                    border:
                      selectedPP == "paypal" ? `2px solid #e46e2e ` : null,
                  }}
                  onClick={() => handleUpdatePP("paypal")}
                >
                  <div className="img_py_sec">
                    {" "}
                    <img src={IMAGES.paypal_logo} alt="content2img" />
                  </div>
                </div> */}
              </div>
              <Link to={DASHBOARD} className="back_btn_primary">
                <button>Back to Dashboard</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
