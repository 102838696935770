import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import CustomBoxHeader from "../../../components/CustomBoxHeader";
import CustomPaginationBox from "../../../components/CustomPaginationBox";

import "./createaccount.css";
import { IMAGES } from "../../../constants/images";
import { Form, Formik } from "formik";
import { CreateTrialSchema } from "../../../helpers/validationSchema";
import { ROUTE_URLS } from "../../../constants/URLS";

export default function Createaccount() {
  const navigate = useNavigate();

  const { BILLING_INFO } = ROUTE_URLS;
  const [finalUser, setFinalUser] = useState({});
  const handleInput = (e) => {
    e.preventDefault();
    setFinalUser({ ...finalUser, [e.target.name]: e.target.value });
  };

  const handleTrialContactInfo = async (values) => {
    try {
      navigate(BILLING_INFO);
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <div id="register">
        <div className="page_container">
          <div className="register_sec">
            <h1>
              Welcome to One Page Pay <br />
              Please create your trial account to continue
            </h1>

            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: JSON.parse(localStorage.getItem("opp-email"))?.email,
                phone: "",
                company_name: "",
              }}
              validationSchema={CreateTrialSchema}
              onSubmit={(values) => {
                handleTrialContactInfo(values);
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form>
                  <div className="contact_information">
                    <CustomBoxHeader
                      title={"Contact Information"}
                      title_img={IMAGES.pencil}
                    />
                    <div className="contact_body">
                      <CustomInput
                        validationName="name"
                        type="text"
                        name="first_name"
                        label={"First Name"}
                        required={true}
                        error={errors && errors.first_name}
                      />
                      <CustomInput
                        validationName="name"
                        type="text"
                        name="last_name"
                        label={"Last Name"}
                        required={true}
                        error={errors && errors.last_name}
                      />
                      <CustomInput
                        type="email"
                        name="email"
                        label={"Email"}
                        value={values.email}
                        onChange={handleChange}
                        required={true}
                        error={errors && errors.email}
                        readOnly
                      />

                      <CustomInput
                        type="tel"
                        name="phone"
                        label={"Phone"}
                        required={true}
                        error={errors && errors.phone}
                      />
                      <CustomInput
                        type="text"
                        name="company_name"
                        label={"Company Name"}
                        required={true}
                        error={errors && errors.company_name}
                      />
                    </div>
                  </div>
                  <div className="pagination_outer">
                    <CustomPaginationBox page_number={1} total_pages={4} />

                    <div className="next_buttn">
                      <button type="submit">Next</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
