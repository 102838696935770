import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/fontawesome-free-regular";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";

import "./dashboard.css";
import { IMAGES } from "../../constants/images";
import { useState } from "react";
import { useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import ConfirmationModalContent, {
  customStyles,
} from "../../components/ConfirmationModalContent";
import { ROUTE_URLS } from "../../constants/URLS";

export default function ManagePayment() {
  const { DASHBOARD } = ROUTE_URLS;
  const PAYMENTS_DATA = [
    {
      id: 1,
      form_name: "ALPHA",
      payee_name: "Mark",
      products: [{ product_name: "A", product_price: 10 }],
      date_preauth: "10/10/2023",
      date_processed: "10/02/2023",
      pre_auth_amount: 50,
      payment_status: 0,
      currency: "$",
    },
    {
      id: 2,
      form_name: "ALPHA-A",
      payee_name: "Zuckerberg",
      products: [{ product_name: "A", product_price: 10 }],
      date_preauth: "10/10/2023",
      date_processed: "10/02/2023",
      pre_auth_amount: 30,
      payment_status: 1,
      currency: "£",
    },
    {
      id: 3,
      form_name: "ALPHA-B",
      payee_name: "Alexander",
      products: [{ product_name: "A", product_price: 10 }],
      date_preauth: "10/10/2023",
      date_processed: "10/02/2023",
      pre_auth_amount: 35,
      payment_status: 2,
      currency: "£",
    },
    {
      id: 4,
      form_name: "ALPHA-C",
      payee_name: "Antonio",
      products: [{ product_name: "A", product_price: 10 }],
      date_preauth: "10/10/2023",
      date_processed: "10/02/2023",
      pre_auth_amount: 25,
      payment_status: 1,
      currency: "$",
    },
  ];

  const [paymentsData, setPaymentsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paymentToCancel, setPaymentToCancel] = useState();

  useEffect(() => {
    setPaymentsData(PAYMENTS_DATA);
  }, []);

  const cancelModal = (id) => {
    setPaymentToCancel(id);
    setOpenModal(true);
  };

  const cancelPayment = (paymentToCancel, flag) => {
    if (flag == "No") {
      setPaymentToCancel();
      setOpenModal(false);
    } else if (flag == "Yes") {
      let filteredPayments = paymentsData.filter(
        (item) => item.id !== paymentToCancel
      );
      setPaymentsData(filteredPayments);
      setOpenModal(false);
      setPaymentToCancel();
      toast.info("Payment cancelled Successfully.");
    }
  };

  return (
    <>
      <CustomModal
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentStyles={customStyles}
        content={
          <ConfirmationModalContent
            paymentToCancel={paymentToCancel}
            cancelPayment={cancelPayment}
          />
        }
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <Header />
      <div className="manage_payment_sec">
        <div className="page_container">
          <div className="manage_payment">
            <h1>Manage Payments</h1>
            <div className="payment_section">
              <CustomBoxHeader title={"Payment"} title_img={IMAGES.adminimg} />
              {paymentsData.length > 0 ? (
                <div className="payment_body">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Form <br /> Name
                        </th>
                        <th>
                          Payee
                          <br /> Name
                        </th>
                        <th>
                          Product
                          <br /> Name
                        </th>
                        <th>
                          Pre-auth
                          <br /> amount
                        </th>
                        <th>
                          Date of
                          <br /> Pre-auth
                        </th>
                        <th>
                          Date <br />
                          processed
                        </th>
                        {/* <th>Payment <br/> Status</th> */}
                        {/* <th colSpan={"4"}></th>  */}
                      </tr>
                    </thead>
                    <tbody>
                      {paymentsData.length > 0 &&
                        paymentsData.map((item) => (
                          <tr key={item.id}>
                            <td>{item?.form_name}</td>
                            <td>{item?.payee_name}</td>
                            <td>
                              {item?.products
                                .map((product) => product.product_name)
                                .join(", ")}
                            </td>
                            <td>{item.currency + "" + item.pre_auth_amount}</td>
                            <td>{item.date_preauth}</td>
                            <td>{item.date_processed}</td>
                            {item.payment_status == 0 ? (
                              <td className="Edit_button">
                                <button type="button">Take Payment</button>
                              </td>
                            ) : item.payment_status == 1 ? (
                              <td
                                className="Success_button"
                                id="success_button"
                              >
                                <button type="button">
                                  Payment{" "}
                                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                </button>
                              </td>
                            ) : (
                              <td
                                className="Cancelled_button"
                                id="cancelled_button"
                              >
                                <button type="button">
                                  Payment <br /> Cancelled{" "}
                                  {/* <FontAwesomeIcon icon={faCheckCircle} />{" "} */}
                                </button>
                              </td>
                            )}
                            {/* <td className="Edit_button">
                            <button type="button">Take Payment</button>
                          </td> */}
                            {item.payment_status == 0 ? (
                              <td className="cancel_button">
                                <button
                                  type="button"
                                  disabled
                                  onClick={() => cancelModal(item.id)}
                                  style={{background:"var(--ul-background)", opacity:"0.6"}}
                                >
                                  Cancel
                                </button>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      {/* <tr>
                      <td>Kandi</td>
                      <td>Joe Bloggs</td>
                      <td>Sculpture </td>
                      <td>£50</td>
                      <td>10/10/10</td>
                      <td>11/10/10</td>
                      <td className="Edit_button">
                        <button type="button">Take Payment</button>
                      </td>
                      <td className="cancel_button">
                        <button type="button">Cancel</button>
                      </td>
                    </tr>
                    <tr>
                      <td>Kandi</td>
                      <td>Joe Bloggs</td>
                      <td>Sculpture </td>
                      <td>£50</td>
                      <td>10/10/10</td>
                      <td>11/10/10</td>
                      <td className="Edit_button">
                        <button type="button">Take Payment</button>
                      </td>
                      <td className="cancel_button">
                        <button type="button">Cancel</button>
                      </td>
                    </tr>
                    <tr>
                      <td>Kandi</td>
                      <td>Joe Bloggs</td>
                      <td>Sculpture </td>
                      <td>£50</td>
                      <td>10/10/10</td>
                      <td>11/10/10</td>
                      <td className="Edit_button">
                        <button type="button">Take Payment</button>
                      </td>
                      <td className="cancel_button">
                        <button type="button">Cancel</button>
                      </td>
                    </tr>
                    <tr>
                      <td>Kandi</td>
                      <td>Joe Bloggs</td>
                      <td>Sculpture </td>
                      <td>£50</td>
                      <td>10/10/10</td>
                      <td>11/10/10</td>
                      <td className="Edit_button">
                        <button type="button">Take Payment</button>
                      </td>
                      <td className="cancel_button">
                        <button type="button">Cancel</button>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h4 style={{ textAlign: "center" }}>No Payments are there.</h4>
              )}
            </div>
            <div className="back_buttn">
              <Link to={DASHBOARD}>
                <button>Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
