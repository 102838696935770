// ProtectedRoute.js
import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ROUTE_URLS } from "./constants/URLS";

const ProtectedRoute = () => {
  const { LOGIN } = ROUTE_URLS;

  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);
  let unparsed_user = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
  if (unparsed_user) {
    const storedData = cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME];
    if (storedData) {
      const value = storedData.access_token;
      if (value) {
        return <Outlet />;
      } else {
        navigate(LOGIN);
      }
    } else {
      return <Navigate to={LOGIN} state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to={LOGIN} state={{ from: location }} replace />;
  }
  return;
};

export default ProtectedRoute;
