import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";
import "./style.css";

export const EyeOpenComponent = () => {
  return <FontAwesomeIcon icon={faEye} color="#34656a" />;
};

export const EyeCloseComponent = () => {
  return <FontAwesomeIcon icon={faEyeSlash} color="#34656a" />;
};

export const ActiveComponent = ({title}) => {
  return (
    <FontAwesomeIcon
      title={title}
      icon={faCheckCircle}
      fontSize={25}
      color="var(--success)"
    />
  );
};

export const InActiveComponent = ({title}) => {
  return (
    <FontAwesomeIcon
      title={title}
      icon={faTimesCircle}
      fontSize={25}
      color="var(--error)"
    />
  );
};

export const PercentageComponent = () => {
  return <div className="percentage_component">%</div>;
};
