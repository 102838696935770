import * as yup from "yup";
export const Form1Schema = yup.object().shape({
  form_name: yup.string().required("Form Name is required")
  .max(50, "Form Name must be at most 50 characters long"),
  currency: yup.string().required("Please select a currency"),
});

export const setupProductSchema = yup.object().shape({
  form_type: yup.boolean(),
  add_shipping_field: yup
    .string()
    .required("Please Confirm Shipping Address Fields For Delivery."),

  product_name: yup.string().when("form_type", {
    is: true,

    then: () =>
      yup
        .string()
        .required("Product name is required")
        .max(50, "Product name must be at most 50 characters long"),
    otherwise: () => yup.string(),
  }),
  product_price: yup.string().when("form_type", {
    is: true,

    then: () =>
      yup
        .number("Product Price must be a number.")
        .typeError("Product Price must be a number.")
        .nullable("Product price cannot be null")
        .required("Product Price is required.")
        // .test(
        //   "len",
        //   "Product Price must be less than 7 digits",
        //   (val) => val.toString().length <= 7
        // )
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "Product Price must be a number.", // error message
          (value) =>
            typeof value === "number" && !/[eE+-]/.test(value.toString())
        ),
    otherwise: () => yup.string(),
  }),

  products: yup.array().when("form_type", {
    is: false,
    then: () =>
      yup.array().of(
        yup.object().shape({
          product_name: yup
            .string()
            .required("Product name is required")
            .max(50, "Product name must be at most 50 characters long"),
          product_price: yup
            .number("Product Price must be a number.")
            .typeError("Product Price must be a number.")
            .nullable("Product price cannot be null")
            .required("Product Price is required.")
            // .test(
            //   "len",
            //   "Product Price must be less than 7 digits",
            //   (val) => val.toString().length <= 7
            // )
            .test(
              "noEOrSign", // type of the validator (should be unique)
              "Product Price must be a number.", // error message
              (value) =>
                typeof value === "number" && !/[eE+-]/.test(value.toString())
            ),
          // .string()
          // .required("Product price is required")
          // .max(10, "Product price must be at most 10 characters long"),
        })
      ),
    // .required("Products are required"),
    otherwise: () => yup.array().notRequired(),
  }),

  code_name: yup
    .string()
    .test("code_percentage", "Code Name is required.", function (value) {
      const { code_percentage } = this.parent;
      return (
        !(code_percentage && code_percentage.trim().length > 0) ||
        (value && value.trim().length > 0)
      );
    }),

  code_percentage: yup
    .string()
    .test("code_name", "Code Percentage is required.", function (value) {
      const { code_name } = this.parent;
      return (
        !(code_name && code_name.trim().length > 0) ||
        (value && value.trim().length > 0)
      );
    })
    .test(
      "valid_percentage",
      "Percentage must be between 0 and 100.",
      function (value) {
        if (value && value.trim().length > 0) {
          const parsedValue = parseFloat(value);
          return !isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100;
        }
        return true;
      }
    ),
  sales_tax_perct: yup
    // .string()
    .number()
    .min(0, "Percentage must be between 0 and 100.")
    .max(100, "Percentage must be between 0 and 100."),
  // .required("Percentage is required")
  // .matches(/^[0-9]{2}$/, "Percentage must be a 2-digit number."),
});




export const setupPaymentSchema = yup.object().shape({
  payment_method: yup.string().required("Payment Method is Required."),
  split_no_of_installments: yup.string().when("payment_method", {
    is: (paymentMethod) => paymentMethod === "Split Payments / Payment Plan",
    then: () => yup.string().required("Number of Installments is Required."),
  }),
  installments: yup.string().when("payment_method", {
    is: (paymentMethod) => paymentMethod === "Split Payments / Payment Plan",
    then: () =>
      yup.string().required("Choose whether weekly, Monthly or quarterly."),
  }),
  pre_authorize_amount: yup.string().when("payment_method", {
    is: (paymentMethod) => paymentMethod === "Pre-authorisation",
    then: () => yup.string().required("Pre Authorize Amount is Required."),
  }),
});
// form_name: yup.string().required("Form Name is required"),
// currency: yup.string().required("Please select a currency"),




export const FormDesignSchema = yup.object().shape({
  upload_logo: yup
    .mixed()
    .test("fileType", "Invalid Logo Image format.", function (value) {
      if (this.parent.upload_logo) {
        // Perform the image format validation only if an image is present
        if (value && value.type) {
          return value.type.startsWith("image/");
        }
        return false;
      }
      return true; // No validation needed if no image is present
    })
    .test(
      "fileRequired",
      "One of Logo file or Url is required.",
      function (value) {
        const { logo_url } = this.parent;
        return Boolean(value) || Boolean(logo_url);
      }
    ),
  logo_url: yup
    .mixed()
    .test(
      "fileRequired",
      "One of Logo file or Url is required.",
      function (value) {
        const { upload_logo } = this.parent;
        return Boolean(value) || Boolean(upload_logo);
      }
    ),
});
