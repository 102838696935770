import axios from "axios";
import { API_BASE_URL } from "../../constants";

const AXIOS_REQUEST = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
// Add a request interceptor to attach the JWT token to each request
AXIOS_REQUEST.interceptors.request.use(
  (config) => {
    let token = getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    if (token) {
      const parsed_token = JSON.parse(token);
      config.headers["Authorization"] = `Bearer ${parsed_token.access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
AXIOS_REQUEST.interceptors.response.use(
  (response) => {
    if (response.data.message == "Unauthorized. Token expired.") {
      delete_cookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
      delete_cookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME);
      delete_cookie(process.env.REACT_APP_PS_COOKIE_NAME);
      window.location = "/";
    }
    return response;
  },
  (error) => {
    console.log(error.message);
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Redirect to the login page or perform any other logic
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default AXIOS_REQUEST;
