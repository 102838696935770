import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import CustomBoxHeader from "./CustomBoxHeader";

import "../css/Modal.css";
import { IMAGES } from "../constants/images";
import { ANNUAL_FORM_PRICE, MONTHLY_FORM_PRICE } from "../constants";

const Modal = ({ show, onClose, handleNext, payment_frequency }) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    if (onClose) {
      document.body.addEventListener("keydown", closeOnEscapeKeyDown);
      return function cleanup() {
        document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
      };
    }
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className="modal" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <CustomBoxHeader title={"Order Summary"} title_img={IMAGES.earth} />

          <div className="modal-body">
            <div className="items">
              <div className="item">
                <p>
                  Single Payment{" "}
                  {payment_frequency == "monthly" ? "(Monthly)" : "(Annually)"}
                </p>
                <p>
                  Price: £
                  {payment_frequency == "monthly"
                    ? MONTHLY_FORM_PRICE
                    : ANNUAL_FORM_PRICE}
                </p>
              </div>
              <div className="item">
                <p>Quantity</p>
                <p>1</p>
              </div>
              <div className="item">
                <p>Total Price</p>
                <p>
                  £
                  {payment_frequency == "monthly"
                    ? MONTHLY_FORM_PRICE * 12
                    : MONTHLY_FORM_PRICE * 10}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={() => handleNext()} className="button">
                Close Summary
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
};

export default Modal;
