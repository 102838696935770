import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getCountriesObjects } from "../../helpers/helperFunctions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IFrameFormWithCardDetails from "./iframe";
import { getFrameForm_API } from "../../APIs";
import { toast } from "react-toastify";

const IFrameForm = () => {
  const { form_id } = useParams();

  const [countriesObjects, setCountriesObjects] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [noData, setNoData] = useState(false);
  const [productQuantity, setProductQuantity] = useState(1);
  const [productSelected, setProductSelected] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [message, setMessage] = useState("");


  useEffect(() => {
    if (form_id) {
      getCountriesList();
      getFormDetails();
    } else {
      setNoData(true);
    }
  }, []);

  const getFormDetails = async () => {
    setLoading(true);
    let response = await getFrameForm_API(form_id);
    if (response) {
      setLoading(false);
      if (!response.status) {
        setNoData(true);
        setMessage(response.message);
      } else {
        setFormData(response?.data);
        document.title = response.data.name;
        if (response?.data?.form_type == "single_product") {
          setProductQuantity(1);
          setProductSelected(
            JSON.parse(response?.data?.form_type_products)[0]
              ? JSON.parse(response?.data?.form_type_products)[0]
              : []
          );
        }

        setProductDetails(JSON.parse(response?.data?.form_type_products));
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const getCountriesList = async () => {
    let response = await getCountriesObjects();
    if (response) {
      setCountriesObjects(response);
      setCountriesList(response.map((item) => item.name));
    }
  };
  return (
    <>
      {formData && (
        <Elements stripe={loadStripe(formData.pk)}>
          <IFrameFormWithCardDetails
            countriesObjects={countriesObjects}
            countriesList={countriesList}
            loading={loading}
            setLoading={setLoading}
            formData={formData}
            noData={noData}
            productQuantity={productQuantity}
            setProductQuantity={setProductQuantity}
            productSelected={productSelected}
            setProductSelected={setProductSelected}
            productDetails={productDetails}
            setProductDetails={setProductDetails}
            setMessage={setMessage}
            message={message}
          />
        </Elements>
      )}
    </>
  );
};

export default IFrameForm;
