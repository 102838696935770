import React from "react";
import "../css/customInput.css";
import { useField, useFormikContext } from "formik";

const CustomInput = ({
  wrapperStyle,
  maxLength,
  label,
  readOnly,
  type,
  validationName,
  IconComponent,
  IconClick,
  specialEffect,
  disabled,
  disabledMessage,
  handleLogoUrl,
  ...props
}) => {
  const [field, meta] = useField(props);
  const formik = useFormikContext();

  const handleInputChange = (event) => {
    const { value } = event.target;
    const lastTypedValue = value.charAt(value.length - 1);
    const isValidValue = /^[A-Za-z\s]+$/.test(lastTypedValue);
    const isValidValueForNumber = /^\d+$/.test(lastTypedValue);
    if (specialEffect === "logo_url" && event.type === "blur") {
      handleLogoUrl(event.target.value, formik.setFieldValue);
    } else {
      if (validationName === "name" && lastTypedValue != "" && !isValidValue) {
        const filteredValue = value.replace(/[^A-Za-z\s]/g, "");
        field.onChange({
          ...event,
          target: { ...event.target, value: filteredValue },
        });
      } else if (
        validationName === "number" &&
        lastTypedValue != "" &&
        !isValidValueForNumber
      ) {
        const filteredValue = value.replace(/\D/g, "");
        field.onChange({
          ...event,
          target: { ...event.target, value: filteredValue },
        });
      } else {
        field.onChange(event);
      }
    }
  };

  const handleAmountBlur = (event) => {
    const { value, name } = event.target;
    if (!value.toString().includes(".")) {
      if (!value) {
        formik.setFieldValue(name, value + "0.00");
      } else {
        formik.setFieldValue(name, value + ".00");
      }
    }
  };

  return (
    <div
      className={wrapperStyle ? "input_container" : "input_container"}
      style={disabled ? { cursor: "not-allowed" } : null}
    >
      {props.type !== "checkbox" ? (
        <label htmlFor={props.id || props.name}>{label}</label>
      ) : null}
      <div
        className="input_div input_container"
        style={{ position: "relative" }}
      >
        <input
          onWheel={validationName == "number" ? (e) => e.target.blur() : null}
          maxLength={maxLength}
          {...field}
          {...props}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={
            specialEffect == "decimalpad"
              ? handleAmountBlur
              : specialEffect == "logo_url"
              ? handleLogoUrl
              : null
          }
          className="input"
          title={disabled ? disabledMessage : null}
          style={disabled ? { cursor: "not-allowed" } : null}
          onChange={handleInputChange}
        />
        {IconComponent ? (
          <div
            onClick={!disabled ? IconClick : null}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <IconComponent />
          </div>
        ) : null}
      </div>
      {props.type === "checkbox" ? (
        <label style={{ marginLeft: "5px" }} htmlFor={props.id || props.name}>
          {label}
        </label>
      ) : null}
      {meta.touched && meta.error ? (
        <div className="invalid-error invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomInput;
