import "../css/DotLoader.css";
const DotLoader = ({ loading }) => {
  return loading ? (
    <div className="loader-container1">
      <div className="loader-dot1"></div>
      <div className="loader-dot1"></div>
      <div className="loader-dot1"></div>
    </div>
  ) : null;
};

export default DotLoader;
