import * as yup from "yup";
import { EMAIL_REGEX } from "../../constants/EXTRA_CONSTANTS";

// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const getSupportSchema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email")
    .required("Email is required"),
  name: yup
    .string()
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name must be no more than 50 characters")
    .required("Name is required"),
  message: yup.string().required("Message is required"),
});
