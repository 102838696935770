import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faMinus, faPlus } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { FieldArray, Form, Formik } from "formik";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomInput from "../../components/CustomInput";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import CustomPaginationBox from "../../components/CustomPaginationBox";
import FormikCustomRadioWithLabel from "../../components/FormikCustomRadioWithLabel";
import DotLoader from "../../components/DotLoader";
import { PercentageComponent } from "../../components/IconComponents";

import { useScrollTo } from "../../hooks/useScrollTo";
import AXIOS_REQUEST from "../../helpers/Axios";
import { setupProductSchema } from "../../helpers/validationSchema/FormFlowValidation";

import { getFormDetailsAPI } from "../../APIs";
import { IMAGES } from "../../constants/images";
import { ROUTE_URLS } from "../../constants/URLS";
import { CREATE_FORM_DETAILS_ENDPOINT } from "../../constants/API_ENDPOINTS";
import "./SetupProduct.css";

const SetupProduct = () => {
  useScrollTo(0, 0);

  const { SETUP_PAYMENT } = ROUTE_URLS;
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [setupProductData, setSetupProductData] = useState(1);
  const [loading, setLoading] = useState(false);

  //get form details step 1
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        1,
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        setSetupProductData(response.data[0]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details(if there) on component load
  useEffect(() => {
    if (form_id) {
      getFormDetails();
    } else {
      let cookiesData = cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME];
      if (cookiesData.is_shipping_address == true) {
        cookiesData.is_shipping_address = 1;
      } else if (cookiesData.is_shipping_address == false) {
        cookiesData.is_shipping_address = 0;
      }
      setSetupProductData(cookiesData);
    }
  }, []);

  const handleSetupProduct = async (values, setValues) => {
    try {
      var ValuesCopy = { ...values };

      if (values.form_type == "true") {
        delete values.products;
        values.products = [
          {
            product_name: values.product_name,
            product_price: values.product_price,
          },
        ];

        delete values.product_name;
        delete values.product_price;
      } else {
        delete values.product_name;
        delete values.product_price;
      }
      let payload = {
        form_step: 1,
        form_type:
          values.form_type == "true" ? "single_product" : "multiple_product",
        discount_code: values.code_name,
        discount_value: values.code_percentage,
        is_shipping_address: values.add_shipping_field,
        sale_tax: values.sales_tax_perct,
        form_type_products: values.products,
        form_id: form_id ? form_id : cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
        // user_id: cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
      };
      setLoading(true);
      let response = await AXIOS_REQUEST.post(
        CREATE_FORM_DETAILS_ENDPOINT,
        payload,
        {
          headers: {
            Authorization:
              "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
          },
        }
      );
      if (response.data.status) {
        setLoading(false);
        if (form_id) {
          navigate(`${SETUP_PAYMENT}/${form_id}`);
        } else {
          let newObj = {
            ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
            ...response.data.details,
          };

          setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
          navigate(SETUP_PAYMENT);
        }
      } else {
        setLoading(false);
        if (
          ValuesCopy.products.length > 0 &&
          (ValuesCopy.form_type == "false" ||
            ValuesCopy.form_type == "multiple_proudct")
        ) {
        } else {
          ValuesCopy.products = [{ product_name: "", product_price: "" }];
        }
        setValues(ValuesCopy);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      if (
        ValuesCopy.products.length > 0 &&
        (ValuesCopy.form_type == "false" ||
          ValuesCopy.form_type == "multiple_proudct")
      ) {
      } else {
        ValuesCopy.products = [{ product_name: "", product_price: "" }];
      }
      setValues(ValuesCopy);
      toast.error(error.message);
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="setup_product_main">
        <div className="page_container">
          <h1>Setup Product</h1>

          <Formik
            initialValues={
              form_id
                ? {
                    products:
                      (setupProductData?.form_type == "multiple_product" ||
                        setupProductData?.form_type == "false") &&
                      setupProductData?.form_type_products
                        ? JSON.parse(setupProductData.form_type_products)
                        : [{ product_name: "", product_price: "" }],
                    code_name: setupProductData?.discount_code
                      ? setupProductData.discount_code
                      : "",
                    code_percentage: setupProductData?.discount_value
                      ? setupProductData.discount_value
                      : "",
                    product_name:
                      setupProductData?.form_type == "single_product" ||
                      setupProductData?.form_type == "true"
                        ? setupProductData?.form_type_products
                          ? JSON.parse(setupProductData?.form_type_products)[0]
                              .product_name
                          : ""
                        : "",
                    product_price:
                      setupProductData?.form_type == "single_product" ||
                      setupProductData?.form_type == "true"
                        ? setupProductData?.form_type_products
                          ? JSON.parse(setupProductData?.form_type_products)[0]
                              .product_price
                          : ""
                        : "",
                    sales_tax_perct: setupProductData?.sale_tax
                      ? setupProductData?.sale_tax
                      : "",
                    add_shipping_field: setupProductData?.is_shipping_address,
                    form_type: setupProductData?.form_type
                      ? setupProductData?.form_type == "single_product"
                        ? "true"
                        : "false"
                      : "true",
                    // agreeToTerms: false,
                  }
                : {
                    products:
                      (setupProductData?.form_type == "multiple_product" ||
                        setupProductData?.form_type == "false") &&
                      setupProductData?.form_type_products
                        ? setupProductData.form_type_products
                        : [{ product_name: "", product_price: "" }],

                    code_name: setupProductData?.discount_code
                      ? setupProductData.discount_code
                      : "",
                    code_percentage: setupProductData?.discount_value
                      ? setupProductData.discount_value
                      : "",
                    product_name:
                      setupProductData?.form_type == "single_product" ||
                      setupProductData?.form_type == "true"
                        ? setupProductData?.form_type_products
                          ? setupProductData?.form_type_products[0].product_name
                          : ""
                        : "",
                    product_price:
                      setupProductData?.form_type == "single_product" ||
                      setupProductData?.form_type == "true"
                        ? setupProductData?.form_type_products
                          ? setupProductData?.form_type_products[0]
                              .product_price
                          : ""
                        : "",
                    sales_tax_perct: setupProductData?.sale_tax
                      ? setupProductData?.sale_tax
                      : "",
                    add_shipping_field: setupProductData?.is_shipping_address,
                    form_type: setupProductData?.form_type
                      ? setupProductData?.form_type == "single_product"
                        ? "true"
                        : "false"
                      : "true",
                  }
            }
            enableReinitialize={true}
            validationSchema={setupProductSchema}
            onSubmit={(values, { setValues }) => {
              handleSetupProduct(values, setValues);
            }}
          >
            {({
              formik,
              errors,
              touched,
              handleChange,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div className="form">
                  {/* <div className="form_head"> */}
                  {/* </div> */}
                  <div className="form_bodies">
                    <div className="forms_left">
                      <div className="form_type">
                        <CustomBoxHeader
                          title={"Form Type"}
                          title_img={IMAGES.pencil}
                        />
                        <div className="form_type_body">
                          <div className="radio_main">
                            <FormikCustomRadioWithLabel
                              label={"Single Product"}
                              value={true}
                              id={"single_product"}
                              selected={values.form_type == "true"}
                              name={"form_type"}
                              widthBottomPad={true}
                            />

                            <FormikCustomRadioWithLabel
                              label={"Multiple Products with Dropdown List"}
                              id={"multiple_product"}
                              value={false}
                              name={"form_type"}
                              selected={values.form_type == "false"}
                              widthBottomPad={false}
                            />
                          </div>

                          {values.form_type == "true" ||
                          values.form_type == "single_product" ? (
                            <div className="product_detail">
                              <div className="product_name">
                                <div className="product_name_div">
                                  <CustomInput
                                    name={"product_name"}
                                    label={"Product Name"}
                                    type={"text"}
                                    required={true}
                                    // value={values.products[0].product_name}
                                    error={errors && errors.product_name}
                                  />
                                </div>
                                <div className={"product_price_div otherclas"}>
                                  <CustomInput
                                    name={"product_price"}
                                    label={"Price"}
                                    type={"number"}
                                    validationName={"number"}
                                    specialEffect={"decimalpad"}
                                    // value={values.products[0].product_price}
                                    required={true}
                                    error={errors && errors.product_price}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="product_detail">
                              <FieldArray name="products">
                                {({ push, remove }) => (
                                  <>
                                    {values?.products?.map((product, index) => (
                                      <>
                                        <div
                                          key={index}
                                          className="product_name"
                                        >
                                          <div
                                            key={index}
                                            className="product_name_div"
                                          >
                                            <CustomInput
                                              name={`products.${index}.product_name`}
                                              label={"Product Name"}
                                              type={"text"}
                                              required={true}
                                            />
                                          </div>
                                          <div className={"product_price_div"}>
                                            <CustomInput
                                              name={`products.${index}.product_price`}
                                              label={"Price"}
                                              type={"number"}
                                              required={true}
                                              specialEffect={"decimalpad"}
                                              validationName={"number"}
                                            />
                                            {index !== 0 ? (
                                              <div className="inc_div">
                                                <div className="inner dec">
                                                  <button
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      color="#FFF"
                                                      icon={faMinus}
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="inc_div">
                                                <div className="inner">
                                                  <button
                                                    onClick={() => {
                                                      push({
                                                        product_name: "",
                                                        product_price: "",
                                                      });
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      color="#FFF"
                                                      icon={faPlus}
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="forms_right">
                      <div className="discount_codes">
                        <CustomBoxHeader
                          title={"Discount Codes"}
                          title_img={IMAGES.earth}
                        />
                        <div className="discount_codes_body">
                          <div className="disc_container">
                            <CustomInput
                              type={"text"}
                              name="code_name"
                              label={"Code Name (Leave blank for none)"}
                              required={false}
                              error={errors && errors.code_name}
                            />
                            <div className="percentage_inputBefore">
                              <CustomInput
                                type={"number"}
                                wrapperStyle={{
                                  paddingLeft: "12px",
                                  width: "30%",
                                }}
                                maxLength={3}
                                name="code_percentage"
                                required={false}
                                validationName={"number"}
                                error={errors && errors.code_percentage}
                                label={"Percentage"}
                                IconComponent={PercentageComponent}
                                IconClick={() => {
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="shipping_address">
                        <CustomBoxHeader
                          title={"Shipping Address"}
                          title_img={IMAGES.earth}
                        />
                        <div className="shipping_address_body">
                          <p>Add shipping Address Fields for Delivery?</p>
                          <div role="group" className="radio_main">
                            <FormikCustomRadioWithLabel
                              label={"Yes"}
                              value={1}
                              id={"shipping_yes"}
                              name={"add_shipping_field"}
                              selected={values.add_shipping_field == 1 ? 1 : 0}
                              widthBottomPad={true}
                            />
                            <FormikCustomRadioWithLabel
                              label={"No"}
                              id={"shipping_no"}
                              selected={values.add_shipping_field == 0 ? 1 : 0}
                              value={0}
                              name={"add_shipping_field"}
                              widthBottomPad={false}
                            />

                            {errors &&
                            touched.add_shipping_field &&
                            errors.add_shipping_field ? (
                              <div className="invalid-error invalid-feedback">
                                {errors.add_shipping_field}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="sales_tax">
                        <CustomBoxHeader
                          title={"Sales Tax"}
                          title_img={IMAGES.earth}
                        />
                        <div className="sales_tax_body">
                          <div className="percentage_inputBefore ">
                            <CustomInput
                              name={"sales_tax_perct"}
                              label={"Percentage (leave blank for none)"}
                              type={"number"}
                              required={false}
                              validationName={"number"}
                              IconComponent={PercentageComponent}
                              error={errors && errors.sales_tax_perct}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pagination_outer">
                    <CustomPaginationBox page_number={1} total_pages={5} />
                    <div className="next_buttn">
                      <button type="submit">Next</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SetupProduct;
