import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomPaginationBox from "../../components/CustomPaginationBox";
import DotLoader from "../../components/DotLoader";

import "./paymentProcessor.css";
import { IMAGES } from "../../constants/images";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getDefaultCRM_API, getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import {
  CREATE_FORM_DETAILS_ENDPOINT,
  HUBSPOT_ACCOUNT_CHECK_ENDPOINT,
  MAIL_CHIMP_ACCOUNT_CHECK_ENDPOINT,
  UPDATE_DEFAULT_CRM,
} from "../../constants/API_ENDPOINTS";
import { CapitalizeAWord } from "../../helpers/helperFunctions";

export default function CrmSystem() {
  const { PAYMENT_PROCESSOR, FORM_DESIGN } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [selectedCRM, setSelectedCRM] = useState();
  const [loading, setLoading] = useState(false);
  let token;

  const getDefaultCRM = async () => {
    try {
      setLoading(true);
      let data = await getDefaultCRM_API(
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (data) {
        setLoading(false);
        setSelectedCRM(data.default_crm);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details step 4
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        4,
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        if (response.data[0]?.crm_type) {
          setLoading(false);
          setSelectedCRM(response.data[0]?.crm_type);
        } else {
          setLoading(false);
          getDefaultCRM();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details(if there) on component load
  useEffect(() => {
    if (form_id) {
      getFormDetails();
    } else {
      getDefaultCRM();
    }
  }, []);

  // to change the CRM
  const handleUpdateCRM = async (crm) => {
    // if (crm !== selectedCRM) {
    //   setLoading(true);
    //   const data = {
    //     to: crm,
    //   };
    //   let response = await AXIOS_REQUEST.post(UPDATE_DEFAULT_CRM, data, {
    //     headers: {
    //       "Content-Type": "application/json",

    //       Authorization: "Bearer " + cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
    //     },
    //   });
    //   if (response.data.status) {
    //     setLoading(false);
    //     toast.info(response.data.message, {
    //       closeButton: true,
    //     });
    //     setSelectedCRM(crm);
    //   } else {
    //     setLoading(false);
    //     onCheckCRMAccount(1, crm);
    //   }
    // }

    if (crm !== selectedCRM) {
      onCheckCRMAccount(1, crm);
    }
  };

  const CheckWindowOpenedOrNot = (newTab) => {
    token = setInterval(() => {
      if (newTab.closed) {
        clearInterval(token);
        setLoading(false);
      }
    }, 1000);
  };

  //CRM Check
  const onCheckCRMAccount = async (check, selectedCRM) => {
    if (selectedCRM == "mailchimp") {
      // Set the authorization header dynamically based on the cookie value
      // AXIOS_REQUEST.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token}`;
      try {
        setLoading(true);
        let response = await AXIOS_REQUEST.get(
          MAIL_CHIMP_ACCOUNT_CHECK_ENDPOINT
        );
        if (response) {
          setLoading(false);
          if (response.data.status == false) {
            setLoading(true);
            const newTab = window.open(response.data.url, "_blank");
            CheckWindowOpenedOrNot(newTab);

            window.addEventListener("message", function (event) {
              // Check if the message is coming from the new tab
              if (event.source === newTab) {
                let responseFromNewTab = event.data;
                if (responseFromNewTab.status) {
                  toast.success(
                    CapitalizeAWord(selectedCRM) +
                      " has been connected successfully."
                  );
                  setSelectedCRM(selectedCRM);
                }
              }
            });
          } else {
            let payload = {
              form_step: 4,
              crm_type: selectedCRM,
              form_id: form_id
                ? form_id
                : Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
              user_id: Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
            };
            let response = await AXIOS_REQUEST.post(
              CREATE_FORM_DETAILS_ENDPOINT,
              payload,
              {
                headers: {
                  Authorization:
                    "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
                },
              }
            );
            if (response.data.status) {
              setSelectedCRM(response.data.details.crm_type);
              if (check != 1) {
                if (form_id) {
                  navigate(`${FORM_DESIGN}/${form_id}`);
                } else {
                  let newObj = {
                    ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
                    ...response.data.details,
                  };
                  setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
                  navigate(FORM_DESIGN);
                }
              }
            } else {
              toast.error("Something went wrong.");
            }
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong...: " + error.message);
      }
    } else if (selectedCRM == "hubspot") {
      try {
        setLoading(true);
        let response = await AXIOS_REQUEST.get(HUBSPOT_ACCOUNT_CHECK_ENDPOINT);
        if (response) {
          setLoading(false);
          if (response.data.status == false) {
            setLoading(true);
            const newTab = window.open(response.data.url, "_blank");
            CheckWindowOpenedOrNot(newTab);
            window.addEventListener("message", function (event) {
              // Check if the message is coming from the new tab
              if (event.source === newTab) {
                let responseFromNewTab = event.data;
                if (responseFromNewTab.status) {
                  toast.success(
                    CapitalizeAWord(selectedCRM) +
                      " has been connected successfully."
                  );
                  setSelectedCRM(selectedCRM);
                }
              }
            });
          } else {
            let payload = {
              form_step: 4,
              crm_type: selectedCRM,
              form_id: form_id
                ? form_id
                : Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
              user_id: Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
            };
            let response = await AXIOS_REQUEST.post(
              CREATE_FORM_DETAILS_ENDPOINT,
              payload,
              {
                headers: {
                  Authorization:
                    "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
                },
              }
            );
            if (response.data.status) {
              setSelectedCRM(response.data.details.crm_type);

              if (check != 1) {
                if (form_id) {
                  navigate(`${FORM_DESIGN}/${form_id}`);
                } else {
                  let newObj = {
                    ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
                    ...response.data.details,
                  };
                  setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
                  navigate(FORM_DESIGN);
                }
              }
            } else {
              toast.error("Something went wrong.");
            }
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong...: " + error.message);
      }
    } else if (selectedCRM == "no_crm") {
      let payload = {
        form_step: 4,
        crm_type: selectedCRM,
        form_id: form_id ? form_id : Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
        user_id: Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
      };
      let response = await AXIOS_REQUEST.post(
        CREATE_FORM_DETAILS_ENDPOINT,
        payload,
        {
          headers: {
            Authorization:
              "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
          },
        }
      );
      if (response.data.status) {
        setSelectedCRM(response.data.details.crm_type);

        if (check != 1) {
          if (form_id) {
            navigate(`${FORM_DESIGN}/${form_id}`);
          } else {
            let newObj = {
              ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
              ...response.data.details,
            };
            setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
            navigate(FORM_DESIGN);
          }
        }
      } else {
        toast.error("Something went wrong.");
      }
    } else {
      toast.info("Please Select MailChimp for now.");
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="payment_process" className="payment_process crmsystem">
        <div className="payment_processor Paymentedit_sec crm_sec">
          <div className="page_container">
            <h1>Connect your CRM System</h1>
            <div className="crm_main">
              <div className="crm_body">
                <div className="content_box">
                  <div
                    className="content content1"
                    onClick={() => handleUpdateCRM("hubspot")}
                    style={{
                      border:
                        selectedCRM == "hubspot" ? `2px solid #e46e2e ` : null,
                    }}
                  >
                    <img src={IMAGES.HubSpot_Primary} alt="Edit CRM" />
                  </div>
                  <div
                    className="content content2"
                    style={{
                      border:
                        selectedCRM == "mailchimp"
                          ? `2px solid #e46e2e `
                          : null,
                    }}
                    onClick={() => handleUpdateCRM("mailchimp")}
                  >
                    <img src={IMAGES.Mailchimp_logo} alt="Edit CRM" />
                  </div>
                  <div
                    className="content content_no_crm"
                    style={{
                      border:
                        selectedCRM == "no_crm" ? `2px solid #e46e2e ` : null,
                    }}
                    onClick={() => handleUpdateCRM("no_crm")}
                  >
                    {/* <img src={IMAGES.zoho_logo} alt="Edit CRM" /> */}
                    <p>I don't have CRM, just give me Payment Form.</p>
                  </div>
                  {/* <div className="content content3">
                    <img src={IMAGES.zoho_logo} alt="Edit CRM" />
                  </div>
                  <div className="content content4">
                    <img src={IMAGES.Freshsales_Logo1} alt="Edit CRM" />
                    <img src={IMAGES.Freshsales_Logotext} alt="Edit CRM" />
                  </div>
                  <div className="content content5">
                    <img src={IMAGES.sharpLogo} alt="Edit CRM" />
                    <img src={IMAGES.sharpspringText} alt="Edit CRM" />
                  </div> */}
                </div>
                {/* <div className="clic_section">
                  <Link>
                    <span>Don’t have a CRM?</span> Click here
                  </Link>
                </div> */}
              </div>
            </div>

            <div className="pagination_outer">
              <CustomPaginationBox page_number={4} total_pages={5} />
              <div className="next_buttn">
                <Link
                  to={
                    form_id
                      ? `${PAYMENT_PROCESSOR}/${form_id}`
                      : PAYMENT_PROCESSOR
                  }
                  className="back_btn"
                >
                  <button>Back</button>
                </Link>
                {/* <Link to={FORM_DESIGN}> */}
                <button onClick={() => onCheckCRMAccount(0, selectedCRM)}>
                  Next
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
