import * as yup from "yup";
import { NEXT_N_YEARS, months } from "../../constants/staticData";
import {
  FIRST_LAST_NAME_REGEX,
  FULL_NAME_REGEX,
} from "../../constants/EXTRA_CONSTANTS";

export const LoginSchema = yup.object().shape({
  // email: yup.string().email().required(),
  email: yup
    .string()
    // .email("Invalid email")
    .required("Email or username is required"),
  password: yup
    .string()
    .min(12, "Invalid Password")
    .required("Password is required"),
  //   confirmPassword: yup
  //     .string()
  //     .oneOf([yup.ref("password"), null], "Passwords must match")
  //     .required("Confirm password is required"),
});

export const RegisterSchema = yup.object().shape({
  email: yup.string().email().required(),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(12, "Password must be at least 12 characters")
    .test("no-spaces", "Password must not contain spaces", (value) => {
      return value === undefined || value === null || !/\s/.test(value);
    })
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const CreateTrialSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    .required("Phone number is required"),
  first_name: yup
    .string()
    .min(3, "First name must be at least 3 characters")
    .max(50, "First name must be no more than 50 characters")
    .required("First name is required"),
  last_name: yup
    .string()
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name must be no more than 50 characters")
    .required("Last name is required"),
  company_name: yup
    .string()
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name must be no more than 50 characters")
    .required("Company name is required"),
  // password: yup
  //   .string()
  //   .min(12, "Password must be at least 12 characters")
  //   .required("Password is required"),
  // confirmPassword: yup
  //   .string()
  //   .oneOf([yup.ref("password"), null], "Passwords must match")
  //   .required("Confirm password is required"),
});

export const BillingInfoSchema = yup.object().shape({
  email: yup.string().email("Invalid email.").required("Email is required."),
  phone: yup
    .string()
    .min(7, "Phone Number must be atleast 7 digits.")
    // .matches( /^[+]\d{1,3}(?: ?-? ?)\d{3,14}$/, "Please enter valid Phone Number.")
    .matches(/^[+0-9 ]{0,15}$/, "Please enter valid Phone Number.")
    .required("Phone Number is required."),
  firstname: yup
    .string()
    .matches(FIRST_LAST_NAME_REGEX, "Invalid First Name.")
    .min(3, "First Name must be at least 3 characters.")
    .max(50, "First Name must be no more than 50 characters.")
    .required("First Name is required."),
  lastname: yup
    .string()
    .matches(FIRST_LAST_NAME_REGEX, "Invalid Last Name.")
    .min(3, "Last Name must be at least 3 characters.")
    .max(50, "Last Name must be no more than 50 characters.")
    .required("Last Name is required."),
  company: yup
    .string()
    .min(3, "Company Name must be at least 3 characters.")
    .max(50, "Company Name must be no more than 50 characters.")
    .required("Company Name is required."),
  streetaddress: yup.string().required("Street Address is required."),
  city: yup
    .string()
    .min(3, "City must be at least 3 characters.")
    .max(50, "City must be no more than 50 characters.")
    .test({
      name: "alphabets-and-spaces-city",
      message: "City should only contain alphabets and spaces.",
      test: (value) => {
        if (value) {
          // Custom validation to check if the country contains only alphabets and spaces
          return typeof value === "string" && /^[A-Za-z\s]+$/.test(value);
        } else {
          return true;
        }
      },
    }),
  // .test(
  //   "no-only-digits-or-empty",
  //   "City cannot contain only numbers",
  //   (value) => {
  //     // Custom validation to check if the city contains at least one non-digit character if the field is not empty
  //     return (
  //       typeof value === "undefined" ||
  //       (typeof value === "string" &&
  //         value.trim().length > 0 &&
  //         /[^\d]/.test(value))
  //     );
  //   }
  // ),
  // .required("City is required")
  state: yup
    .string()
    .min(3, "State must be at least 3 characters.")
    .max(50, "State must be no more than 50 characters.")
    .test({
      name: "alphabets-and-spaces-state",
      message: "State should only contain alphabets and spaces.",
      test: (value) => {
        if (value) {
          // Custom validation to check if the country contains only alphabets and spaces
          return typeof value === "string" && /^[A-Za-z\s]+$/.test(value);
        } else {
          return true;
        }
      },
    }),
  // .test(
  //   "no-only-digits-or-empty",
  //   "State cannot contain only numbers",
  //   (value) => {
  //     // Custom validation to check if the state contains at least one non-digit character if the field is not empty
  //     return (
  //       typeof value === "undefined" ||
  //       (typeof value === "string" &&
  //         value.trim().length > 0 &&
  //         /[^\d]/.test(value))
  //     );
  //   }
  // ),
  // .required("State is required")
  postalcode: yup
    .string()
    // .matches(/^[0-9]{6}$/, "Zip/ Postal Code must be a 6-digit number") // .number("","Zip/ Postal Code must be in number only")
    .required("Zip/ Postal Code is required."),

  country: yup
    .string()
    .test({
      name: "alphabets-and-spaces-country",
      message: "Country should only contain alphabets and spaces.",
      test: (value) => {
        // Custom validation to check if the country contains only alphabets and spaces
        return typeof value === "string" && /^[A-Za-z\s]+$/.test(value);
      },
    })
    .required("Country is required"),
});

export const PaymentDetailSchema = yup.object().shape({
  payment_detail_email: yup
    .string()
    .email("Invalid email.")
    .required("Email is required."),

  payment_detail_firstname: yup
    .string()
    .min(3, "First Name must be at least 3 characters.")
    .max(50, "First Name must be no more than 50 characters.")
    .matches(FIRST_LAST_NAME_REGEX, "Invalid First Name.")
    .required("First Name is required."),

  payment_detail_lastname: yup
    .string()
    .min(3, "Last Name must be at least 3 characters.")
    .max(50, "Last Name must be no more than 50 characters.")
    .matches(FIRST_LAST_NAME_REGEX, "Invalid Last Name.")
    .required("Last Name is required."),

  // cardnumber: yup
  //   .string()
  //   .min(12, "Card Number must be of atleast 12 digits.")
  //   .max(19, "Card Number should be atmost 19 digits.")
  //   .required("Card Number is required."),

  cardholdername: yup
    .string()
    .matches(FULL_NAME_REGEX, "Invalid Card Holder Name.")
    .min(3, "Card Holder Name must be at least 3 characters.")
    .max(50, "Card Holder Name must be no more than 50 characters.")
    .required("Card Holder Name is required."),

  // securitycode: yup
  //   .string()
  //   // .matches(/^[0-9]{3}$/, "Security Code must be exactly 3 digits")
  //   .min(3, "Security Code must be of atleast 3 digits.")
  //   .max(4, "Security Code should be atmost 4 digits.")
  // //   .required("Security Code is required."),

  // expiry_year: yup
  //   .string()
  //   .oneOf(
  //     NEXT_N_YEARS(15).map((ele) => ele.toString()),
  //     "Expiration Year is required."
  //   )
  //   .required("Expiration Month is required.")

  //   .test({
  //     name: "expiry_year",
  //     message: "",
  //     test: function (value) {
  //       const currentYear = new Date().getFullYear();
  //       const currentMonth = new Date().getMonth() + 1; // January is month 0
  //       const expirymonth = this.parent.expirymonth;
  //       if (expirymonth && value == currentYear && expirymonth < currentMonth) {
  //         return false;
  //       }
  //       return true;
  //     },
  //   }),

  // expirymonth: yup
  //   .string()
  //   .oneOf(months, "Expiration Month is required.")
  //   .required("Expiration Month is required.")
  //   .test({
  //     name: "expiry_month",
  //     message: "Invalid month.",
  //     test: function (value) {
  //       const currentYear = new Date().getFullYear();
  //       const currentMonth = new Date().getMonth() + 1; // January is month 0
  //       const expiryYear = this.parent.expiry_year;
  //       if (expiryYear && expiryYear == currentYear && value < currentMonth) {
  //         return false;
  //       }
  //       return true;
  //     },
  //   }),

  // expirymonth: yup
  //   .string()
  //   .oneOf(months, "Expiration Month is required")
  //   .when(
  //     ["expiry_year", "expirymonth"],
  //     (expiry_year, expirymonth, schema) => {
  //       const currentYear = new Date().getFullYear();
  //       const currentMonth = new Date().getMonth() + 1; // January is month 0
  //       if (
  //         expiry_year &&
  //         expiry_year[0] == currentYear &&
  //         expirymonth < currentMonth
  //       ) {
  //         return schema.test({
  //           name: "expirymonth",
  //           message: "Selected month must not be in the past",
  //           test: false,
  //         });
  //       }
  //       return schema;
  //     }
  //   ),
  // expiry_year: yup
  //   .string()
  //   .oneOf(
  //     NEXT_N_YEARS(15).map((ele) => ele.toString()),
  //     "Expiration Year is required"
  //   )
  //   .when(
  //     ["expiry_year", "expirymonth"],
  //     (expiry_year, expirymonth, schema) => {
  //       const currentYear = new Date().getFullYear();
  //       const currentMonth = new Date().getMonth() + 1; // January is month 0
  //       if (
  //         expirymonth &&
  //         expiry_year == currentYear &&
  //         expirymonth < currentMonth
  //       ) {
  //         return schema.test({
  //           name: "expiry_year",
  //           message: "Selected month must not be in the past",
  //           test: false,
  //         });
  //       }
  //       return schema;
  //     }
  //   ),

  // expirymonth: yup
  //   .string()
  //   .oneOf(months, "Expiration Month is required")
  //   .when("expiry_year", (expiry_year, schema) => {
  //     const currentYear = new Date().getFullYear();
  //     const currentMonth = new Date().getMonth() + 1; // January is month 0
  //     return schema.test({
  //       test: function (value) {
  //         // if (node.value.isBefore(expiry_year)) {
  //         if (expiry_year[0] == currentYear && value < currentMonth) {
  //           return false;
  //         }
  //         // }
  //         return true;
  //       },
  //       message: "Selected month must not be in the past",
  //     });
  //   }),
  // expiry_year: yup
  //   .string()
  //   .oneOf(
  //     NEXT_N_YEARS(15).map((ele) => ele.toString()),
  //     "Expiration Year is required"
  //   )
  //   .when("expirymonth", (expirymonth, schema) => {
  //     const currentYear = new Date().getFullYear();
  //     const currentMonth = new Date().getMonth() + 1; // January is month 0
  //     return schema.test({
  //       test: function (value) {
  //         if (value == currentYear && expirymonth < currentMonth) {
  //           return false;
  //         }
  //         return true;
  //       },
  //       message: "Selected month must not be in the past",
  //     });
  //   }),
});

export const ForgotPassSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Username or Email")
    .required("Username or Email is required"),
});

export const ForgotChangePassSchema = yup.object().shape({
  password: yup
    .string()
    .min(12, "Password must be at least 12 characters")
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const ChangePassSchema = yup.object().shape({
  password: yup
    .string()
    .min(12, "Password must be at least 12 characters")
    .test("no-spaces", "Password must not contain spaces", (value) => {
      return value === undefined || value === null || !/\s/.test(value);
    })
    .required("New password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm New password is required"),
});
