import React from "react";
import "../css/customTextArea.css";

const CustomTextArea = React.forwardRef(({ label, name, ...props }, ref) => {
  return (
    <div className="customTextArea_container">
      <label htmlFor={name}>{label}</label>
      <textarea {...props} name={name} id={name} ref={ref} />
    </div>
  );
});

export default CustomTextArea;
