import { getCountriesAPI, getStatesAPI, getCitiesAPI } from "../APIs";

// to get all countries list.
export const getCountries = async () => {
  let response = await getCountriesAPI();
  return response.map((item) => item?.name);
};

// to get all country objects
export const getCountriesObjects = async () => {
  let response = await getCountriesAPI();
  return response;
};

//to get all states  of a country using iso2
export const getStatesOfACountry = async (iso2) => {
  let response = await getStatesAPI(iso2);
  return response;
};

//to get all states  of a country using iso2
export const getCities = async (iso2, state_code) => {
  let response = await getCitiesAPI(iso2, state_code);
  return response;
};

export const CapitalizeAWord = (word) => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
};
