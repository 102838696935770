import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import DotLoader from "../../components/DotLoader";

import AXIOS_REQUEST from "../../helpers/Axios";
import {
  PAYPAL_PAYMENT_CHECK_ENDPOINT,
  PAYPAL_SUBSCRIPTION_CHECK_ENDPOINT,
} from "../../constants/API_ENDPOINTS";

const PaymentValidate = () => {

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  //Subscription or Payment validate Paypal
  const checkSubscriptionOrPayment = async () => {
    try {
      setLoading(true);
      let paymentId = searchParams.get("paymentId");
      let subscriptionId = searchParams.get("subscription_id");
      let response;
      if (paymentId) {
        response = await AXIOS_REQUEST.get(
          PAYPAL_PAYMENT_CHECK_ENDPOINT + "?" + searchParams
        );
      } else if (subscriptionId) {
        response = await AXIOS_REQUEST.get(
          PAYPAL_SUBSCRIPTION_CHECK_ENDPOINT + "?" + searchParams
        );
      }
      if (response.data.status === true) {
        setLoading(false);
        window.opener.postMessage(response.data, "*");
        window.close();
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSubscriptionOrPayment();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <DotLoader loading={loading} />
    </div>
  );
};

export default PaymentValidate;
