import AXIOS_REQUEST from "../helpers/Axios";
import {
  COUNTRIES_ENDPOINT,
  STATES_ENDPOINT,
  CITIES_ENDPOINT,
  FRAME_FORM,
  GET_ALL_FORMS_ENDPOINT,
  GET_DEFAULT_CRM,
  GET_DEFAULT_PP,
  GET_FORM_DETAILS_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  USER_PROFILE_ENDPOINT,
  TOGGLE_PROMOTIONS_ENDPOINT,
  DISCOUNT_CHECK_ENDPOINT,
} from "../constants/API_ENDPOINTS";
import { toast } from "react-toastify";

//API to get list of countries
export const getCountriesAPI = async () => {
  try {
    const response = await AXIOS_REQUEST.get(COUNTRIES_ENDPOINT);
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

//API to get list of states of a country
export const getStatesAPI = async (iso2) => {
  try {
    const response = await AXIOS_REQUEST.get(STATES_ENDPOINT + "/" + iso2);
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

//API to get list of cities of a state in a country
export const getCitiesAPI = async (iso2, state_code) => {
  try {
    const response = await AXIOS_REQUEST.get(
      CITIES_ENDPOINT + "/" + iso2 + "/" + state_code
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

//get details of a user
export const getUserDetailsAPI = async (TOKEN) => {
  let response = await AXIOS_REQUEST.get(USER_PROFILE_ENDPOINT, {
    headers: {
      Authorization: TOKEN,
    },
  });
  if (response) {
    return response.data;
  }
};

//get a form of a user using form_id and form_step
export const getFormDetailsAPI = async (form_id, form_step, TOKEN) => {
  try {
    let response = await AXIOS_REQUEST.post(
      `${GET_FORM_DETAILS_ENDPOINT}`,
      { form_id: form_id, form_step: form_step },
      {
        headers: {
          Authorization: TOKEN,
        },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

//get all forms of a user.
export const getAllFormsAPI = async (TOKEN) => {
  try {
    let response = await AXIOS_REQUEST.get(GET_ALL_FORMS_ENDPOINT, {
      headers: {
        Authorization: TOKEN,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

// get Default PP
export const getDefaultPP_API = async (TOKEN) => {
  try {
    let response = await AXIOS_REQUEST.get(GET_DEFAULT_PP, {
      headers: {
        Authorization: TOKEN,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

// get Default PP
export const getDefaultCRM_API = async (TOKEN) => {
  try {
    let response = await AXIOS_REQUEST.get(GET_DEFAULT_CRM, {
      headers: {
        Authorization: TOKEN,
      },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

//get Frame form
export const getFrameForm_API = async (formId) => {
  try {
    let response = await AXIOS_REQUEST.get(FRAME_FORM + "/" + formId);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

//change Password API
export const changePasswordAPI = async (TOKEN, values) => {
  try {
    let response = await AXIOS_REQUEST.post(UPDATE_PASSWORD_ENDPOINT, values, {
      headers: { Authorization: TOKEN },
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};

// Toggle Promotion API
export const TogglePromotionAPI = async (TOKEN, wants) => {
  try {
    let response = await AXIOS_REQUEST.post(
      TOGGLE_PROMOTIONS_ENDPOINT,
      { wants: wants },
      {
        headers: { Authorization: TOKEN },
      }
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};

// Check Discount Applied or not
export const checkDiscountAPI = async (discount_code, frame_id) => {
  try {
    let response = await AXIOS_REQUEST.post(DISCOUNT_CHECK_ENDPOINT, {
      frame_code: frame_id,
      user_discount_code: discount_code,
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};
