import React from "react";
import "../css/CustomCheckBox.css";
import { useField } from "formik";

const CustomCheckbox = ({
  label,
  name,
  id,
  widthBottomPad,
  checkLeft,
  checked,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={
        widthBottomPad ? "checkbox_div with_padding_bottom" : "checkbox_div"
      }
    >
      {checkLeft ? <label htmlFor={id}>{label}</label> : null}

      <div className="check_div">
        <input
          // checked={checked}
          type="checkbox"
          checked={checked}
          name={name}
          id={id}
          value={checked}
          {...field}
          {...props}
        />
      </div>
      {label ? checkLeft ? null : <label htmlFor={id}>{label}</label> : null}
    </div>
  );
};

export default CustomCheckbox;
