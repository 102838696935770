import React from "react";
import { Link } from "react-router-dom";

import "../css/footer.css";
import { IMAGES } from "../constants/images";
import { ROUTE_URLS } from "../constants/URLS";

const Footer = () => {
  const {DASHBOARD} = ROUTE_URLS
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer">
            <div className="footer_sec_1">
              <Link to={DASHBOARD}>
                <img src={IMAGES.footerLogo} alt={"Carlie Anglemire"} />
              </Link>
              <div className="text">Part of the Commino Companies</div>
            </div>
            <div className="footer_sec_2">
              <div className="footer_sec_22">
                <h4>Product</h4>
                <ul>
                  <li>
                    <Link to={"#"}>Create Account</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Help Centre</Link>{" "}
                  </li>
                  <li>
                    <Link to={"#"}>Case Studies</Link>
                  </li>
                </ul>
              </div>
              <div className="footer_sec_22">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to={"#"}>About us</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Blog</Link>{" "}
                  </li>
                  <li>
                    <Link to={"#"}>Terms of use</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Privacy</Link>
                  </li>
                </ul>
              </div>
              <div className="footer_sec_22">
                <h4>Contact</h4>
                <ul>
                  <li>
                    <Link to={"mailto:support@onepagepay.com"}>
                      support@onepagepay.com{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to={"tel:+44 (0)20 7193 6057"}>
                      +44 (0)20 7193 6057
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer_sec_3">
              <Link to={"#"}>
                <img src={IMAGES.chat_img} alt={"Carlie Anglemire"} />
              </Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer_bottom">
        <p>© All Rights Reserved OnePagePay 2021</p>
      </div>
    </>
  );
};

export default Footer;
