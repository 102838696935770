import React from "react";
import "../css/FormikCustomTextArea.css";
import { useField } from "formik";

// const CustomTextArea = ({
//   label,
//   name,
//   // , onChange,
//   ...props
// }) => {
//   const [field, meta] = useField(props);

//   return (
//     <div className={"container"}>
//       <label htmlFor={name}>{label}</label>
//       <textarea
//         {...field}
//         {...props}
//         name={props.name}
//         id={name}
//         // onChange={onChange}
//       />
//       {meta.touched && meta.error ? (
//         <div className="invalid-error invalid-feedback">{meta.error}</div>
//       ) : null}
//     </div>
//   );
// };

const CustomTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <div className={"container"}>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="text-area" {...field} {...props} />
          {meta.touched && meta.error ? (
              <div className="invalid-error invalid-feedback">{meta.error}</div>
          ) : null}
      </div>
  );
};

export default CustomTextArea;
