import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/CustomInput";
import CustomToast from "../../../components/CustomToast";

import "./ChangePassword.css";
import { Form, Formik } from "formik";
import { ForgotChangePassSchema } from "../../../helpers/validationSchema";
import AXIOS_REQUEST from "../../../helpers/Axios";
import { ROUTE_URLS } from "../../../constants/URLS";
import { UPDATE_PASSWORD_ENDPOINT } from "../../../constants/API_ENDPOINTS";
import DotLoader from "../../../components/DotLoader";

export default function ChangePassword() {
  const { LOGIN, REGISTER } = ROUTE_URLS;
  let [searchParams, setSearchParams] = useSearchParams();

  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);
  let tokenFromUrl = searchParams.get("token");

  const showToast = (message, type) => {
    setToast({ message, type });
  };

  const handleChangeNewPassword = async (values, resetForm) => {
    try {
      setLoading(true);

      let response = await AXIOS_REQUEST.post(
        UPDATE_PASSWORD_ENDPOINT,
        values,
        {
          headers: {
            Authorization: "Bearer " + tokenFromUrl,
          },
        }
      );

      if (response.status == 200) {
        setLoading(false);
        if (response.data.status) {
          showToast(response.data.message, "message");
          resetForm({ password: "", password_confirmation: "" });
          setTimeout(() => {
            setToast(null);
          }, 10000);
        } else {
          showToast(response.data.message, "error");
        }
      } else {
        setLoading(false);

        showToast(response.data.message, "error");
      }
    } catch (error) {
      setLoading(false);
      showToast("Something went wrong.", "error");
      setTimeout(() => {
        setToast(null);
      }, 10000);
    }
  };
  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="lost_password_sec">
        <div className="page_container">
          <div className="forgot">
            {toast && (
              <CustomToast
                message={toast.message}
                type={toast.type}
                onClose={() => setToast(null)}
              />
            )}
            <Formik
              initialValues={{ password: "", password_confirmation: "" }}
              validationSchema={ForgotChangePassSchema}
              onSubmit={(values, { resetForm }) => {
                handleChangeNewPassword(values, resetForm);
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form>
                  <CustomInput
                    type="password"
                    name="password"
                    label={"Password"}
                    required={true}
                    error={errors && errors.password}
                  />
                  <CustomInput
                    type="password"
                    name="password_confirmation"
                    label={"Confirm Password"}
                    required={true}
                    error={errors && errors.password_confirmation}
                  />

                  {/* <Link onClick={handleCreateNewPassword}> */}
                  <button type="submit">Change Password</button>
                  {/* </Link> */}
                </Form>
              )}
            </Formik>
            <div className="logi_register">
              <ul>
                <Link to={LOGIN}>
                  <li>Log in</li>
                </Link>
                <Link to={REGISTER}>
                  <li>Register</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
