import React from "react";
import "../css/CustomBoxHeader.css"

const CustomBoxHeader = ({title_img,title}) => {
  return (
    <div className="box_header">
      <img src={title_img} alt={title} />
      {title}
    </div>
  );
};

export default CustomBoxHeader;
