export const ROUTE_URLS = {
  // AUTH_ROUTES
  LOGIN: "/",
  REGISTER: "/register",
  ABOUT_US: "/about",
  FORGOT_PASS: "/ForgotPass",
  CHANGE_PASSWORD: "/forgot",
  GET_SUPPORT: "/getSupport",
  CREATE_ACCOUNT: "/Createaccount",
  BILLING_INFO: "/BillingInfo",
  PAYMENT_DETAIL: "/PaymentDetail",
  FINAL_SIGNUP: "/FinalSignup",

  //   DASHBOARD_ROUTES
  MY_ACCOUNT: "/myaccount",
  BILLING_HISTORY: "/BillingHistory",
  DASHBOARD: "/dashboard",
  MANAGE_PAYMENT: "/ManagePayment",
  CHANGE_CRM: "/changeCRM",
  CHANGE_PAYMENT_PROCESSOR: "/changePaymentProcessor",

  //   CREATE_FORM_ROUTES
  CREATE_FORM: "/createForm",
  SETUP_PRODUCT: "/setupProduct",
  SETUP_PAYMENT: "/setupPayment",
  PAYMENT_PROCESSOR: "/PaymentProcessor",
  CONGRATS: "/congrats",
  CRM: "/CrmSystem",
  FORM_DESIGN: "/formDesign",
  CONFIRM_SELECTION: "/confirmSelection",
  FORM_CODE: "/FormCode",
  PAYMENT_VALIDATE: "/validate",

  EMBED_FORM1C: "/product",

  //EXTRA
  PROFILE: "/profile",

  //   DEFAULT_ROUTE
  ALL_OTHER: "*",

  //   PARAMS
  FORM_ID_PARAM: "/:form_id",
  FORM_CODE_PARAM: "/:form_code_id",
  PP_CRM_PARAM: "/:pp_or_crm",
  FORM_NAME_PARAM: "/:formName",
};
