import { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import DotLoader from "../../components/DotLoader";
import NoData from "../../components/NoData";
import "./column.css";

import { checkDiscountAPI } from "../../APIs";
import { EMAIL_REGEX } from "../../constants/EXTRA_CONSTANTS";
import AXIOS_REQUEST from "../../helpers/Axios";
import {
  BillingRequiredFields,
  CardDetailsFields,
  ShippingRequiredFields,
  calculateDiscountPrice,
  calculateProductPrice,
  calculateSalesTax,
  calculateTotalPrice,
  checkFieldsForValidation,
  generateErrorMessage,
  handleNumberField,
  resetErrors,
} from "./helper";
import { SUBMIT_IFRAME_ENDPOINT } from "../../constants/API_ENDPOINTS";
import Autocomplete from "react-autocomplete";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );
};
const useResponsiveFontSize = () => {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "25px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
};

const IFrameFormWithCardDetails = ({
  countriesObjects,
  countriesList,
  loading,
  setLoading,
  formData,
  noData,
  productQuantity,
  setProductQuantity,
  productSelected,
  setProductSelected,
  productDetails,
  setProductDetails,
  message,
  setMessage,
}) => {
  const { form_id } = useParams();
  const formRef = useRef();
  const MaskedInputRef = useRef(null);
  const options = useOptions();

  const stripe = useStripe();
  const elements = useElements();

  const [billingAddress, setBillingAddressFields] = useState({
    billing_first_name: "",
    billing_last_name: "",
    billing_email: "",
    billing_phone: "",
    billing_company_name: "",
    billing_street_address: "",
    billing_add_line_2: "",
    billing_city: "",
    billing_state: "",
    billing_zip_postal_code: "",
    billing_country: "",
  });
  const [shippingAddress, setShippingAddressFields] = useState({
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_company_name: "",
    shipping_street_address: "",
    shipping_address_line_2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip_postal_code: "",
    shipping_country: "",
  });

  const [billingAddressErrors, setBillingAddressErrors] = useState();
  const [shippingAddressErrors, setShippingAddressErrors] = useState();
  const [cardDetailsErrors, setCardDetailsErrors] = useState();
  const [cardLength, setCardLength] = useState(16);
  const [discountCode, setDiscountCode] = useState();
  const [discountActive, setDiscountActive] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);

  const [cardErrors, setCardErrors] = useState({
    cardNumber: {},
    cardExpiry: {},
    security_code: {},
  });

  const handleApplyDiscount = async () => {
    try {
      let response = await checkDiscountAPI(discountCode.trim(), form_id);
      if (response) {
        if (response.status) {
          setDiscountValue(response.data.discount_value);
          setDiscountApplied(response.status);
        } else {
          toast.error("Not a valid Discount Code.");
        }
      }
    } catch (error) {}
  };
  const handleChangeProduct = (event) => {
    let filteredProduct = productDetails?.filter(
      (item) => item.product_name == event.target.value
    );
    setProductSelected(filteredProduct[0]);
  };
  //handling change in billing fields
  const handleBillingFields = async (event) => {
    const { name, value } = event.target;
    if (name != "billiing_country" || name != "billing_phone") {
      setBillingAddressFields((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
    if (name == "billing_phone") {
      const billing_phone = value.replace(/[^0-9+]/g, "").slice(0, 15);
      setBillingAddressFields((prevValues) => ({
        ...prevValues,
        [name]: billing_phone,
      }));
    }
  };

  const handleCountryDropDown = (name, country) => {
    if (name == "billing_country") {
      setBillingAddressFields((prevValues) => ({
        ...prevValues,
        [name]: country,
      }));
    }
    if (name == "shipping_country") {
      setShippingAddressFields((prevValues) => ({
        ...prevValues,
        [name]: country,
      }));
    }
  };

  const handleDiscountCode = (event) => {
    if (event.target.value.trim()) {
      setDiscountActive(true);
    } else {
      setDiscountActive(false);
    }
    setDiscountCode(event.target.value);
  };

  //handling change in Shipping fields
  const handleShippingFields = async (event) => {
    const { name, value } = event.target;
    setShippingAddressFields((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmitCardElement = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: billingAddress.billing_city,
          // country: userDetails.address.country_code,
          state: billingAddress.billing_state,
          line1: billingAddress.billing_street_address,
          line2: billingAddress.billing_add_line_2,
          postal_code: billingAddress.billing_zip_postal_code,
        },
        email: billingAddress.billing_email,
        name:
          billingAddress.billing_first_name +
          " " +
          billingAddress.billing_last_name,
        phone: billingAddress.phone,
      },
    });
    if (payload.error) {
      toast.error(payload.error.message);
      return payload;
    } else {
      return payload;
    }
  };

  //submitting the form to purchase the product
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (productQuantity <= 0) {
      return toast.error("Please enter valid quantity.");
    }
    if (!productSelected) {
      return toast.error("Please select the product.");
    }

    let err = 0;

    const allFields = checkFieldsForValidation(
      formData?.is_shipping_address,
      formData?.payment_processor
    );

    allFields.forEach((field) => {
      const fieldValue = billingAddress?.[field] || shippingAddress?.[field];
      const errorSetter = field.includes("billing")
        ? setBillingAddressErrors
        : setShippingAddressErrors;

      if (!fieldValue) {
        const errorMessage = generateErrorMessage(field);
        errorSetter((prevValues) => ({
          ...prevValues,
          [field]: errorMessage,
        }));
        err++;
      }
      if (fieldValue) {
        if (field === "billing_email") {
          if (EMAIL_REGEX.test(fieldValue)) {
            let errorMessage = "";
            errorSetter((prevValues) => ({
              ...prevValues,
              [field]: errorMessage,
            }));
          } else {
            let errorMessage = `${
              field?.charAt(8)?.toUpperCase() + field?.slice(9)
            } is invalid.`;
            errorSetter((prevValues) => ({
              ...prevValues,
              [field]: errorMessage,
            }));
          }
        } else {
          let errorMessage = "";
          errorSetter((prevValues) => ({
            ...prevValues,
            [field]: errorMessage,
          }));
        }
      }
    });

    if (err === 0) {
      let payload = formData.payment_processor != "paypal" ? false : true;

      if (formData.payment_processor != "paypal") {
        setLoading(true);
        payload = await handleSubmitCardElement();
      }

      if (!payload.error) {
        let data = {
          form_code: form_id,
          discount_code:discountCode,
          quantity: productQuantity,
          billing_details: billingAddress,
          payment_details: {
            client_payment_method_response: payload,
            payment_id: payload.paymentMethod?.id,
          },
          shipping_details: shippingAddress,
          purchased_product: productSelected,
          discount_available: discountApplied,
        };
        try {
          let response = await AXIOS_REQUEST.post(SUBMIT_IFRAME_ENDPOINT, data);
          if (response.data.status) {
            if (formData.payment_processor != "paypal") {
              setLoading(false);
              resetErrors(BillingRequiredFields, setBillingAddressFields);
              resetErrors(ShippingRequiredFields, setShippingAddressFields);
              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();
              formRef.current.reset();
              if (MaskedInputRef.current) {
                MaskedInputRef.current.inputElement.value = ""; // Clear the input's value
              }
              setProductQuantity(1);
              if (formData?.form_type !== "single_product") {
                setProductSelected();
              }
              toast.success(response.data.message);
              if (formData?.next_action) {
                let next_action = formData?.next_action;
                let search = `?name=${
                  billingAddress.billing_first_name +
                  " " +
                  billingAddress.billing_last_name
                }&email=${billingAddress.billing_email}`;
                let url = next_action + search;
                window.open(url, "_blank");
              }
            } else {
              const newTab = window.open(response.data.payment_link, "_blank");
              window.addEventListener("message", function (event) {
                // Check if the message is coming from the new tab
                if (event.source === newTab) {
                  event.source.postMessage(
                    "Message from original tab",
                    event.origin
                  );
                  setLoading(false);
                  setLoading(false);
                  resetErrors(BillingRequiredFields, setBillingAddressFields);
                  resetErrors(ShippingRequiredFields, setShippingAddressFields);
                  formRef.current.reset();
                  if (MaskedInputRef.current) {
                    MaskedInputRef.current.inputElement.value = ""; // Clear the input's value
                  }
                  setProductQuantity(1);
                  if (formData?.form_type !== "single_product") {
                    setProductSelected();
                  }
                  toast.success(event.data.message);

                  if (formData?.next_action) {
                    let next_action = formData?.next_action;
                    let search = `?name=${
                      billingAddress.billing_first_name +
                      " " +
                      billingAddress.billing_last_name
                    }&email=${billingAddress.billing_email}`;
                    let url = next_action + search;
                    window.open(url, "_blank");
                  }
                }
              });
            }
          } else {
            setLoading(false);
            toast.error(response.data.message);
          }
        } catch (error) {
          setLoading(false);
          toast.error(error.message);
        }
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <form ref={formRef}>
        <DotLoader loading={loading} />
        {noData ? (
          <NoData
            style={{ width: "100vw", height: "100vh" }}
            message={message}
          />
        ) : (
          <div
            className={
              formData?.form_style === "single_column"
                ? "column_form_main one"
                : "column_form_main two"
            }
          >
            <div className="logo_image_div">
              <img
                className="logo_image"
                src={
                  formData?.logo_file_path
                    ? formData?.logo_file_path
                    : formData?.logo_file_url
                }
                alt="Logo_Image"
              />
            </div>
            <div className="container_column">
              <div className={"single_column_main"}>
                <div className="details_img_sec">
                  <div
                    className={`flex_section_img ${
                      formData?.form_style == "single_column" ? "" : "no_bg"
                    }`}
                  >
                    <div
                      className={`product_select ${
                        formData?.form_type == "single_product" &&
                        formData?.form_style == "single_column"
                          ? "flex"
                          : ""
                      }`}
                    >
                      {formData?.form_type == "single_product" ? (
                        <div className="product__details__single">
                          <h4>
                            Product Name:{" "}
                            <span className="product__name">
                              {formData?.form_type_products &&
                                formData?.form_type_products.length > 0 &&
                                JSON.parse(formData?.form_type_products)[0]
                                  ?.product_name}{" "}
                            </span>
                          </h4>
                          <p className="euro_price">
                            Product Price
                            <span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formData?.currency_symbol,
                                }}
                              ></span>{" "}
                              {
                                JSON.parse(formData.form_type_products)[0]
                                  ?.product_price
                              }
                            </span>
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="main_multiple_single_col">
                            <div>
                              <div>
                                <label
                                  className="bold_primary"
                                  htmlFor="multiple_product"
                                >
                                  Select your Product
                                </label>
                                <br />
                                <select
                                  name=""
                                  id="multiple_product"
                                  value={productSelected?.product_name}
                                  onChange={handleChangeProduct}
                                >
                                  <option value="">Select Your Product</option>
                                  {formData?.form_type_products &&
                                    JSON.parse(
                                      formData?.form_type_products
                                    )?.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item?.product_name}
                                      >
                                        {item?.product_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              {formData?.form_style === "single_column" &&
                              formData?.form_type === "multiple_product" &&
                              productSelected ? (
                                <div>
                                  <p className="euro_price">
                                    Selected Product Price
                                    <span>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: formData?.currency_symbol,
                                        }}
                                      ></span>{" "}
                                      {productSelected?.product_price}
                                    </span>
                                  </p>
                                </div>
                              ) : null}
                            </div>
                            {productSelected &&
                            formData?.form_style == "single_column" ? (
                              <div className="Quantity_sec">
                                <label
                                  className="bold_primary"
                                  htmlFor="productQuantityMobile"
                                >
                                  Quantity
                                </label>
                                <input
                                  type="number"
                                  id="productQuantityMobile"
                                  value={productQuantity}
                                  onKeyDown={handleNumberField}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(event) =>
                                    setProductQuantity(event.target.value)
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      {formData?.form_type === "single_product" &&
                      formData?.form_style == "single_column" ? (
                        <div className="quantity_single_column">
                          <div className="Quantity_sec">
                            <label
                              className="bold_primary"
                              htmlFor="productQuantity"
                            >
                              Quantity
                            </label>
                            <input
                              id="productQuantity"
                              type="number"
                              value={productQuantity}
                              onKeyDown={handleNumberField}
                              onWheel={(e) => e.target.blur()}
                              onChange={(event) =>
                                setProductQuantity(event.target.value)
                              }
                            />
                          </div>
                          {/* <div className="price_sec">
                          <h4>Total</h4>
                          <div className="total_price_info">
                            <p>Price</p>
                            <p>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: formData?.currency_symbol,
                                }}
                              ></span>{" "}
                              {Number(
                                JSON.parse(formData.form_type_products)[0]
                                  ?.product_price
                              ) * productQuantity}
                            </p>
                          </div>
                        </div> */}
                        </div>
                      ) : null}
                    </div>
                    {formData?.form_style === "single_column" &&
                    productSelected ? (
                      <div className="double_column_main block single_order_summary">
                        <div
                          className={`content_sec ${
                            formData?.form_style === "single_column"
                              ? "no_pad"
                              : ""
                          }`}
                        >
                          <h6 className="bold_primary">Order Summary</h6>
                          <div className="product_price_sec">
                            <p>Product Price:</p>
                            <p>
                              <span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formData?.currency_symbol,
                                  }}
                                ></span>{" "}
                                {/* {calculateProductPrice(
                                productSelected?.product_price,
                                productQuantity
                              )} */}
                                {Number(
                                  productSelected?.product_price
                                )?.toFixed(2)}
                              </span>
                            </p>
                          </div>
                          {/* <div className="quantity_input">
                        <p>Quantity</p>
                        <input
                          type="number"
                          value={productQuantity}
                          onKeyDown={handleNumberField}
                          onWheel={(e) => e.target.blur()}
                          onChange={(event) =>
                            setProductQuantity(event.target.value)
                          }
                        />{" "}
                      </div> */}
                          {/* {formData?.is_discount ? ( */}
                            <div className="discount_input">
                              <p>Discount Code</p>
                              <div className="discount_input_inner">
                                <input
                                  type="text"
                                  value={discountCode}
                                  onChange={handleDiscountCode}
                                  disabled={discountApplied}
                                />
                                <div
                                  onClick={
                                    discountActive && !discountApplied? handleApplyDiscount : null
                                  }
                                  className={
                                    discountActive
                                      ? discountApplied
                                        ? "discount_apply_btn discount_button_applied"
                                        : "discount_apply_btn discount_button_active"
                                      : "discount_apply_btn"
                                  }
                                  role="button"
                                  disabled={!discountActive}
                                  title={
                                    discountActive
                                      ? discountApplied
                                        ? "Applied"
                                        : "Apply"
                                      : "Type to Apply Discount Code"
                                  }
                                >
                                  {!discountApplied ? "Apply" : "Applied"}
                                </div>
                              </div>
                            </div>
                          {/* ) : null} */}

                          <div className="price_sec">
                            <div className="total_price_info">
                              <p>Total Product Price </p>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formData?.currency_symbol,
                                  }}
                                ></span>{" "}
                                {calculateProductPrice(
                                  productSelected?.product_price,
                                  productQuantity
                                )?.toFixed(2)}
                              </p>
                            </div>
                            {discountApplied ? (
                              <div className="total_price_info discount_div_info">
                                <p>Discount Applied </p>
                                <p>
                                  {"- "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: formData?.currency_symbol,
                                    }}
                                  ></span>{" "}
                                  {calculateDiscountPrice(
                                    productSelected?.product_price,
                                    discountValue,
                                    productQuantity
                                  )?.toFixed(2)}
                                </p>
                              </div>
                            ) : null}

                            <div className="total_price_info">
                              <p>
                                Sales Tax{" "}
                                {formData?.sale_tax &&
                                  "(" + formData?.sale_tax + "%)"}
                              </p>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formData?.currency_symbol,
                                  }}
                                ></span>{" "}
                                {formData?.sale_tax
                                  ? calculateSalesTax(
                                      productSelected?.product_price,
                                      discountValue,
                                      formData?.sale_tax,
                                      productQuantity
                                    )?.toFixed(2)
                                  : "0.00"}
                              </p>
                            </div>

                            <div className="total_price_info total_amount">
                              <p>Total Amount</p>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: formData?.currency_symbol,
                                  }}
                                ></span>{" "}
                                {calculateTotalPrice(
                                  productSelected?.product_price,
                                  discountValue,
                                  formData?.sale_tax,
                                  productQuantity
                                )?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="billing_add">
                  <h3>Billing Address</h3>
                  <div className="double_col">
                    <div className="error_input">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="billing_first_name"
                        onChange={handleBillingFields}
                      />
                      {billingAddressErrors?.billing_first_name ? (
                        <small>
                          {billingAddressErrors?.billing_first_name}
                        </small>
                      ) : null}
                    </div>

                    <div className="error_input">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="billing_last_name"
                        onChange={handleBillingFields}
                      />
                      {billingAddressErrors?.billing_last_name ? (
                        <small>{billingAddressErrors?.billing_last_name}</small>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="single_thirty_col"> */}

                  <div className="double_col">
                    <div className="error_input">
                      <input
                        type="email"
                        placeholder="Email"
                        name="billing_email"
                        onChange={handleBillingFields}
                      />
                      {billingAddressErrors?.billing_email ? (
                        <small>{billingAddressErrors?.billing_email}</small>
                      ) : null}
                    </div>
                    <div className="error_input">
                      <input
                        type="text"
                        // onKeyDown={handleNumberField}
                        // onWheel={(e) => e.target.blur()}
                        placeholder="Phone"
                        name="billing_phone"
                        onChange={handleBillingFields}
                        value={billingAddress.billing_phone}
                      />
                      {billingAddressErrors?.billing_phone ? (
                        <small>{billingAddressErrors?.billing_phone}</small>
                      ) : null}
                    </div>
                    <div className="error_input">
                      <input
                        type="text"
                        placeholder="Company Name"
                        name="billing_company_name"
                        onChange={handleBillingFields}
                      />
                      {billingAddressErrors?.billing_company_name ? (
                        <small>
                          {billingAddressErrors?.billing_company_name}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <div className="single_col">
                    <input
                      type="text"
                      placeholder="Street Address"
                      name="billing_street_address"
                      onChange={handleBillingFields}
                    />
                    {billingAddressErrors?.billing_street_address ? (
                      <small>
                        {billingAddressErrors?.billing_street_address}
                      </small>
                    ) : null}

                    <input
                      type="text"
                      placeholder="Address line 2 (Optional)"
                      name="billing_add_line_2"
                      onChange={handleBillingFields}
                    />
                    {billingAddressErrors?.billing_add_line_2 ? (
                      <small>{billingAddressErrors?.billing_add_line_2}</small>
                    ) : null}
                  </div>
                  <div className="double_col">
                    <div className="error_input">
                      <Autocomplete
                        getItemValue={(item) => item}
                        items={countriesList}
                        wrapperProps={{
                          style: {
                            width: "100%",
                            background: "white",
                            position: "relative",
                            zIndex: 99999,
                          },
                        }}
                        menuStyle={{
                          background: "white",
                          minWidth: "auto",
                          maxHeight: "200px",
                          overflowY: "scroll",
                          paddingBottom: "10px",
                          top: "unset",
                          left: "unset",
                          position: "absolute",
                          width: "100%",
                        }}
                        renderItem={(item, isHighlighted) => (
                          <div
                            key={item}
                            style={{
                              background: isHighlighted ? "lightgray" : "white",
                            }}
                          >
                            {item}
                          </div>
                        )}
                        inputProps={{
                          name: "billing_country",
                          placeholder: "Select Country",
                        }}
                        value={billingAddress?.billing_country}
                        onChange={(event, value) =>
                          handleCountryDropDown("billing_country", value)
                        }
                        onSelect={(event, value) =>
                          handleCountryDropDown("billing_country", value)
                        }
                        shouldItemRender={(item, value) =>
                          item.toLowerCase().includes(value.toLowerCase())
                        }
                      />

                      {billingAddressErrors?.billing_country ? (
                        <small>{billingAddressErrors?.billing_country}</small>
                      ) : null}
                    </div>
                    <div className="error_input">
                      {/* <select
                      name="billing_state"
                      id=""
                      onChange={handleBillingFields}
                    >
                      <option value="">Select State</option>
                      {billingStatesList &&
                        billingStatesList?.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {billingAddressErrors?.billing_state ? (
                      <small>{billingAddressErrors?.billing_state}</small>
                    ) : null} */}
                      <input
                        type="text"
                        placeholder="State / County (Optional)"
                        name="billing_state"
                        onChange={handleBillingFields}
                      />
                    </div>
                  </div>
                  <div className="double_col">
                    <div className="error_input">
                      <input
                        type="text"
                        placeholder="City (Optional)"
                        name="billing_city"
                        onChange={handleBillingFields}
                      />
                      {/* <select
                      name="billing_city"
                      id=""
                      onChange={handleBillingFields}
                    >
                      <option value="">Select City</option>
                      {billingCitiesList &&
                        billingCitiesList?.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {billingAddressErrors?.billing_city ? (
                      <small>{billingAddressErrors?.billing_city}</small>
                    ) : null} */}
                    </div>
                    <div className="error_input">
                      <input
                        type="text"
                        placeholder="Zip / Postal Code"
                        // onKeyDown={handleNumberField}
                        // onWheel={(e) => e.target.blur()}
                        name="billing_zip_postal_code"
                        onChange={handleBillingFields}
                      />
                      {billingAddressErrors?.billing_zip_postal_code ? (
                        <small>
                          {billingAddressErrors?.billing_zip_postal_code}
                        </small>
                      ) : null}
                    </div>

                    {/* <input type="text" placeholder="Country" /> */}
                  </div>
                </div>
                {formData?.is_shipping_address ? (
                  <div className="Shipping_add">
                    <h3>Shipping Address</h3>
                    <div className="double_col">
                      <div className="error_input">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="shipping_first_name"
                          onChange={handleShippingFields}
                        />
                        {shippingAddressErrors?.shipping_first_name ? (
                          <small>
                            {shippingAddressErrors?.shipping_first_name}
                          </small>
                        ) : null}
                      </div>
                      <div className="error_input">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="shipping_last_name"
                          onChange={handleShippingFields}
                        />
                        {shippingAddressErrors?.shipping_last_name ? (
                          <small>
                            {shippingAddressErrors?.shipping_last_name}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <div className="single_thirty_col">
                      {/* <input type="text" placeholder='Email' />
                          <input type="text" placeholder='Phone' /> */}
                      <input
                        type="text"
                        placeholder="Company Name"
                        name="shipping_company_name"
                        onChange={handleShippingFields}
                      />
                      {shippingAddressErrors?.shipping_company_name ? (
                        <small>
                          {shippingAddressErrors?.shipping_company_name}
                        </small>
                      ) : null}
                    </div>
                    <div className="single_col">
                      <input
                        type="text"
                        placeholder="Street Address"
                        name="shipping_street_address"
                        onChange={handleShippingFields}
                      />
                      {shippingAddressErrors?.shipping_street_address ? (
                        <small>
                          {shippingAddressErrors?.shipping_street_address}
                        </small>
                      ) : null}
                      <input
                        type="text"
                        placeholder="Address line 2 (Optional)"
                        name="shipping_address_line_2"
                        onChange={handleShippingFields}
                      />
                      {shippingAddressErrors?.shipping_address_line_2 ? (
                        <small>
                          {shippingAddressErrors?.shipping_address_line_2}
                        </small>
                      ) : null}
                    </div>
                    <div className="double_col">
                      <div className="error_input">
                        <Autocomplete
                          getItemValue={(item) => item}
                          items={countriesList}
                          wrapperProps={{
                            style: {
                              width: "100%",
                              background: "white",
                              position: "relative",
                              zIndex: 99999,
                            },
                          }}
                          menuStyle={{
                            background: "white",
                            minWidth: "auto",
                            maxHeight: "200px",
                            overflowY: "scroll",
                            paddingBottom: "10px",
                            top: "unset",
                            left: "unset",
                            position: "absolute",
                            width: "100%",
                          }}
                          renderItem={(item, isHighlighted) => (
                            <div
                              key={item}
                              style={{
                                background: isHighlighted
                                  ? "lightgray"
                                  : "white",
                              }}
                            >
                              {item}
                            </div>
                          )}
                          inputProps={{
                            name: "shipping_country",
                            placeholder: "Select Country",
                          }}
                          value={shippingAddress?.shipping_country}
                          onChange={(event, value) =>
                            handleCountryDropDown("shipping_country", value)
                          }
                          onSelect={(event, value) =>
                            handleCountryDropDown("shipping_country", value)
                          }
                          shouldItemRender={(item, value) =>
                            item.toLowerCase().includes(value.toLowerCase())
                          }
                        />
                        {shippingAddressErrors?.shipping_country ? (
                          <small>
                            {shippingAddressErrors?.shipping_country}
                          </small>
                        ) : null}
                      </div>

                      <div className="error_input">
                        {/* <select
                        name="shipping_state"
                        id=""
                        onChange={handleShippingFields}
                      >
                        <option value="">Select State</option>
                        {shippingStatesList &&
                          shippingStatesList?.map((item) => (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      
                      {shippingAddressErrors?.shipping_state ? (
                        <small>{shippingAddressErrors?.shipping_state}</small>
                      ) : null} */}

                        <input
                          type="text"
                          placeholder="State / County (Optional)"
                          name="billing_state"
                          onChange={handleBillingFields}
                        />
                      </div>
                    </div>
                    <div className="double_col">
                      <div className="error_input">
                        <input
                          type="text"
                          placeholder="City (Optional)"
                          name="shipping_city"
                          onChange={handleShippingFields}
                        />
                        {/* <select
                        name="shipping_city"
                        id=""
                        onChange={handleShippingFields}
                      >
                        <option value="">Select City</option>
                        {shippingCitiesList &&
                          shippingCitiesList?.map((item) => (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {shippingAddressErrors?.shipping_city ? (
                        <small>{shippingAddressErrors?.shipping_city}</small>
                      ) : null} */}
                      </div>
                      <div className="error_input">
                        <input
                          type="text"
                          placeholder="Zip / Postal Code"
                          // onKeyDown={handleNumberField}
                          // onWheel={(e) => e.target.blur()}
                          name="shipping_zip_postal_code"
                          onChange={handleShippingFields}
                        />
                        {shippingAddressErrors?.shipping_zip_postal_code ? (
                          <small>
                            {shippingAddressErrors?.shipping_zip_postal_code}
                          </small>
                        ) : null}
                      </div>
                      {/* <input type="text" placeholder="Country" /> */}
                    </div>
                  </div>
                ) : null}
                {formData?.payment_processor != "paypal" ? (
                  <div className="carddetails">
                    <h3>Card Details</h3>
                    <div className="payment_details_form_div">
                      <div className="card_sec_input">
                        <label htmlFor="card_Number">Card Number:</label>
                        {/* <input
                type="number"
                id="card_Number"
                name="card_Number"
                onKeyDown={handleNumberField}
                onWheel={(e) => e.target.blur()}
                onChange={handleCardFields}
                placeholder="1234 5678 9012 3456"
              /> */}
                        {/* <InputMask
                          placeholder={
                            cardLength == 16
                              ? "**** **** **** ****"
                              : "**** **** **** **** ***"
                          }
                          mask={
                            cardLength == 16
                              ? [
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]
                              : [
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]
                          }
                          guide={true}
                          placeholderChar={"\u2000"}
                          showMask={false}
                          id="card_Number"
                          name="card_Number"
                          // value={cardDetails.card_Number}
                          onChange={handleCardFields}
                        /> */}

                        <CardNumberElement
                          options={options}
                          onChange={(event) => {
                            setCardErrors({
                              ...cardErrors,
                              cardNumber: event,
                            });
                          }}
                        />
                        {cardErrors.cardNumber &&
                          !cardErrors.cardNumber.empty &&
                          cardErrors.cardNumber.error && (
                            <small>{cardErrors.cardNumber.error.message}</small>
                          )}
                      </div>

                      {/* <div className="card_sec_input">
                        <label htmlFor="card_Holder_Name">
                          Card Holder Name:
                        </label>
                        <input
                          type="text"
                          id="card_Holder_Name"
                          name="card_Holder_Name"
                          onChange={handleCardFields}
                          placeholder="John Doe"
                        />
                        {cardDetailsErrors?.card_Holder_Name ? (
                          <small>{cardDetailsErrors?.card_Holder_Name}</small>
                        ) : null}
                      </div> */}
                      <div className="card_sec_input">
                        <label htmlFor="expiry_Date">Expiry Date:</label>

                        {/* <InputMask
                          ref={MaskedInputRef}
                          id="expiry_Date"
                          placeholder="MM/YY"
                          name="expiry_Date"
                          mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                          // value={cardDetails?.expiry_Date}
                          // defaultValue={cardDetails?.expiry_Date}
                          onChange={handleCardFields}
                        /> */}
                        <CardExpiryElement
                          options={options}
                          onChange={(event) => {
                            setCardErrors({
                              ...cardErrors,
                              cardExpiry: event,
                            });
                          }}
                        />

                        {cardErrors.cardExpiry &&
                          !cardErrors.cardExpiry.empty &&
                          cardErrors.cardExpiry.error && (
                            <small>{cardErrors.cardExpiry.error.message}</small>
                          )}
                      </div>

                      <div className="card_sec_input">
                        <label htmlFor="CVV">CVC:</label>
                        <CardCvcElement
                          options={options}
                          onChange={(event) => {
                            setCardErrors({
                              ...cardErrors,
                              security_code: event,
                            });
                          }}
                        />

                        {cardErrors.security_code &&
                          !cardErrors.security_code.empty &&
                          cardErrors.security_code.error && (
                            <small>
                              {cardErrors.security_code.error.message}
                            </small>
                          )}
                      </div>
                    </div>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="submit_btn mobile_hide"
                    >
                      Submit
                    </button>
                  </div>
                ) : null}
                {formData?.payment_processor === "paypal" ? (
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="submit_btn mobile_hide"
                  >
                    Submit
                  </button>
                ) : null}
              </div>
              {productSelected ||
              (formData?.form_style == "two_column" &&
                formData?.form_type == "single_product") ? (
                <div className="double_column_main">
                  <div className="content_sec">
                    <h6>Order Summary</h6>
                    <div className="product_price_sec">
                      <p>Product Price</p>
                      <p>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formData?.currency_symbol,
                            }}
                          ></span>{" "}
                          {/* {calculateProductPrice(
                          productSelected?.product_price,
                          productQuantity
                        )} */}
                          {Number(productSelected?.product_price)?.toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="quantity_input">
                      <p>Quantity</p>
                      <input
                        type="number"
                        value={productQuantity}
                        onKeyDown={handleNumberField}
                        onWheel={(e) => e.target.blur()}
                        onChange={(event) =>
                          setProductQuantity(event.target.value)
                        }
                      />
                    </div>
                    {/* {formData?.is_discount ? ( */}
                      <div className="discount_input">
                        <p>Discount Code</p>
                        <div className="discount_input_inner">
                          <input
                            type="text"
                            value={discountCode}
                            onChange={handleDiscountCode}
                            disabled={discountApplied}
                          />
                          <div
                            onClick={
                              discountActive ? handleApplyDiscount : null
                            }
                            className={
                              discountActive
                                ? discountApplied
                                  ? "discount_apply_btn discount_button_applied"
                                  : "discount_apply_btn discount_button_active"
                                : "discount_apply_btn"
                            }
                            role="button"
                            disabled={!discountActive}
                            title={
                              discountActive
                                ? discountApplied
                                  ? "Applied"
                                  : "Apply"
                                : "Type to Apply Discount Code"
                            }
                          >
                            {!discountApplied ? "Apply" : "Applied"}
                          </div>
                        </div>
                      </div>
                    {/* ) : null} */}

                    <div className="price_sec">
                      <div className="total_price_info">
                        <p>Total Product Price </p>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formData?.currency_symbol,
                            }}
                          ></span>{" "}
                          {calculateProductPrice(
                            productSelected?.product_price,
                            productQuantity
                          )?.toFixed(2)}
                        </p>
                      </div>
                      {discountApplied ? (
                        <div className="total_price_info discount_div_info">
                          <p>Discount Applied </p>
                          <p>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: formData?.currency_symbol,
                              }}
                            ></span>{" "}
                            {"-"}
                            {calculateDiscountPrice(
                              productSelected?.product_price,
                              discountValue,
                              productQuantity
                            )?.toFixed(2)}
                          </p>
                        </div>
                      ) : null}

                      <div className="total_price_info">
                        <p>
                          Sales Tax{" "}
                          {formData?.sale_tax &&
                            "(" + formData?.sale_tax + "%)"}
                        </p>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formData?.currency_symbol,
                            }}
                          ></span>{" "}
                          {formData?.sale_tax
                            ? calculateSalesTax(
                                productSelected?.product_price,
                                discountValue,
                                formData?.sale_tax,
                                productQuantity
                              )?.toFixed(2)
                            : "0.00"}
                        </p>
                      </div>

                      <div className="total_price_info total_amount">
                        <p>Total Amount</p>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formData?.currency_symbol,
                            }}
                          ></span>{" "}
                          {calculateTotalPrice(
                            productSelected?.product_price,
                            discountValue,
                            formData?.sale_tax,
                            productQuantity
                          )?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <button
                onClick={handleSubmit}
                type="submit"
                className="submit_btn mobile_show"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default IFrameFormWithCardDetails;
