import React from "react";
import { useField } from "formik";
import "./index.css";

const FormikCustomCheckBox = ({ label,id, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="check_box_remember">
      <input id={id} type="checkbox" {...field} {...props} />
      <label htmlFor={id}>{label}</label>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikCustomCheckBox;
