import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import CustomBoxHeader from "../../components/CustomBoxHeader";

import "./ConfirmSelection.css";
import { IMAGES } from "../../constants/images";
import CustomCheckbox from "../../components/CustomCheckbox";
import FormikCustomRadioWithLabel from "../../components/FormikCustomRadioWithLabel";
import { useCookies } from "react-cookie";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import { CREATE_FORM_DETAILS_ENDPOINT } from "../../constants/API_ENDPOINTS";
import { OPP_PORTAL_CURRENCY } from "../../constants/EXTRA_CONSTANTS";
import { toast } from "react-toastify";
import DotLoader from "../../components/DotLoader";
import CustomInput from "../../components/CustomInput";
import { ANNUAL_FORM_PRICE, MONTHLY_FORM_PRICE } from "../../constants";

const ConfirmSelection = () => {
  const { FORM_CODE } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  // const [currency, setCurrency] = useState([]);
  const [confirmationData, setConfirmationData] = useState();
  const [formCodeId, setFormCodeId] = useState();
  const [formName, setFormName] = useState();
  const [loading, setLoading] = useState(false);

  const [cookies] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  useEffect(() => {
    if (form_id) {
      async function getFormDetails() {
        let response = await getFormDetailsAPI(
          form_id,
          7,
          "Bearer " +
            Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
        );
        setFormName(response.data[0].name);
        setConfirmationData(response.data[0]);
      }
      getFormDetails();
    } else {
      setFormName(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].name);
      setConfirmationData(
        cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]
      );
    }
  }, []);

  const handleConfirmSelection = async (values) => {
    try {
      setLoading(true);
      let payload = {
        form_step: 6,
        is_vip_support: values.vip_support == 0 ? "no" : "yes",
        payment_frequency: values.pay_freq,
        form_id: form_id
          ? form_id
          : cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
        user_id: cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].user_id,
        next_action: values.next_action,
      };
      let response = await AXIOS_REQUEST.post(
        CREATE_FORM_DETAILS_ENDPOINT,
        payload,
        {
          headers: {
            Authorization:
              "Bearer " +
              Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
          },
        }
      );
      if (response.data.status) {
        setLoading(false);
        setFormCodeId(response.data.details.frame);
        setShow(true);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  //on click of summary modal button
  const handleNext = () => {
    setShow(false);
    if (form_id && formCodeId) {
      navigate(`${FORM_CODE}/${form_id}/${formCodeId}/${formName}`);
    } else if (
      cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id &&
      formCodeId
    ) {
      let form_id = cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id;
      navigate(`${FORM_CODE}/${form_id}/${formCodeId}/${formName}`);
    } else {
      navigate(FORM_CODE);
    }
  };
  return (
    <>
      <Header />
      <DotLoader loading={loading} />

      <Formik
        initialValues={{
          pay_freq: confirmationData?.payment_frequency
            ? confirmationData?.payment_frequency
            : "monthly",
          // vip_support: confirmationData
          //   ? confirmationData && confirmationData?.is_vip_support == "yes"
          //     ? true
          //     : false
          //   : false,
          next_action:
            confirmationData?.next_action &&
            confirmationData?.next_action != "null"
              ? confirmationData?.next_action
              : "",
        }}
        enableReinitialize
        onSubmit={(values) => handleConfirmSelection(values)}
        // validationSchema={FormDesignSchema}
      >
        {({ errors, touched, handleChange, values, setFieldValue }) => (
          <Form>
            <Modal
              payment_frequency={values.pay_freq}
              title="My Modal"
              onClose={() => null}
              show={show}
              handleNext={handleNext}
            >
              <p>This is modal body</p>
            </Modal>
            <div id="confirm_selection_main">
              <div className="page_container">
                <h1>Confirm Selection</h1>
                <div className="box">
                  {/* <div className="box_head">
            </div> */}
                  <div className="box_body">
                    <div className="confirm_selection">
                      <div className="included_items">
                        <CustomBoxHeader
                          title={"Your Form"}
                          title_img={IMAGES.pencil}
                        />

                        <div className="included_items_body">
                          <div className="disc_container">
                            <div className="single_item_box">
                              <h4>Form Type</h4>
                              <p>
                                {confirmationData?.form_type == "true" ||
                                confirmationData?.form_type == "single_product"
                                  ? "Single Product"
                                  : "Multiple Product"}
                              </p>
                            </div>
                            <div className="divider"></div>
                            {form_id
                              ? confirmationData?.form_type_products &&
                                JSON.parse(
                                  confirmationData?.form_type_products
                                ).map((item, index) => (
                                  <>
                                    <div
                                      key={index}
                                      className="double_item_box"
                                    >
                                      <div className="item">
                                        <h4>Product Name</h4>
                                        <p>{item?.product_name}</p>
                                      </div>
                                      <div className="item">
                                        <h4>Product Price</h4>
                                        <p title={confirmationData?.currency}>
                                          {item?.product_price}{" "}
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                confirmationData?.currency_symbol,
                                            }}
                                          ></span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="divider"></div>
                                  </>
                                ))
                              : confirmationData?.form_type_products &&
                                confirmationData?.form_type_products?.map(
                                  (item, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className="double_item_box"
                                      >
                                        <div className="item">
                                          <h4>Product Name</h4>
                                          <p>{item?.product_name}</p>
                                        </div>
                                        <div className="item">
                                          <h4>Product Price</h4>
                                          <p title={confirmationData?.currency}>
                                            {item?.product_price}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  " " +
                                                  decodeURIComponent(
                                                    confirmationData?.currency_symbol
                                                  ),
                                              }}
                                            ></span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="divider"></div>
                                    </>
                                  )
                                )}
                            {confirmationData?.sale_tax ? (
                              <>
                                <div className="double_item_box">
                                  <div className="item">
                                    <h4>Sales Tax</h4>
                                    <p>
                                      {confirmationData?.sale_tax
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <h4>Percentage</h4>
                                    <p>
                                      {confirmationData?.sale_tax
                                        ? confirmationData?.sale_tax
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="divider"></div>
                              </>
                            ) : null}
                            {confirmationData?.discount_code &&
                            confirmationData?.discount_value ? (
                              <>
                                <div className="double_item_box">
                                  <div className="item">
                                    <h4>Discount Code</h4>
                                    <p>{confirmationData?.discount_code}</p>
                                  </div>
                                  <div className="item">
                                    <h4>Percentage</h4>
                                    <p>{confirmationData?.discount_value}</p>
                                  </div>
                                </div>
                                <div className="divider"></div>
                              </>
                            ) : null}

                            <div className="single_item_box">
                              <h4>Shipping Address Fields</h4>
                              <p>
                                {confirmationData?.is_shipping_address
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div className="divider"></div>
                            {confirmationData?.payment_method ==
                            "Pre-authorisation" ? (
                              <>
                                <div className="double_item_box">
                                  <div className="item">
                                    <h4>Payment Method</h4>
                                    <p>Pre-authorisation</p>
                                  </div>
                                  <div className="item">
                                    <h4>Authorization Amount</h4>
                                    <p>
                                      {
                                        confirmationData?.preauthorisation_amount
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="divider"></div>
                              </>
                            ) : null}

                            {confirmationData?.payment_method ==
                            "Split Payments / Payment Plan" ? (
                              <>
                                <div className="double_item_box">
                                  <div className="item">
                                    <h4>Payment Method / Frequency</h4>
                                    <p>
                                      Split Payments / Payment Plan
                                      {/* ({confirmationData?.installment}) */}
                                    </p>
                                  </div>
                                  <div className="item">
                                    <h4>Split No of Installments</h4>
                                    <p>{confirmationData?.installment}</p>
                                  </div>
                                </div>
                                <div className="divider"></div>
                              </>
                            ) : null}

                            {confirmationData?.payment_method ==
                              "One Off Total Payment" ||
                            confirmationData?.payment_method ==
                              "Ongoing Recurring Subscription / Membership" ? (
                              <>
                                <div className="single_item_box">
                                  <h4>Payment Method / Frequency</h4>

                                  {confirmationData?.payment_method ==
                                  "One Off Total Payment" ? (
                                    <p>One Off Total Payment</p>
                                  ) : null}
                                  {confirmationData?.payment_method ==
                                  "Ongoing Recurring Subscription / Membership" ? (
                                    <p>
                                      Ongoing Recurring Subscription /
                                      Membership
                                    </p>
                                  ) : null}
                                </div>
                                <div className="divider"></div>
                              </>
                            ) : null}

                            <div className="single_item_box">
                              <h4>Form Design</h4>
                              {confirmationData?.form_style ==
                              "single_column" ? (
                                <p>Order Summary on Top</p>
                              ) : (
                                ""
                              )}
                              {confirmationData?.form_style == "two_column" ? (
                                <p>Order Summary on the Right</p>
                              ) : (
                                ""
                              )}
                              {confirmationData?.form_style ==
                              "custom_design" ? (
                                <p>Custom Design (+{OPP_PORTAL_CURRENCY}150)</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="extras">
                        <div className="extras_div">
                          <CustomBoxHeader
                            title={"Extras"}
                            title_img={IMAGES.pencil}
                          />
                          <div className="extras_body">
                            {/* <div className="item_box vip">
                            <h4>VIP Support</h4>
                            <div className="checkbox_main" id="vip">
                              <CustomCheckbox
                                id={"vip_support"}
                                name={"vip_support"}
                                checked={values.vip_support}
                                widthBottomPad={false}
                              />
                            </div>
                          </div>

                          <p id="extra_p_text">
                            Jump the queue! Get priority support on live chat
                            and email 24 hours a day, 6 days per week. Your chat
                            and email requests will be answered first by our
                            team. This add-on includes access to our highest
                            level of technical support.
                          </p>
                          <div className="divider"></div> */}

                            <div className="item_box">
                              <h4>Payment frequency</h4>
                              <div className="radio_main">
                                <FormikCustomRadioWithLabel
                                  label={`Monthly (${OPP_PORTAL_CURRENCY}${MONTHLY_FORM_PRICE} per month)`}
                                  value={"monthly"}
                                  id={"monthly"}
                                  name={"pay_freq"}
                                  selected={values.pay_freq == "monthly"}
                                  radioLeft={true}
                                  widthBottomPad={true}
                                />
                                <FormikCustomRadioWithLabel
                                  label={"Annually (2 months free)"}
                                  id={"annually"}
                                  value={"annually"}
                                  name={"pay_freq"}
                                  selected={values.pay_freq == "annually"}
                                  radioLeft={true}
                                  widthBottomPad={false}
                                />
                              </div>
                            </div>
                            <div className="divider"></div>

                            <div className="total_pay_value">
                              <h4 id="total_payment">Total Payment</h4>
                              <p id="total_payment_value">
                                {OPP_PORTAL_CURRENCY}
                                {values.pay_freq == "monthly"
                                  ? MONTHLY_FORM_PRICE + "/ Month"
                                  : values.pay_freq === "annually"
                                  ? ANNUAL_FORM_PRICE + "/ Annum"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="thank_you_url">
                          <CustomBoxHeader
                            title={"Thank You page URL"}
                            title_img={IMAGES.pencil}
                          />
                          <div className="extras_body">
                            <div className="">
                              <CustomInput
                                type={"text"}
                                name={"next_action"}
                                label={"Thank You Page URL"}
                                error={errors && errors.last_name}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <div className="create_my_form_btn">
                      <button type="submit">Create my form</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};

export default ConfirmSelection;
