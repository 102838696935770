export const IMAGES = {
  account_img: require("../images/account/account_img.png"),

  cards: require("../images/createaccount/cards.png"),
  security_code: require("../images/createaccount/security_code.png"),

  adminimg: require("../images/dashboardpage/adminimg.png"),
  Freshsales_Logo1: require("../images/dashboardpage/Freshsales_Logo1.png"),
  Freshsales_Logotext: require("../images/dashboardpage/Freshsales_Logotext.png"),
  gocardless: require("../images/dashboardpage/gocardless.png"),
  HubSpot_Primary: require("../images/dashboardpage/HubSpot_Primary.png"),
  Mailchimp_logo: require("../images/dashboardpage/Mailchimp_logo.png"),
  paypal_logo: require("../images/dashboardpage/paypal_logo.png"),
  securityimg: require("../images/dashboardpage/securityimg.png"),
  sharpLogo: require("../images/dashboardpage/sharpLogo.png"),
  sharpspringText: require("../images/dashboardpage/sharpspringText.png"),
  Stripe_logo_revised_: require("../images/dashboardpage/Stripe_logo_revised_.png"),
  takepayment: require("../images/dashboardpage/takepayment.png"),
  takepaymenttext: require("../images/dashboardpage/takepaymenttext.png"),
  zoho_logo: require("../images/dashboardpage/zoho_logo.png"),

  chat_img: require("../images/footerimg/chat_image.png"),
  footerLogo: require("../images/footerimg/footerLogo.png"),

  earth: require("../images/form/earth.png"),
  formcodeimg: require("../images/form/formcodeimg.png"),
  formstylebg: require("../images/form/formstylebg.png"),
  formstylebgsingle: require("../images/form/formstylebgsingle.png"),

  image_logo: require("../images/form/image_logo.png"),
  pencil: require("../images/form/pencil.png"),

  Onepageheaderimg: require("../images/headerimg/Onepageheaderimg.png"),
  hamburger: require("../images/headerimg/hamburgerWhite.png"),
  closeimg: require("../images/headerimg/closeimg.png"),


  creditcardimg: require("../images/registerimg/creditcardimg.png"),
  editimg: require("../images/registerimg/editimg.png"),
  payment_multiple: require("../images/registerimg/payment_multiple.png"),
  worldimg: require("../images/registerimg/worldimg.png"),

  account: require("../images/support/account.png"),
};
