import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import DotLoader from "../../components/DotLoader";

import AXIOS_REQUEST from "../../helpers/Axios";
import { useCookies } from "react-cookie";

const CongratsPaymentProcessor = () => {
  const { pp_or_crm } = useParams();

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [cookie, setCookie] = useCookies(["cookie-code"]);
  console.log(searchParams.get("code"));
  
  //common function to check account of CRM or Payment Processor
  const checkCRM_PPAccount = async (account_name) => {
    try {
      setLoading(true);
      let code = searchParams.get("code");

      console.log(code, "code", account_name, "account_name ");
      setCookie("cookie-code", JSON.stringify(code), {
        maxAge: 36666666,
      });
      let response = await AXIOS_REQUEST.get(
        `/congrats/${code}/${account_name}`
      );
      if (response.data.status === true) {
        setLoading(false);
        response.data.code = code;
        window.opener.postMessage({ response: response.data, code: code }, "*");
        window.close();
      } else {
        toast.error(response.data.message);
        console.log(response.data.message);
        toast.success(code);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pp_or_crm == "stripe") {
      checkCRM_PPAccount("stripe");
    }
    if (pp_or_crm == "mailchimp") {
      checkCRM_PPAccount("mailchimp");
    }
    if (pp_or_crm == "hubspot") {
      checkCRM_PPAccount("hubspot");
    }
    if (pp_or_crm == "paypal") {
      checkCRM_PPAccount("paypal");
    }
  }, []);

  console.log();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <DotLoader loading={loading} />
    </div>
  );
};

export default CongratsPaymentProcessor;
