export const countries = [
    "England",
    "India",
    "United Kingdom",
    "Other"
]

export const months = [
    "01","02","03",
    "04","05","06",
    "07","08","09",
    "10","11","12"
]

export const PREVIOUS_YEARS = (startYear)=> {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years;
}


export const NEXT_N_YEARS = (N)=> {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = currentYear;
    let endYear = currentYear+N;  
    while( endYear >= startYear) {
        years.push(startYear);
        startYear++;    
    }   
    return years;
}

export const FORMCODE_YOUTUBE_VIDEO_ID = "ALpehSJ4ybg"