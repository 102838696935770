import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import CustomInput from "../../../../components/CustomInput";
import CustomBoxHeader from "../../../../components/CustomBoxHeader";
import CustomPaginationBox from "../../../../components/CustomPaginationBox";

import "./Signup.css";
import { IMAGES } from "../../../../constants/images";
import { NEXT_N_YEARS } from "../../../../constants/staticData";
import { PaymentDetailSchema } from "../../../../helpers/validationSchema";
import AXIOS_REQUEST from "../../../../helpers/Axios";
import { ROUTE_URLS } from "../../../../constants/URLS";
import {
  UPDATE_CARD_DETAILS_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from "../../../../constants/API_ENDPOINTS";
import DotLoader from "../../../../components/DotLoader";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import CustomCheckbox from "../../../../components/CustomCheckbox";

const useResponsiveFontSize = () => {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "25px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );
};

export default function PaymentDetail() {
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();

  const { FINAL_SIGNUP, MY_ACCOUNT } = ROUTE_URLS;
  const navigate = useNavigate();

  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);
  const [psCookie, setPSCookie] = useCookies([
    process.env.REACT_APP_PS_COOKIE_NAME,
  ]);

  const [next15Years, setNext15Years] = useState();
  const [userDetails, setUserDetails] = useState();
  const [cardErrors, setCardErrors] = useState({
    cardNumber: {},
    cardExpiry: {},
    security_code: {},
  });

  const [loading, setLoading] = useState(false);
  const [showUpdateFields, setShowUpdateDetails] = useState(false);

  useEffect(() => {
    let next_15_years = NEXT_N_YEARS(15);
    async function y() {
      let user_details = await getUserDetails();
      setShowUpdateDetails(
        user_details?.payment_details?.firstname ? false : true
      );
      setUserDetails(user_details);
    }
    y();

    setNext15Years(next_15_years);
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    let response = await AXIOS_REQUEST.get(USER_PROFILE_ENDPOINT);
    setLoading(false);
    if (response) {
      return response.data;
    }
  };

  const handlePaymentDetails = async (values, payload) => {
    setLoading(true);
    if (values.showupdateFields) {
      // values.cardholdername = values.newcardholdername;
      values.udpatingfield = values.showupdateFields;
      // delete values.newcardholdername;
      delete values.cardexpiration;
      delete values.showupdateFields;
      delete values.cardnumber;
    }

    let newData = { card_details: payload.paymentMethod, ...values };
    try {
      let response = await AXIOS_REQUEST.post(
        UPDATE_CARD_DETAILS_ENDPOINT,
        newData
      );
      if (response && response.status == 200) {
        if (!response.data.status) {
          setLoading(false);
          toast.error(response.data.message);
        } else {
          setLoading(false);
          setPSCookie(process.env.REACT_APP_PS_COOKIE_NAME, 1);
          toast.success("Account Infomation saved successfully.");
          if (userDetails.form_count > 0) {
            navigate(MY_ACCOUNT);
          } else {
            response.data.status
              ? navigate(FINAL_SIGNUP)
              : toast.error(response.data.message);
          }
        }
      }
    } catch (error) {
      setLoading(false);

      toast.error(error.message);
    }
  };

  const handleSubmitCardElement = async (values) => {
    if (values.showupdateFields) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: userDetails.address.city,
            country: userDetails.address.country_code,
            state: userDetails.address.state,
            line1: userDetails.address.streetaddress,
            line2: userDetails.address.addressline2,
            postal_code: "userDetails.address.postalcode",
          },
          email: userDetails.email,
          name: userDetails.firstname + " " + userDetails.lastname,
          phone: userDetails.phone,
        },
      });
      if (payload.error) {
        toast.error(payload.error.message);
      } else {
        handlePaymentDetails(values, payload);
      }
    } else {
      handlePaymentDetails(values, {});
    }
  };

  return (
    <>
      <DotLoader loading={loading} />

      <Header />
      <div id="payment_detail">
        <div className="page_container">
          <div className="payment_sec">
            <h1>Payment Details</h1>
            <p>
              We are now taking payment details for your form. <br />
              You have 30 days to use all of the features of OnePagePay and a
              free trial. <br />
              We will start charging you from month 2 of your subscription
            </p>

            <Formik
              initialValues={{
                payment_detail_firstname: userDetails?.payment_details
                  ?.firstname
                  ? userDetails?.payment_details?.firstname
                  : userDetails?.firstname
                  ? userDetails?.firstname
                  : "",
                payment_detail_lastname: userDetails?.payment_details?.lastname
                  ? userDetails?.payment_details?.lastname
                  : userDetails?.lastname
                  ? userDetails?.lastname
                  : "",
                payment_detail_email: userDetails?.payment_details?.email
                  ? userDetails?.payment_details?.email
                  : userDetails?.email
                  ? userDetails?.email
                  : "",
                cardnumber: userDetails?.payment_details?.cardnumber
                  ? "Ends With " + userDetails?.payment_details?.cardnumber
                  : "",
                cardsecuritycode: userDetails?.payment_details?.securitycode
                  ? userDetails?.payment_details?.securitycode
                  : "***",
                cardexpiration:
                  userDetails?.payment_details?.expirymonth +
                  "/" +
                  userDetails?.payment_details?.expiry_year,
                cardholdername: userDetails?.payment_details?.cardholdername
                  ? userDetails?.payment_details?.cardholdername
                  : "",
                newcardholdername: "",
                showupdateFields: userDetails?.payment_details?.firstname
                  ? false
                  : true,
              }}
              enableReinitialize={true}
              validationSchema={PaymentDetailSchema}
              onSubmit={(values) => handleSubmitCardElement(values)}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form>
                  <div className="payment_main">
                    <CustomBoxHeader
                      title={"Payment Details"}
                      title_img={IMAGES.editimg}
                    />
                    <div className="box_body payment_detail_box_body">
                      <div className="double_input">
                        <CustomInput
                          type="text"
                          name="payment_detail_firstname"
                          label={"First Name"}
                          error={errors && errors.payment_detail_firstname}
                        />
                        <CustomInput
                          type="text"
                          name="payment_detail_lastname"
                          label={"Last Name"}
                          error={errors && errors.payment_detail_lastname}
                        />
                      </div>

                      <CustomInput
                        type="email"
                        name="payment_detail_email"
                        label={"Email"}
                        disabled
                        disabledMessage={"Not Allowed to update email."}
                        // required={true}
                        error={errors && errors.payment_detail_email}
                      />
                      {!showUpdateFields && (
                        <div className="checkbox_main">
                          <CustomCheckbox
                            label={"Do you want to update your card details?"}
                            id={"showupdateFields"}
                            checked={values.showupdateFields == true}
                            name={"showupdateFields"}
                            widthBottomPad
                          />
                        </div>
                      )}

                      {values.showupdateFields ? (
                        <>
                          <div className="input_container stripe_my_element">
                            <label htmlFor="">Card Number</label>

                            <CardNumberElement
                              options={options}
                              onChange={(event) => {
                                setCardErrors({
                                  ...cardErrors,
                                  cardNumber: event,
                                });
                              }}
                            />
                            {cardErrors.cardNumber &&
                              !cardErrors.cardNumber.empty &&
                              cardErrors.cardNumber.error && (
                                <div className="invalid-error invalid-feedback">
                                  {cardErrors.cardNumber.error.message}
                                </div>
                              )}
                          </div>

                          <div className="double_input">
                            <div className="selct_input stripe_my_element">
                              <label htmlFor="">Card Expiry</label>
                              <CardExpiryElement
                                options={options}
                                onChange={(event) => {
                                  setCardErrors({
                                    ...cardErrors,
                                    cardExpiry: event,
                                  });
                                }}
                              />
                              {cardErrors.cardExpiry &&
                                !cardErrors.cardExpiry.empty &&
                                cardErrors.cardExpiry.error && (
                                  <div className="invalid-error invalid-feedback">
                                    {cardErrors.cardExpiry.error.message}
                                  </div>
                                )}
                            </div>
                            <div className="selct_input stripe_my_element">
                              <label htmlFor="">Security Code</label>
                              <CardCvcElement
                                options={options}
                                onChange={(event) => {
                                  setCardErrors({
                                    ...cardErrors,
                                    security_code: event,
                                  });
                                }}
                              />
                              {cardErrors.security_code &&
                                !cardErrors.security_code.empty &&
                                cardErrors.security_code.error && (
                                  <div className="invalid-error invalid-feedback">
                                    {cardErrors.security_code.error.message}
                                  </div>
                                )}
                            </div>
                          </div>
                          <CustomInput
                            type="text"
                            name="cardholdername"
                            label={"Card Holder Name"}
                            error={errors && errors.cardholdername}
                          />
                        </>
                      ) : (
                        <>
                          <div className="input_container stripe_my_element">
                            <CustomInput
                              type="text"
                              name="cardnumber"
                              label={"Card Number"}
                              disabled
                              readOnly
                              // disabledMessage={
                              //   "Click Checkbox to edit Card Details"
                              // }
                            />
                          </div>

                          <div className="double_input">
                            <CustomInput
                              type="text"
                              name="cardexpiration"
                              label={"Card Expiry"}
                              disabled
                              readOnly
                              // disabledMessage={
                              //   "Click Checkbox to edit Card Details"
                              // }
                            />
                            <CustomInput
                              type="text"
                              name="cardsecuritycode"
                              label={"Security Code"}
                              disabled
                              readOnly
                              // disabledMessage={
                              //   "Click Checkbox to edit Card Details"
                              // }
                            />
                          </div>
                          <CustomInput
                            type="text"
                            name="cardholdername"
                            label={"Card Holder Name"}
                            disabled
                            readOnly
                            // disabledMessage={
                            //   "Click Checkbox to edit Card Details"
                            // }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="register_pagination">
                    <div className="pagination_outer">
                      <CustomPaginationBox total_pages={3} page_number={2} />
                    </div>
                    <div className="next_buttn">
                      <button type="submit">Next</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
