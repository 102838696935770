import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomInput from "../../components/CustomInput";
import FormikCustomTextArea from "../../components/FormikCustomTextArea";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import DotLoader from "../../components/DotLoader";

import "./Support.css";
import AXIOS_REQUEST from "../../helpers/Axios";
import { IMAGES } from "../../constants/images";
import { getSupportSchema } from "../../helpers/validationSchema/getSupportSchema";
import { CONTACT_US_ENDPOINT } from "../../constants/API_ENDPOINTS";

const Support = () => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values, resetForm) => {
    try {
      values.form_type = "support";
      values.phone = "000";
      setLoading(true);
      let response = await AXIOS_REQUEST.post(CONTACT_US_ENDPOINT, values);
      if (response.data.status) {
        setLoading(false);
        resetForm({ name: "", email: "", message: "" });
        toast.success(response.data.message);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="get_support_main">
        <div className="page_container">
          <h1>Support</h1>
          <div className="get_support">
            <CustomBoxHeader
              title={"Account Data"}
              title_img={IMAGES.account}
            />
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
              }}
              validationSchema={getSupportSchema}
            >
              {({}) => (
                <Form>
                  <div className="get_support_body">
                    <div className="content_box">
                      <CustomInput
                        label={"Name"}
                        name="name"
                        type={"name"}
                      />
                      <div className="chat_img">
                        <Link to={"#"}>
                          <img src={IMAGES.chat_img} alt={"Carlie Anglemire"} />
                        </Link>
                      </div>
                    </div>
                    <div className="content_box">
                      <CustomInput name="email" label={"Email"} />
                    </div>
                    <div className="content_box textarea_div">
                      <FormikCustomTextArea
                        name="message"
                        label={"Message"}
                        height={"218px"}
                      />
                    </div>
                    <div className="next_buttn">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Support;
