import React from "react";
import "./index.css";
import Loaderspin from "../DotLoader";

export const customStyles = {
  content: {
    top: "50%",
    // left: "50%",
    transform: "translateY(-50%)",
    background: "#fff",
    padding: "20px",
    maxHeight: "150px",
    // minheight: "140px",
    maxWidth: "400px",
    margin: "0px auto",
    // Height:"auto",
  },
};

const ConfirmationModalContent = ({ cancelPayment, paymentToCancel }) => {
  return (
    
    <>
      {/* <Loaderspin/> */}
      <div id="confirmation_modal_content">
        <h2>Are you sure you want to cancel?</h2>
        <div className="main_container">
          <div className="inner_container">
            <button
              className="yes_button"
              onClick={() => cancelPayment(paymentToCancel, "Yes")}
            >
              Yes
            </button>
            <button
              className="no_button"
              onClick={() => cancelPayment(paymentToCancel, "No")}
            >
              No
            </button>
          </div>
        </div>
      </div></>
  );
};

export default ConfirmationModalContent;
