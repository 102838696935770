import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomBoxHeader from "../../../components/CustomBoxHeader";
import CustomInput from "../../../components/CustomInput";
import DotLoader from "../../../components/DotLoader";

import "./myaccount.css";
import { IMAGES } from "../../../constants/images";
import { ROUTE_URLS } from "../../../constants/URLS";
import { useScrollTo } from "../../../hooks/useScrollTo";
import { ChangePassSchema } from "../../../helpers/validationSchema";
import {
  TogglePromotionAPI,
  changePasswordAPI,
  getUserDetailsAPI,
} from "../../../APIs";
import {
  EyeCloseComponent,
  EyeOpenComponent,
} from "../../../components/IconComponents";
import PageTitleHeader from "../../../components/PageTitleHeader";

export default function MyAccount() {
  const passwordChangeSubmitRef = useRef();
  const { BILLING_INFO, BILLING_HISTORY, CREATE_FORM } = ROUTE_URLS;
  const navigate = useNavigate();
  const [profileActive, setProfileActive] = useState(false);
  const [promotionActive, setPromotionActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [googleAccountOrNot, setGoogleAccountOrNot] = useState(false);

  const [cookie, setCookie, removeCookie] = useCookies([
    process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
  ]);

  const [cookieToken, ,] = useCookies([
    process.env.REACT_APP_TOKEN_COOKIE_NAME,
  ]);

  // getUserDetails on Page Load
  useEffect(() => {
    if (cookieToken) {
      let google_login_id =
        cookieToken[process.env.REACT_APP_TOKEN_COOKIE_NAME].google_login_id;
      if (google_login_id) {
        setGoogleAccountOrNot(true);
      } else {
        setGoogleAccountOrNot(false);
      }
    }
    getUserDetails();
  }, []);

  //get UserDetails API Call
  const getUserDetails = async () => {
    try {
      setLoading(true);
      let TOKEN =
        "Bearer" +
        " " +
        cookieToken[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token;
      let data = await getUserDetailsAPI(TOKEN);
      if (data) {
        setLoading(false);
        setPromotionActive(data.promotions_news);
        setProfileActive(data.profile_status);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const gotoBillingInfo = () => {
    navigate(BILLING_INFO);
  };

  const goToCreateForm = () => {
    if (cookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]) {
      removeCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME);
      navigate(CREATE_FORM);
    } else {
      navigate(CREATE_FORM);
    }
  };

  const ChangePassword = () => {
    if (passwordChangeSubmitRef.current) {
      passwordChangeSubmitRef.current.handleSubmit();
    }
  };

  //Toggle Eye
  const IconClick = () => {
    setPassShow(!passShow);
  };

  //Toggle Eye Confirm Password
  const ConfirmPassIconClick = () => {
    setConfirmPassShow(!confirmPassShow);
  };

  // Password Change API call
  const handlePasswordChange = async (values, resetForm) => {
    try {
      setLoading(true);
      let TOKEN =
        "Bearer " +
        cookie[process.env.REACT_APP_TOKEN_COOKIE_NAME]?.access_token;
      let data = await changePasswordAPI(TOKEN, values);
      if (data.status) {
        toast.success(data.message);
        resetForm({ password: "", password_confirmation: "" });
        setLoading(false);
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  // Password Change API call
  const handlePromotion = async () => {
    try {
      setLoading(true);
      let payload = promotionActive == "no" ? "yes" : "no";
      let TOKEN =
        "Bearer " +
        cookie[process.env.REACT_APP_TOKEN_COOKIE_NAME]?.access_token;
      let data = await TogglePromotionAPI(TOKEN, payload);
      if (data.status) {
        setPromotionActive(payload);
        setLoading(false);
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="myacoount_sec">
        <div className="page_container">
          <PageTitleHeader title={"My Account"} />

          <div className="account_boxes">
            <div className="box_sec1">
              <CustomBoxHeader
                title={"My Account"}
                title_img={IMAGES.adminimg}
              />

              <div className="box_body">
                <p>Welcome to One Page Pay</p>
                <div className="accounts_buttn">
                  <button onClick={gotoBillingInfo}>
                    Update Account information
                  </button>
                  <button
                    type="button"
                    disabled={googleAccountOrNot}
                    title={
                      googleAccountOrNot
                        ? "This action is not permitted as your account is linked with Social sign-in"
                        : null
                    }
                    className={googleAccountOrNot ? "inactive_button" : null}
                    onClick={ChangePassword}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
            <Formik
              initialValues={{ password: "", password_confirmation: "" }}
              onSubmit={(values, { resetForm }) => {
                handlePasswordChange(values, resetForm);
              }}
              validationSchema={ChangePassSchema}
              innerRef={passwordChangeSubmitRef}
            >
              {({ errors, touched, values }) => (
                <Form className="box_sec2">
                  <div>
                    <CustomBoxHeader
                      title={"Change Password"}
                      title_img={IMAGES.securityimg}
                    />
                    <div className="box_body">
                      <CustomInput
                        type={passShow ? "text" : "password"}
                        name="password"
                        id={"password"}
                        label={"New Password"}
                        required={true}
                        disabled={googleAccountOrNot}
                        IconComponent={
                          passShow ? EyeOpenComponent : EyeCloseComponent
                        }
                        IconClick={IconClick}
                        disabledMessage={
                          "This action is not permitted as your account is linked with Social sign-in"
                        }
                        error={errors && errors.password}
                      />
                      <CustomInput
                        type={confirmPassShow ? "text" : "password"}
                        name="password_confirmation"
                        id={"password_confirmation"}
                        label={"Confirm New Password"}
                        disabled={googleAccountOrNot}
                        required={true}
                        IconComponent={
                          confirmPassShow ? EyeOpenComponent : EyeCloseComponent
                        }
                        disabledMessage={
                          "This action is not permitted as your account is linked with Social sign-in"
                        }
                        IconClick={ConfirmPassIconClick}
                        error={errors && errors.password_confirmation}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="notification_sec">
            <p>Notifications</p>
            <div className="content">
              <div>
                I wish to recieve newsletters, promotions and news from One Page
                Pay
              </div>
              <div className="switch_sec">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={promotionActive == "no" ? false : true}
                    onChange={handlePromotion}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <div className="invoice">
              <p>
                Invoicing{" "}
                <span className="last_payment">Last payment: 01/01/2021</span>
              </p>
              <Link to={BILLING_HISTORY}>
                <button>Check billing history</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
