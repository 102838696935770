import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import CustomInput from "../../components/CustomInput";
import DotLoader from "../../components/DotLoader";

import "./CreateForm.css";
import { IMAGES } from "../../constants/images";
import { Form1Schema } from "../../helpers/validationSchema/FormFlowValidation";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import {
  CREATE_FORM_ENDPOINT,
  CURRENCY_LIST_ENDPOINT,
  UPDATE_FORM_ENDPOINT,
} from "../../constants/API_ENDPOINTS";

const CreateForm = () => {
  const { SETUP_PRODUCT } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();

  const [currencies, setCurrencies] = useState([]);
  const [createForm, setCreateFormData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
  ]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  //get form details step 0
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        0,
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        setCreateFormData(response.data[0]);
      }
    } catch (error) {
      console.log(error.message)
      setLoading(false);
    }
  };

  //get list of currencies and form details(if there) on component load
  useEffect(() => {
    getCurrencyList();
    if (form_id) {
      getFormDetails();
    } else {
      setCreateFormData(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
    }
  }, []);

  //get all currencies
  const getCurrencyList = async () => {
    try {
      setLoading(true);
      let response = await AXIOS_REQUEST.get(CURRENCY_LIST_ENDPOINT);
      if (response) {
        setLoading(false);
        setCurrencies(Object.entries(response.data));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //create the form step 1
  const handleForm = async (values) => {
    try {
      let payload = {
        name: values.form_name,
        currency: values.currency,
      };
      removeCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, { path: "/" });
      setLoading(true);

      let response = await AXIOS_REQUEST.post(CREATE_FORM_ENDPOINT, payload, {
        headers: {
          Authorization:
            "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response.data.status) {
        removeCookie([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
        response.data.form_details.currency_symbol = encodeURIComponent(
          response.data.form_details.currency_symbol
        );
        setCookie(
          process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
          JSON.stringify(response.data.form_details)
        );
        setLoading(false);
        navigate(SETUP_PRODUCT);
      } else if (
        !response.data.status &&
        response.data.message == "Form with this name is already exists."
      ) {
        setLoading(false);
        UpdateForm(values, response?.data?.form_id);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  //update the form step 1
  const UpdateForm = async (values, form_id_ff) => {
    try {
      let payload = {
        name: values.form_name,
        currency: values.currency,
        form_id: form_id
          ? form_id
          : cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]?.id
          ? cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]?.id
          : form_id_ff,
      };
      setLoading(true);
      let response = await AXIOS_REQUEST.post(UPDATE_FORM_ENDPOINT, payload, {
        headers: {
          Authorization:
            "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response.data.status) {
        setCookie(
          process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
          JSON.stringify(response.data.form_details)
        );
        if (form_id) {
          navigate(`${SETUP_PRODUCT}/${form_id}`);
        } else {
          if (cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]?.id) {
            navigate(`${SETUP_PRODUCT}/${cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id}`);
          } else {
            navigate(`${SETUP_PRODUCT}/${form_id_ff}`);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);

      console.log(error.message);
    }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="create_form_main">
        <div className="page_container">
          <h1>Create Form</h1>
          <Formik
            initialValues={{
              form_name: createForm ? createForm.name : "",
              currency: createForm ? createForm.currency : "",
            }}
            enableReinitialize={true}
            validationSchema={Form1Schema}
            onSubmit={(values) =>
              form_id ? UpdateForm(values) : handleForm(values)
            }
          >
            {({ errors, handleChange, values }) => (
              <Form>
                <div className="form">
                  {/* <div className="form_head">
            </div> */}
                  <div className="form_bodies">
                    <div className="form_name">
                      <CustomBoxHeader
                        title={"Form Name"}
                        title_img={IMAGES.pencil}
                      />

                      <div className="name_body">
                        <CustomInput
                          type={"text"}
                          name={"form_name"}
                          label={"Name Your Form"}
                          required={true}
                          disabledMessage={"you can't change your Form Name."}
                          disabled={form_id ? true : false}
                          error={errors && errors.last_name}
                        />
                      </div>
                    </div>
                    <div className="form_currency">
                      <CustomBoxHeader
                        title={"Form Currency"}
                        title_img={IMAGES.earth}
                      />
                      <div className="currency_body">
                        <div className="select_container">
                          <label htmlFor="currency">Select a Currency</label>
                          <Field as="select" name="currency" id="currency">
                            <option value="">Select a currency</option>
                            {currencies?.map((item, index) => (
                              <option key={index} value={item[0]}>
                                {item[1]}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name="currency">
                            {(msg) => (
                              <div className="invalid-error invalid-feedback">
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                          <div className="text_info">
                            If your currency is not listed here, please contact
                            support.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {form_id ? (
                    <p style={{ color: "var(--error)", fontWeight: 500 }}>
                      Note : Kindly complete all the steps to activate this
                      form.
                    </p>
                  ) : null}

                  <div className="save_btn">
                    {/* <Link to={SETUP_PRODUCT}> */}
                    <button type="submit">Save</button>
                    {/* </Link> */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateForm;
