import React, { useState } from "react";
import Modal from "react-modal";

// Custom styles for the modal

// Modal.setAppElement("#root");

const CustomModal = ({
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  content,
  title,
  setIsOpen,
  isOpen,
  contentStyles,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={contentStyles}
        contentLabel="Modal"
      >
        {content}
      </Modal>
    </div>
  );
};

export default CustomModal;
