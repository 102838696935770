import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomInput from "../../components/CustomInput";
import CustomBoxHeader from "../../components/CustomBoxHeader";
import CustomRadioWithLabel from "../../components/CustomRadioWithLabel";
import CustomPaginationBox from "../../components/CustomPaginationBox";
import FormikCustomRadioWithLabel from "../../components/FormikCustomRadioWithLabel";
import DotLoader from "../../components/DotLoader";

import "./SetupPayment.css";
import { setupPaymentSchema } from "../../helpers/validationSchema/FormFlowValidation";
import { IMAGES } from "../../constants/images";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";

const SetupPayment = () => {
  const { SETUP_PRODUCT, PAYMENT_PROCESSOR } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  const [setupPaymentData, setSetupPaymentData] = useState(1);
  const [loading, setLoading] = useState(false);

  //get form details step 2
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        2,
        "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        setSetupPaymentData(response.data[0]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details(if there) on component load
  useEffect(() => {
    if (form_id) {
      getFormDetails();
    } else {
      setSetupPaymentData(cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME]);
    }
  }, []);

  //submit form on submit button for setup payment method of the form
  const handleSetupPayment = async (values) => {
    try {
      if (values.payment_method == "One Off Total Payment") {
        delete values.installments;
        delete values.pre_authorize_amount;
        delete values.split_no_of_installments;
      } else if (values.payment_method == "Split Payments / Payment Plan") {
        delete values.pre_authorize_amount;
      } else if (
        values.payment_method == "Ongoing Recurring Subscription / Membership"
      ) {
        delete values.installments;
        delete values.pre_authorize_amount;
        delete values.split_no_of_installments;
      } else if (values.payment_method == "Pre-authorisation") {
        delete values.installments;
        delete values.split_no_of_installments;
      }

      let payload = {
        form_step: 2,
        form_id: form_id ? form_id : cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
        payment_method:
          values.payment_method == "One Off Total Payment"
            ? "One Off Total Payment"
            : values.payment_method == "Split Payments / Payment Plan"
            ? "Split Payments / Payment Plan"
            : values.payment_method == "Pre-authorisation"
            ? "Pre-authorisation"
            : values.payment_method ==
              "Ongoing Recurring Subscription / Membership"
            ? "Ongoing Recurring Subscription / Membership"
            : undefined,
        installment:
          values.payment_method === "Split Payments / Payment Plan"
            ? Number(values.split_no_of_installments)
            : undefined,
        installment_by:
          values.payment_method === "Split Payments / Payment Plan"
            ? values.installments
            : undefined,
        preauthorisation_amount:
          values.payment_method === "Pre-authorisation"
            ? Number(values.pre_authorize_amount)
            : undefined,
      };

      setLoading(true);
      let response = await AXIOS_REQUEST.post("/create-form-details", payload, {
        headers: {
          Authorization:
            "Bearer " + Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
        },
      });
      if (response.data.status) {
        setLoading(false);
        if (form_id) {
          navigate(`${PAYMENT_PROCESSOR}/${form_id}`);
        } else {
          let newObj = {
            ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
            ...response.data.details,
          };
          setCookie(process.env.REACT_APP_CREATE_FORM_COOKIE_NAME, JSON.stringify(newObj));
          navigate(PAYMENT_PROCESSOR);
        }
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };
  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="setup_payment_main">
        <div className="page_container">
          <h1>Taking Payments</h1>
          <Formik
            initialValues={{
              payment_method: setupPaymentData?.payment_method
                ? setupPaymentData?.payment_method
                : "One Off Total Payment",
              split_no_of_installments: setupPaymentData?.installment
                ? setupPaymentData?.installment
                : "",
              pre_authorize_amount: setupPaymentData?.preauthorisation_amount
                ? setupPaymentData?.preauthorisation_amount
                : "",
              installments: setupPaymentData?.installment_by
                ? setupPaymentData?.installment_by
                : "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              handleSetupPayment(values);
            }}
            validationSchema={setupPaymentSchema}
          >
            {({ errors, touched, handleChange, values }) => (
              <Form>
                <div className="form">
                  {/* <div className="form_head"></div> */}
                  <div
                    className="form_bodies"
                    style={{
                      justifyContent:
                        values.payment_method === "One Off Total Payment" ||
                        values.payment_method ===
                          "Ongoing Recurring Subscription / Membership" ||
                        values.payment_method === ""
                          ? "center"
                          : "space-between",
                    }}
                  >
                    <div className="forms_left">
                      <div className="payment_method">
                        <CustomBoxHeader
                          title={"Payment Method"}
                          title_img={IMAGES.pencil}
                        />
                        <div className="payment_method_body">
                          <div className="radio_main">
                            <FormikCustomRadioWithLabel
                              id={"One Off Total Payment"}
                              label={"One Off Total Payment"}
                              value={"One Off Total Payment"}
                              // onChange={null}
                              // onClick={null}
                              selected={
                                values.payment_method == "One Off Total Payment"
                              }
                              widthBottomPad={true}
                              name={"payment_method"}
                            />

                            <FormikCustomRadioWithLabel
                              id={"Split Payments / Payment Plan"}
                              label={"Split Payments / Payment Plan"}
                              value={"Split Payments / Payment Plan"}
                              // onChange={null}
                              // onClick={null}
                              selected={
                                values.payment_method ==
                                "Split Payments / Payment Plan"
                              }
                              widthBottomPad={true}
                              name={"payment_method"}
                            />

                            <FormikCustomRadioWithLabel
                              id={"Ongoing Recurring Subscription / Membership"}
                              label={
                                "Ongoing Recurring Subscription / Membership"
                              }
                              value={
                                "Ongoing Recurring Subscription / Membership"
                              }
                              // onChange={null}
                              // onClick={null}
                              selected={
                                values.payment_method ==
                                "Ongoing Recurring Subscription / Membership"
                              }
                              widthBottomPad={true}
                              name={"payment_method"}
                            />

                            {/* <FormikCustomRadioWithLabel
                              id={"Pre-authorisation"}
                              label={"Pre-authorisation"}
                              value={"Pre-authorisation"}
                              // onChange={null}
                              // onClick={null}
                              selected={
                                values.payment_method == "Pre-authorisation"
                              }
                              widthBottomPad={true}
                              name={"payment_method"}
                            /> */}
                            {errors && errors.payment_method && (
                              <small style={{ color: "red" }}>
                                {errors.payment_method}
                              </small>
                            )}

                            {/* <CustomRadioWithLabel
                              id={"One Off Total Payment"}
                              label={"One Off Total Payment"}
                              name={"payment_method"}
                              onClick={() => handlePayMethod("One Off Total Payment")}
                              selected={payMethod === "One Off Total Payment"}
                              onChange={() => handlePayMethod("One Off Total Payment")}
                            />
                            <CustomRadioWithLabel
                              id={"Split Payments / Payment Plan"}
                              label={"Spit Payments / Payment Plan"}
                              name={"payment_method"}
                              onClick={() => handlePayMethod("Split Payments / Payment Plan")}
                              selected={payMethod === "Split Payments / Payment Plan"}
                              onChange={() => handlePayMethod("Split Payments / Payment Plan")}
                            />
                            <CustomRadioWithLabel
                              id={"Ongoing Recurring Subscription / Membership"}
                              label={
                                "Ongoing Recurring Subscription / Ongoing Recurring Subscription / Membership"
                              }
                              name={"payment_method"}
                              onClick={() => handlePayMethod("Ongoing Recurring Subscription / Membership")}
                              onChange={() => handlePayMethod("Ongoing Recurring Subscription / Membership")}
                              selected={payMethod === "Ongoing Recurring Subscription / Membership"}
                            />
                            <CustomRadioWithLabel
                              id={"Pre-authorisation"}
                              label={"Pre-authorisation"}
                              name={"payment_method"}
                              onClick={() => handlePayMethod("Pre-authorisation")}
                              onChange={() => handlePayMethod("Pre-authorisation")}
                              selected={payMethod === "Pre-authorisation"}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {values.payment_method !== "One Off Total Payment" &&
                    values.payment_method !==
                      "Ongoing Recurring Subscription / Membership" &&
                    values.payment_method !== "" ? (
                      <div className="forms_right">
                        {/* {payMethod == "One Off Total Payment" ? (
                        <div className="payment_frequency">
                          <CustomBoxHeader
                            title={"Payment Frequency"}
                            title_img={IMAGES.earth}
                          />
                          <div className="payment_frequency_body">
                            <div className="disc_container">
                              <CustomInput
                                type={"text"}
                                label={"How many installments?"}
                              />

                              <div className="radio_main installments">
                                <CustomRadioWithLabel
                                  id={"installments_weekly"}
                                  label={"Weekly"}
                                  name={"installments"}
                                />

                                <CustomRadioWithLabel
                                  id={"installments_monthly"}
                                  label={"Monthly"}
                                  name={"installments"}
                                />
                                <CustomRadioWithLabel
                                  id={"installments_quarterly"}
                                  label={"Quarterly"}
                                  name={"installments"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                        {/* {payMethod === "Ongoing Recurring Subscription / Membership" ? (
                        <div className="payment_frequency">
                          <CustomBoxHeader
                            title={"Payment Frequency"}
                            title_img={IMAGES.earth}
                          />
                          <div className="payment_frequency_body">
                            <div className="disc_container">
                              <CustomInput
                                type={"text"}
                                label={"How many installments?"}
                                name="member_no_of_installments"
                              />

                              <div className="radio_main installments">
                                <CustomRadioWithLabel
                                  id={"installments_weekly"}
                                  label={"Weekly"}
                                  name={"installments"}
                                />

                                <CustomRadioWithLabel
                                  id={"installments_monthly"}
                                  label={"Monthly"}
                                  name={"installments"}
                                />
                                <CustomRadioWithLabel
                                  id={"installments_quarterly"}
                                  label={"Quarterly"}
                                  name={"installments"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                        {values.payment_method ===
                        "Split Payments / Payment Plan" ? (
                          <div className="payment_frequency">
                            <CustomBoxHeader
                              title={"Payment Frequency"}
                              title_img={IMAGES.earth}
                            />
                            <div className="payment_frequency_body">
                              <div className="disc_container">
                                <CustomInput
                                  type={"text"}
                                  maxLength={2}
                                  name="split_no_of_installments"
                                  label={"How many installments?"}
                                />

                                <div className="radio_main installments">
                                  <FormikCustomRadioWithLabel
                                    label={"Weekly"}
                                    value={"Weekly"}
                                    id={"installments_weekly"}
                                    // onChange={null}
                                    // onClick={null}
                                    selected={values.installments == "Weekly"}
                                    widthBottomPad={true}
                                    name={"installments"}
                                  />

                                  <FormikCustomRadioWithLabel
                                    label={"Monthly"}
                                    value={"Monthly"}
                                    id={"installments_monthly"}
                                    // onChange={null}
                                    // onClick={null}
                                    selected={values.installments == "Monthly"}
                                    widthBottomPad={true}
                                    name={"installments"}
                                  />
                                  <FormikCustomRadioWithLabel
                                    label={"Quarterly"}
                                    value={"Quarterly"}
                                    id={"installments_quarterly"}
                                    // onChange={null}
                                    // onClick={null}
                                    selected={
                                      values.installments == "Quarterly"
                                    }
                                    widthBottomPad={true}
                                    name={"installments"}
                                  />
                                </div>
                                {errors &&
                                touched.installments &&
                                errors.installments ? (
                                  <div className="invalid-error invalid-feedback">
                                    {errors.installments}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {values.payment_method === "Pre-authorisation" ? (
                          <div className="payment_frequency">
                            <CustomBoxHeader
                              title={"Pre-authorisation amount"}
                              title_img={IMAGES.pencil}
                            />
                            <div className="payment_frequency_body">
                              <div className="disc_container">
                                <CustomInput
                                  type={"text"}
                                  maxLength={7}
                                  label={
                                    "How much do you want to pro-authorise for?"
                                  }
                                  name="pre_authorize_amount"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {/* <div className="payment_frequency">
                        <CustomBoxHeader
                          title={"Payment Frequency"}
                          title_img={IMAGES.earth}
                        />
                        <div className="payment_frequency_body">
                          <div className="disc_container">
                            <CustomInput
                              type={"text"}
                              label={"How many installments?"}
                            />

                            <div className="radio_main installments">
                              <CustomRadioWithLabel
                                id={"installments_weekly"}
                                label={"Weekly"}
                                name={"installments"}
                              />

                              <CustomRadioWithLabel
                                id={"installments_monthly"}
                                label={"Monthly"}
                                name={"installments"}
                              />
                              <CustomRadioWithLabel
                                id={"installments_quarterly"}
                                label={"Quarterly"}
                                name={"installments"}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    ) : null}
                  </div>
                  <div className="pagination_outer">
                    <CustomPaginationBox total_pages={5} page_number={2} />
                    <div className="next_buttn">
                      <Link
                        to={
                          form_id
                            ? `${SETUP_PRODUCT}/${form_id}`
                            : SETUP_PRODUCT
                        }
                        className="back_btn"
                      >
                        <button>Back</button>
                      </Link>
                      <button type="submit">Next</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SetupPayment;
