import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Nopage = () => {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "red" }}>No Page Found || 404 </span>
      </div>
      <Footer />
    </>
  );
};

export default Nopage;
