import React, { useState } from "react";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomBoxHeader from "../../../components/CustomBoxHeader";
import DotLoader from "../../../components/DotLoader";

import "./myaccount.css";
import { IMAGES } from "../../../constants/images";

export default function BillingHistory() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div className="billing_history_sec">
        <div className="page_container">
          <h1>Billing History</h1>
          <div className="billing_history">
            <div className="billing_main">
              <CustomBoxHeader
                title={"Your Invoice History"}
                title_img={IMAGES.adminimg}
              />
              <div className="main_body">
                <div className="body_header">
                  <ul>
                    <li>Date</li>
                    <li>Invoice Number</li>
                    <li>Invoice Amount</li>
                  </ul>
                </div>
                <div className="body">
                  <div className="content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
